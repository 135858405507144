import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import { X } from 'feather-icons-react/build/IconComponents';
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { AddAnnauncements } from "../../Redux/Action/annauncement.action";
import { useDispatch } from "react-redux";
import {
  Popover,
} from '@mui/material';
import module, { formatCode } from "../../controllers/QuillToolbar";
import Breadcrumbs from "../../controllers/breadCrumbs";
const CreateAnnouncement = () => {
  const [value, setValue] = useState();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    title: "",
    receiver: "all",
    link: "",
    short_description: "",
    tag: "Improvement",
  });
  const { title, receiver, link, short_description, tag } = formData;
  // collect Data
  const HandleInput = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Submit Form

  // const HandleSubmit = async (e) => {
  //   e.preventDefault();
  //   await fetch(
  //     `${process.env.REACT_APP_BACKEND_URL}/super/admin/announcement/AddAnnouncement`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "content-type": "application/json",
  //         token,
  //       },
  //       body: JSON.stringify({
  //         announcement_title: title,
  //         announcement_type: receiver,
  //         short_description: short_description,
  //         description: value,
  //         tag: tag,
  //         link: link,
  //       }),
  //     }
  //   )
  //     .then(() => {
  //       toast.success("AnnounceMent Added Successfully")
  //       setTimeout(() => {
  //         navigate('/announcement')
  //       }, 2000);
  //     })
  //     .catch((error) => console.log(error));
  // };


  const HandleSubmit = async (e) => {
    e.preventDefault();
    dispatch(AddAnnauncements(title, receiver, link, short_description, tag, value));
    toast.success("AnnounceMent Added Successfully")
    navigate('/announcement')
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef?.current?.getEditor();
    const htmlContent = quill?.root?.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setValue(code)
  }

  return (
    <>
      <div className="w-full pb-[64px]">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Announcement', path: `/announcement` },
            { name: 'Add announcement', path: `/announcement/createannouncement` }
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Add Announcement</h1>
        </div>
        <div className="pb-4 overflow-x-hidden  h-full w-full">
          <form
            method="POST"
            className="w-[90%] lg:w-[70%] xl:w-[60%] bg-white border border-gray-300 p-5 mx-auto rounded-[10px]"
          >
            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  Title
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="anntitle"
                  name="title"
                  value={title}
                  placeholder="Title"
                  onChange={HandleInput}
                />
              </div>
              <div className="w-full">
                <label
                  className="flex text-gray-700 text-sm font-medium mb-1"
                  htmlFor="receiver"
                >
                  Receiver
                </label>
                <div className="relative">
                  <select
                    name="receiver"
                    onChange={HandleInput}
                    value={receiver}
                    className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500"
                  >
                    <option value="all">All</option>
                    <option value="admin">Admin</option>
                    <option value="affiliate">Affiliate</option>
                    <option value="customer">Customer</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>
            </div>
            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="link"
                >
                  Link
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="link"
                  placeholder="Link"
                  name="link"
                  value={link}
                  onChange={HandleInput}
                />
              </div>
              <div className="w-full">
                <label
                  className="flex text-gray-700 text-sm font-medium mb-1"
                  htmlFor="receiver"
                >
                  Tags
                </label>
                <div className="relative">
                  <select
                    value={tag}
                    name="tag"
                    onChange={HandleInput}
                    className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500"
                  >
                    <option>Improvement</option>
                    <option>New Version</option>
                    <option>New Feature</option>
                    <option>Tips</option>
                    <option>Warning</option>
                    <option>Success</option>
                    <option>Idea</option>
                  </select>
                  <div className="select-arrow"></div>
                </div>
              </div>
            </div>
            <div className="w-full mb-4">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="short_description"
              >
                Short Description
              </label>
              <textarea
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                placeholder="Short description"
                id="short_description"
                name="short_description"
                value={short_description}
                onChange={HandleInput}
              />
            </div>
            <div className="mb-4">
              <div className='flex space-x-3 justify-between items-center mb-1'>
                <label
                  className="block text-gray-700 text-sm font-medium mb-1"
                  htmlFor="description"
                >
                  Description
                </label>
                <section
                  className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                  onClick={handleViewHtml}
                >
                  View HTML
                </section>
              </div>
              <div>
                <ReactQuill
                  className="w-full bg-white"
                  placeholder="Description"
                  ref={quillRef}
                  modules={module}
                  value={value}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
            <button
              className="block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
              type="submit"
              onClick={HandleSubmit}
            >
              Publish Announcement
            </button>
          </form>
        </div>
        <ToastContainer />
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X className="cursor-pointer" onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
                <button
                  className='btn w-full mt-3 text-white px-3 py-2 rounded-[10px] mx-auto'
                  onClick={saveHTML}
                >
                  save
                </button>
              </div>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default CreateAnnouncement;
