import API from "../../API";
import { ADD_GUIDE_GUAIDENCE, ADD_GUAIDE_CATERORIES_GUAIDENCE, GET_GUAIDE_CATERORIES, GET_PERTICULAR_CATERORIES_DATA, UPDATE_GUAIDE_CATERORIES, UPDATE_GUIDE } from "../action.type"
import { toast } from "react-toastify";


export const AddGuides = (title, value, slugName, category, extension) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let ApiCall = await API({ url: "/super/admin/guide/addGuide", method: 'post', data: {
          title: title,
          description: value,
          guide_slug_name: slugName,
          category: category,
          extension: extension,
        }});
        if (ApiCall?.data) {
          dispatch({
            type: ADD_GUIDE_GUAIDENCE,
          });
        }
        dispatch(GetGuides(1, 10, ''));
        return Promise.resolve()
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const GetGuides = (Page, limit, searchkey) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/guide/getguides`, method: 'get', params: { page: Page || 1, limit: limit || 10, searchkey }});
      if (ApiCall?.data) {
        dispatch({
          type: "GET_GUIDES",
          payload: ApiCall
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const GetExtensionName = () => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/guide/GetExtension`, method: 'get' });
      if (ApiCall?.data) {
        dispatch({
          type: "GET_EXTENSION_NAME",
          payload: ApiCall
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const viewGuide = (id) => {
  return async (dispatch) => {
    // if(id) {
    try {
      let ApiCall = await API({ url: `/super/admin/guide/getGuide/${id}`, method: 'get' });
      if (ApiCall?.data) {
        dispatch({
          type: "GET_PERTICULAR_DATA",
          payload: ApiCall
        })
      }
    } catch (error) {
      console.log(error);
    }
    // }
  }
}

export const UpdateGuides = (title, value, slugName, category, extension, _id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let ApiCall = await API({ url: `/super/admin/guide/updateGuide/${_id}`, method: 'put', data: {
          title: title,
          description: value,
          guide_slug_name: slugName,
          category: category,
          extension: extension,
        }});
        if (ApiCall?.data) {
          dispatch({
            type: UPDATE_GUIDE,
            payload: ApiCall
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const GetDeleteGuides = (selectedRowsId, id) => {
  if (window.confirm("Delete Guide?")) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      try {
        if (token) {
          let ApiCall = await API({ url: `/super/admin/guide/deleteGuide`, method: 'post', data: {
            guide: selectedRowsId?.length > 0 ? selectedRowsId : [id],
          }});
          if (ApiCall.status === 200) {
            toast.success("Guide Removed Successfully", {
              position: "top-right",
            });
            dispatch(GetGuides(1, 10, ''));
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    };
  }
};

// CATEGORIES CATEGORY

export const AddGuidesCategory = (category, slugName, sort_order, image, value) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let ApiCall = await API({ url: "/super/admin/guideCategory/addGuideCategory", method: 'post', data: {
          category_name: category,
          guide_category_slug_name: slugName,
          sort_order: sort_order,
          image: image,
          description: value,
        }});
        if (ApiCall?.data) {
          dispatch({
            type: ADD_GUAIDE_CATERORIES_GUAIDENCE,
          });
        }
        dispatch(GetGuideCategories())
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const GetGuideCategories = (searchkey = '') => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/guideCategory/getGuideCategory`, method: 'get', params: { searchkey }});
      if (ApiCall?.data) {
        dispatch({
          type: GET_GUAIDE_CATERORIES,
          payload: ApiCall
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
}


export const getGuidePerticularCategories = (id) => {
  return async (dispatch) => {
    if (id)
      try {
        let ApiCall = await API({ url: `/super/admin/guideCategory/getGuideCategoryById/${id}`, method: 'get' });
        if (ApiCall?.data) {
          dispatch({
            type: GET_PERTICULAR_CATERORIES_DATA,
            payload: ApiCall
          })
        }
      } catch (error) {
        console.log(error);
      }
  }
}

export const UpdateGuideCategories = (value, slugName, category, sort_order, image, guide_category_slug_name) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        let ApiCall = await API({ url: `/super/admin/guideCategory/updateGuideCategory/${guide_category_slug_name}`, method: 'put', data:
          {
            description: value,
            guide_slug_name: slugName,
            category_name: category,
            sort_order: sort_order,
            image
          }});
        if (ApiCall?.data) {
          dispatch({
            type: UPDATE_GUAIDE_CATERORIES,
            payload: ApiCall
          });
        }
        dispatch(GetGuideCategories())
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const GetDeleteGuideCategories = (selectedRowsId, id) => {
  if (window.confirm("Delete Guide Category?")) {
    return async (dispatch) => {
      const token = localStorage.getItem("token");
      try {
        if (token) {
          let ApiCall = await API({ url: `/super/admin/guideCategory/deleteGuideCategory`, method: 'post', data: {
            id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
          }});
          dispatch({
            type: GET_GUAIDE_CATERORIES,
          });
          if (ApiCall.status === 200) {
            toast.success("Guides Category Removed Successfully", {
              position: "top-right",
            });
            dispatch(GetGuideCategories());
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    };
  }
};