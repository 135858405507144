import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addChangelogCategory,
  getAllChangelogCategories,
  getPerticularLog,
  updateChangelog,
} from "../../Redux/Action/changelog.action";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import module from "../../controllers/QuillToolbar";
import { Autocomplete, Popover, TextField, Tooltip } from "@mui/material";
import { Check, Edit, X } from "feather-icons-react/build/IconComponents";
import { formatCode } from "../../controllers/QuillToolbar";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { GenerateSlugName } from "../../controllers/GenerateSlugName";

const UpdateChangeLog = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [err, setErr] = useState(false);
  const [addCatPP, setAddCatPP] = useState(false)
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [slugName, setSlugName] = useState();
  const [addCategoryData, setAddCategoryData] = useState({ category_name: "" });
  const { perticularLog, allChangelogCategories } = useSelector(
    (state) => state.changelogReducer
  );
  const [changelogData, setChangelogData] = useState({
    short_description: "",
    title: "",
    type: "New",
    categories: "",
    change_log_date: ""
  });
  const types = ["New", "Improvement", "Feature", "Changed", "Removed"];
  const HandleInput = (e) => {
    setErr(false);
    setChangelogData({ ...changelogData, [e.target.name]: e.target.value });
  };
  const [longDescription, setLongDescription] = useState("");
  useEffect(() => {
    dispatch(getPerticularLog(params?.logID));
    dispatch(getAllChangelogCategories("", ""))
  }, [dispatch]);

  useEffect(() => {
    if (perticularLog && allChangelogCategories) {
      setChangelogData({
        short_description: perticularLog?.short_description || "",
        title: perticularLog?.title || "",
        type: perticularLog?.type || "",
        change_log_date: perticularLog?.change_log_date || new Date().toISOString().slice(0, 10),
        categories: allChangelogCategories?.records?.find(
          (x) => x.category_name === perticularLog?.category_name
        )?._id,
      });
      setLongDescription(perticularLog?.long_description);
      setSlugName(perticularLog?.log_slug_name)
    }
  }, [dispatch, perticularLog, allChangelogCategories])


  // add New Category
  const addNewCategory = () => {
    if (addCategoryData.category_name == "") {
      setErr(true);
    } else {
      dispatch(addChangelogCategory(addCategoryData));
      setAddCatPP(false)
      setAddCategoryData({ ...addCategoryData, category_name: "" });
    }
  };

  const HandleUpdate = (e) => {
    e.preventDefault();
    if (
      changelogData?.title === "" ||
      changelogData.short_description === "" ||
      changelogData.categories === ""
    ) {
      setErr(true);
    } else {
      dispatch(
        updateChangelog(
          { ...changelogData, long_description: longDescription, log_slug_name: slugName },
          perticularLog?._id
        )
      )
        .then(() => navigate("/changelog"))
        .catch((e) => console.log(e));
    }
  };


  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setLongDescription(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setLongDescription(code)
  }

  return (
    <>
      <div className="w-full h-full pb-[64px]">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Changelog', path: `/changelog` },
            { name: 'Update Changelog', path: `/update-changelog` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Update Changelog</h1>
        </div>
        <div className="pb-4 overflow-x-hidden  h-full w-full">
          <form
            method="POST"
            className="w-[90%] lg:w-[70%] xl:w-[60%] bg-white border border-gray-300 p-4 mx-auto rounded"
          >
            <div className="w-full mb-4">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="title"
              >
                Title
              </label>
              <input
                className={`w-full px-3 py-2 border ${err && changelogData.title === ""
                  ? "border-red-500"
                  : "border-gray-300"
                  } rounded-[4px] focus:outline-none focus:border-gray-500`}
                type="text"
                name="title"
                value={changelogData.title}
                placeholder="Title"
                onChange={HandleInput}
              />
              {err && changelogData.title === "" && (
                <h1 className="text-sm text-red-500">*Title is required</h1>
              )}
            </div>
            <div className='w-full mb-4'>
              <label htmlFor='category_slug_name'>
                Changelog slug name
              </label>
              <div
                className={`flex items-center justify-between w-full border ${err ? 'border-red-500' : 'main-border-color'
                  } rounded-[4px]`}
              >
                <input
                  className='w-full px-3 py-2 rounded-[10px] focus:outline-none'
                  type='text'
                  name='category_slug_name'
                  placeholder='Enter slug name'
                  value={slugName}
                  disabled={!enableSlug}
                  onChange={(e) => {
                    setSlugName(e.target.value);
                  }}
                />
                {enableSlug ? (
                  <Check
                    onClick={async () => {
                      setSlugName(await GenerateSlugName(slugName));
                      setEnableSlug(!enableSlug);
                      setSlugEdited(true);
                    }}
                    className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                  />
                ) : (
                  <Edit
                    onClick={() => setEnableSlug(!enableSlug)}
                    className='text-blue-500 p-1 h-7 w-7 cursor-pointer'
                  />
                )}
              </div>
            </div>
            <div className="w-full mb-4">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="type"
              >
                Type
              </label>
              <div className="relative">
                <select
                  onChange={(e) =>
                    setChangelogData({ ...changelogData, type: e.target.value })
                  }
                  className="border w-full border-gray-300 px-2 py-3 text-sm cursor-pointer outline-none rounded-[5px]"
                >
                  {types.map((x, index) => {
                    return <option key={index}>{x}</option>;
                  })}
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            <div className="w-full mb-4">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="change_log_date"
              >
                Date
              </label>
              <input
                className={`w-full px-3 py-2 border  border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                type="date"
                id="change_log_date"
                name="change_log_date"
                value={changelogData?.change_log_date?.split("T")[0]}
                placeholder="Title"
                onChange={HandleInput}
              />
              {err && changelogData.title === "" && (
                <h1 className="text-sm text-red-500">*Title is required</h1>
              )}
            </div>
            <div className="mb-4">
              <div className="flex justify-between">
                <label htmlFor="Description">Changelog category</label>
                <button className="text-[#6A63FB] hover:underline" onClick={(e) => { e.preventDefault(); setAddCatPP(true) }}>
                  Add new Category
                </button>
              </div>
              <Autocomplete
                className="autocomplete "
                options={
                  allChangelogCategories?.records
                    ? allChangelogCategories.records
                    : []
                }
                getOptionLabel={(category) => category.category_name}
                value={allChangelogCategories?.records?.find(
                  (x) => x.category_name === perticularLog && perticularLog?.category_name
                )}
                //*******************   For Future:If we add Multi Select Categories   ****************************** */
                //   onChange={(e, v) => HandleLogID(v?._id, v?.category_name)}

                onChange={(e, v) =>
                  v &&
                  setChangelogData({ ...changelogData, categories: v?._id })
                }
                renderInput={(products) => (
                  <TextField
                    className="py-1"
                    placeholder="Select changelog category products"
                    {...products}
                  />
                )}
              />
              {err && changelogData.categories === "" && (
                <h1 className="text-sm text-red-500">*Category is required</h1>
              )}
              {/* //*******************   For Future:If we add Multi Select Categories   ****************************** */}
              {/* <div className='flex flex-wrap mt-1'>
                          {categories?.map((elem) => {
                            return (
                              <div className='bg-[#f1f1f1] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded-[10px]'>
                                <span>{elem.category_name}</span>
                                <X
                                  className='w-4 h-4 ms-1 cursor-pointer'
                                  onClick={() => HandleRemoveLogID(elem.id)}
                                />
                              </div>
                            );
                          })}
                        </div> */}
            </div>
            <div className="w-full mb-4">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="short_description"
              >
                Short Description
              </label>
              <textarea
                className={`w-full px-3 py-2 border ${err && changelogData.short_description === ""
                  ? "border-red-500"
                  : "border-gray-300"
                  } rounded-[4px] focus:outline-none focus:border-gray-500`}
                type="text"
                placeholder="Short description"
                id="short_description"
                name="short_description"
                value={changelogData.short_description}
                onChange={HandleInput}
              />
              {err && changelogData.short_description === "" && (
                <h1 className="text-sm text-red-500">
                  *short description is required
                </h1>
              )}
            </div>
            <div className="mb-4">
              <div className='flex space-x-3 justify-between items-center mb-1'>
                <label
                  className="block text-gray-700 text-sm font-medium mb-1"
                  htmlFor="description"
                >
                  Description
                </label>
                <section
                  className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                  onClick={handleViewHtml}
                >
                  View HTML
                </section>
              </div>
              <div>
                <ReactQuill
                  className="w-full bg-white"
                  placeholder="Description"
                  ref={quillRef}
                  modules={module}
                  value={longDescription}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
            <button
              className="block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
              type="submit"
              onClick={HandleUpdate}
            >
              Update Changelog
            </button>
          </form>
        </div>
        {addCatPP && (
          <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
            <Popover
              open={addCatPP}
              className="h-screen"
              onClose={() => {
                setAddCatPP(false)
              }}
            >
              <div className="w-[400px] max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                <div className="w-full flex p-2 justify-between">
                  <h1 className="text-xl font-medium">Add Changelog Category</h1>
                  <X
                    className="w-5 cursor-pointer"
                    onClick={() => {
                      setAddCatPP(false)
                    }}
                  />
                </div>
                <hr />
                <div className="w-full contryCode">
                  <label
                    className="flex items-center py-2 font-semibold"
                    htmlFor="category_name"
                  >
                    Category Name*
                    <Tooltip
                      title="This is the net sales price for your customers."
                      arrow
                      placement="right"
                      className="ml-2 -tracking-tighter"
                    ></Tooltip>
                  </label>
                  <input
                    className={
                      err && addCategoryData.category_name === ""
                        ? "w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none"
                        : "w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                    }
                    type="text"
                    min={1}
                    id="category_name"
                    name="category_name"
                    value={addCategoryData.category_name}
                    onChange={(e) => setAddCategoryData({ category_name: e.target.value })}
                    placeholder="Category name"
                  />
                  {err && addCategoryData.category_name === "" && (
                    <h1 className="text-red-500 text-sm">
                      Category name is required
                    </h1>
                  )}
                </div>
                <label for="start">Start date:</label>


                <button
                  className="flex w-full justify-center my-1 items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
                  type="button"
                  onClick={addNewCategory}
                >
                  Add category
                </button>
              </div>
            </Popover>
          </div>
        )}
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X className="cursor-pointer" onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
                <button
                  className='btn w-full mt-3 text-white px-3 py-2 rounded-[10px] mx-auto'
                  onClick={saveHTML}
                >
                  save
                </button>
              </div>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default UpdateChangeLog;
