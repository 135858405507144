import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react'
import { Transition } from './Transition';

const SourceCode = ({ open, htmlCode, closeCode, saveHTML }) => {
    const [html, setHtml] = useState({ htmlPP: open, code: htmlCode });
    return (
        <Dialog
            open={html?.htmlPP}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => closeCode()}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full md:w-[35rem]  p-5 rounded bg-white shadow dark:bg-gray-700'>
                <div>
                    <label className='flex items-center justify-between text-gray-700 text-md font-medium mb-2' htmlFor='product_name'>
                        Source code
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                                closeCode()
                            }
                        }} className="cursor-pointer flex items-center justify-center rounded text-gray-600 duration-200 hover:bg-[#f5f5f5] hover:text-black w-6 h-6">
                            <X className="w-4 h-4" />
                        </button>
                    </label>
                    <hr className='my-4 border-gray-300' />
                    <textarea
                        className='w-full block px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        rows={16}
                        value={html?.code}
                        onChange={(e) => setHtml({ ...html, code: e.target.value })}
                        placeholder='<p>Code here<p/>'
                    />
                </div>
                <button
                    className='btn font-medium text-sm mt-4 w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                    onClick={() => saveHTML(html?.code)}
                >
                    Edit source code
                </button>
            </div>
        </Dialog>
    )
}

export default SourceCode