import {
    Trash,
    X
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { addDomains, getDomains, removeDomain } from "../../Redux/Action/restrictedDomain.action";
import { ToastContainer } from "react-toastify";

const MyModules = () => {
    const dispatch = useDispatch();
    const { getDomain } = useSelector((state) => state.restrictedDomainReducer)
    const [restrictedDomains, setRestrictedDomainsInfo] = useState([]);
    const [domains, setDomains] = useState([]);
    const [domainsArray, setDomainsArray] = useState([]);
    const [domainsCount, setDomainsCount] = useState(0);

    const GenerateSlugName = async (name) => {
        const normalizedName = name
            .trim()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
            .replace(/[^a-zA-Z\-]/g, '') // Remove special characters and numbers
            .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen


        return normalizedName;
    };

    // save Group Values In Array
    const SaveDomainsValue = (e) => {
        e.preventDefault();
        if (domains.length > 0) {
            if (domains.includes(',')) {
                let TagSeperator = domains.split(',');
                let finalArray = []
                let newDomains = [...domainsArray, ...TagSeperator]
                newDomains.filter(tag => {
                    if (tag) {
                        GenerateSlugName(tag).then((filteredTag) => {
                            if (filteredTag?.length >= 3) {
                                const searchRegex = new RegExp(`^${filteredTag}$`, "i");
                                let exists = finalArray.some((finalTag) => searchRegex.test(finalTag))
                                if (!exists) {
                                    finalArray.push(filteredTag)
                                }
                            }
                        }).catch((e) => console.log(e));
                    }
                });
                setDomainsArray(finalArray);
            } else {
                GenerateSlugName(domains).then((filteredDomain) => {
                    if (filteredDomain?.length >= 3) {
                        const searchRegex = new RegExp(`^${filteredDomain}$`, "i");
                        const exists = domainsArray.some((tag) => searchRegex.test(tag));
                        if (!exists) {
                            setDomainsArray((prev) => [...prev, filteredDomain]);
                        }
                    }
                }).catch((e) => console.log(e));
            }
            setDomains('');
        }
    };

    // remove Tag
    const RemoveTag = (index) => {
        if (index > -1) {
            // only splice array when item is found
            domainsArray.splice(index, 1); // 2nd parameter means remove one item only
        }
        setDomainsArray([...domainsArray]);
    };

    // Get Module Information
    const GetRestrictedDomains = async () => {
        try {
            dispatch(getDomains());
        } catch (error) {
            console.log(error);
        }
    };

    const DomainsSave = async () => {
        try {
            if (domainsArray?.length) {
                dispatch(addDomains(domainsArray));
            }
            setDomainsArray([]);
        } catch (error) {
            console.log(error)
        }
    }

    const DomainRemove = async (domain) => {
        if (window.confirm(`Remove "${domain}" Domain?`)) {
            try {
                dispatch(removeDomain(domain));
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        GetRestrictedDomains();
    }, []);

    useEffect(() => {
        if(getDomain) {
            setRestrictedDomainsInfo(getDomain?.data);
            setDomainsCount(getDomain?.data?.length);
        }
    },[getDomain])

    return (
        <>
            <div className="w-full pb-[64px]">
                <div>
                    <Breadcrumbs crumbs={[
                        { name: 'Dashboard', path: `/dashboard` },
                        { name: 'Restricted Domains', path: `/Restricted-Domains` },
                    ]} />
                    <h1 className='text-xl md:text-2xl mb-4 font-semibold text-primary'>Restricted Domains</h1>
                </div>
                <div>
                    <form className="">
                        <label className='flex items-center justify-between' htmlFor='Domains'>
                            Domains
                            <h1 className='text-gray-500 font-medium text-xs'>
                                Use <b className='text-black'>Enter</b> key to comma separate the domains
                            </h1>
                        </label>
                        <div className="flex">
                            <textarea
                                className='block w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                type='text'
                                id='domains'
                                name='domains'
                                value={domains}
                                onChange={(e) => setDomains(e.target.value)}
                                onKeyDown={(e) => (e.key === 'Enter' ? SaveDomainsValue(e) : '')}
                                placeholder='e.g. admin, login'
                            />
                            <div className='flex flex-wrap items-center mt-2'>
                            </div>
                        </div>
                        <small className="flex items-center justify-between">NOTE: Minimum 3 characters acceptable for each domain.</small>

                        {domainsArray.length > 0 &&
                            <>
                                {domainsArray.map((elem, index) => {
                                    if (elem !== "") {
                                        return (
                                            <span
                                                key={index}
                                                className='bg-[#f1f1f1] inline-flex items-center me-2 mb-2 mt-2 min-w-[50px] text-black text-xs text-center px-3 py-1.5 rounded-[10px]'
                                            >
                                                {elem}
                                                <X
                                                    className='text-black cursor-pointer h-[14px] w-[14px] ms-2'
                                                    onClick={() => RemoveTag(index)}
                                                />
                                            </span>
                                        );
                                    }
                                })}
                            </>
                        }
                    </form>
                    {domainsArray.length > 0 &&
                        <button
                            type="button"
                            className="btn px-3 py-1 text-white rounded"
                            onClick={DomainsSave}
                        >
                            Submit
                        </button>
                    }
                </div>
                <div className="flex space-x-4 mt-5">
                    <div className="w-full space-y-4">
                        <div className="w-full overflow-x-auto">
                            <table className="w-full text-left border border-slate-300">
                                <thead className="h-[45px]">
                                    <tr>
                                        <th className="flex items-center space-x-1 cursor-pointer h-[45px] min-w-[300px] px-4">
                                            <span>Domains</span> <span className="text-base">({domainsCount})</span>
                                        </th>
                                        <th className="cursor-pointer min-w-[100px] text-right pe-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        restrictedDomains?.length ? restrictedDomains?.map((elem, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="bg-white w-full cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600"
                                                >
                                                    <td className="p-3"> {elem} </td>
                                                    <td className="space-x-3 text-right pe-3">
                                                        <button onClick={() => DomainRemove(elem)}>
                                                            <Trash className="w-[18px] h-[18px] text-red-500" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                            :
                                            <p className="text-gray-600 p-3">No data found</p>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default MyModules;
