import React from 'react'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewTenants } from '../../Redux/Action/tenant.action';
import { ToastContainer } from 'react-toastify';

const ViewTenant = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { getPerticularTenantData } = useSelector((state) => state.tenantReducer)
  useEffect(() => {
    dispatch(viewTenants(params.tenant_id));
  }, []);
  // const location = useLocation()
  // const { tenant_id, tenant_name, unique_identifier, tenant_email, custom_domain, customer_domain, business_name, business_category } = location.state
  return (
    <>
      <div className="flex space-y-2 pb-[64px] lg:space-y-0 items-end justify-between mb-4">
        <h1 className="text-3xl font-medium text-center text-primary">
          Tenant Information
        </h1>
        <button
          className="border py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-800 text-white"
          type="submit"
          onClick={() => navigate("/tenants")}
        >
          My Tenants
        </button>
      </div>
      {/* <h1 className='text-3xl font-medium pb-4'>Tenant Information</h1> */}
      <div className='bg-white  p-3 shadow-sm rounded-sm'>
        <div className='flex items-center justify-between text-2xl space-x-2 font-semibold text-gray-900 leading-8'></div>
        <div className='text-gray-700 flex items-start '>
          <div className='grid grid-cols-1 text-sm'>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Tenant ID:</div>
              <div className='px-4 py-2'> {getPerticularTenantData?.tenant_id}</div>
            </div>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Tenant Name:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.tenant_name}</div>
            </div>
            <div className='grid grid-cols-2 mb-2'>
              <div className='px-4 py-2 font-semibold'>Unique Identifier:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.unique_identifier}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Email:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.tenant_email}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Customer Domain:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.customer_domain}</div>
            </div>
            {getPerticularTenantData?.custom_domain &&
              <div className='grid grid-cols-2'>
                <div className='px-4 py-2 font-semibold'>Custom Domain:</div>
                <div className='px-4 py-2'>{getPerticularTenantData?.custom_domain}</div>
              </div>
            }
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Business Name:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.business_name}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Business Category:</div>
              <div className='px-4 py-2'>{getPerticularTenantData?.business_category}</div>
            </div>
            <div className='grid grid-cols-2'>
              <div className='px-4 py-2 font-semibold'>Verify Status:</div>
              <div className='px-4 py-2 capitalize'>{getPerticularTenantData?.verify_status?.replace("_", " ")}</div>
            </div>
            {
              getPerticularTenantData?.delete_store_reason ?
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>Delete Store Reason:</div>
                  <div className='px-4 py-2 capitalize'>
                    <ul className='list-disc'>
                      {Object.keys(getPerticularTenantData?.delete_store_reason).map(key => (
                        <li className='ms-4' key={key}>{getPerticularTenantData?.delete_store_reason[key]}</li>
                      ))}
                    </ul>
                  </div>
                </div> : <></>
            }
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

export default ViewTenant