import { ArrowDown, ChevronDown, ChevronUp, Edit, Eye, Filter, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { deleteChangelog, getAllChangelogCategories, getChangelog, getPerticularLog } from '../../Redux/Action/changelog.action'
import DataTable from 'react-data-table-component'
import { Autocomplete, Pagination, Popover, TextField } from '@mui/material'
import Breadcrumbs from '../../controllers/breadCrumbs';

const Changelog = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedRowsId, setSelectedRowsId] = useState([])
  const [filterDD, setFilterDD] = useState(false)
  const [viewChangelog, setViewChangelog] = useState(false)
  const [page, setPage] = useState(1)
  const [viewData, setViewData] = useState()
  const [limit, setLimit] = useState(10)
  const [iconVisible, setIconVisible] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [filterCat, setFilterCat] = useState('')
  const [orderBy, setOrderBy] = useState({
    orderByValue: "",
    isDecending: false,
  });
  // Sorting Data
  const SortData = (sort_by) => {
    setSortBy(sort_by);
    setIconVisible(true);
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: "DESC", isDecending: true });
    } else {
      setOrderBy({ orderByValue: "ASC", isDecending: false });
    }
  };

  const { allChangelog, allChangelogCategories } = useSelector(state => state.changelogReducer)

  useEffect(() => {
    dispatch(getChangelog(page, limit, sortBy, orderBy.orderByValue, filterCat))
    dispatch(getAllChangelogCategories('', ''));
  }, [page, limit, sortBy, orderBy, filterCat])


  const columns = [
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
            onClick={() => SortData("title")}
          >
            Title
          </button>
          {iconVisible && sortBy === "title" ? (
            !orderBy.isDecending ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      minWidth: "200px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">
          {row.title}
        </span>
      ),
    },
    {
      name: "TYPE",
      minWidth: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">{row.type}</span>
      ),
    },
    {
      name: "Category Name",
      minWidth: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize">{row.category_name}</span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
            onClick={() => SortData("short_description")}
          >
            Short Description
          </button>
          {iconVisible && sortBy === "short_description" ? (
            !orderBy.isDecending ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )
          ) : (
            <></>
          )}
        </div>
      ),
      cell: (row) => <p className="line-clamp-2">{row.short_description}</p>,
    },
    {
      name: <p className="w-full text-right block">ACTION</p>,
      cell: (row) => (
        <div className="space-x-3 w-full flex items-center justify-end">
          <button
            onClick={async (e) => {
              navigate(`/changelog/update-changelog/${row?._id}`
              );
            }}
          >
            <button className="">
              <Edit className="w-[17px] h-[17px] text-blue-500" />
            </button>
          </button>

          <button onClick={() => dispatch(deleteChangelog(row?._id))}>
            <button className="">
              <Trash className="w-[17px] h-[17px] text-red-500" />
            </button>
          </button>
          <button
            onClick={() => {
              setViewData(row);
              setViewChangelog(true)
            }
            }
          >
            <button className="">
              <Eye className="w-[17px] h-[17px]" />
            </button>
          </button>
        </div>
      ),
    },
  ];



  return (
    <div className=" w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Changelog', path: `/changelog` },
          ]} />
          <h1 className='text-xl md:text-2xl font-semibold text-primary'>Changelog</h1>
        </div>
        <div className="flex space-x-3">
          <Link
            to={"/changelog/add-changelog"}
            className="flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
            type="button"
          //   onClick={HandleSubmit}
          >
            <PlusCircle className="w-4 h-4 me-2" />
            Add Changelog
          </Link>
          <button
            className="filter-btn rounded w-10 h-10"
            onClick={() => setFilterDD(!filterDD)}
          >
            <Filter className="inline-flex rounded-md w-5 h-5" />
          </button>
        </div>

      </div>
      <details
        className={
          selectedRowsId?.length === 0
            ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
            : `cursor-pointer group w-[200px] mb-1.5 relative`
        }
      >
        <summary className="flex bg-white border border-gray-300 px-2 py-2 text-sm rounded justify-between items-center list-none">
          <span> Bulk Selection</span>
          <span
            className={
              selectedRowsId?.length === 0
                ? "transition group-open:rotate-0"
                : "transition group-open:rotate-180"
            }
          >
            <svg
              fill="none"
              height={14}
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width={14}
            >
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
        </summary>
        <div
          className={
            selectedRowsId?.length === 0
              ? `hidden`
              : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
          }
        >
          <button
            className="mt-1 text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
            onClick={() => dispatch(deleteChangelog(selectedRowsId))}
          >
            <Trash className="w-3.5 h-3.5" />
            <span>Delete selection</span>
          </button>
        </div>
      </details>
      <div className="w-full flex space-x-4">
        <div className="w-full space-y-3">
          <div className="w-full overflow-x-auto">
            <div className="w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]">
              <DataTable
                columns={columns}
                data={allChangelog?.records}
                fixedHeader
                selectableRows
                fixedHeaderScrollHeight="700px"
                onSelectedRowsChange={({ selectedRows }) => {
                  setSelectedRowsId(selectedRows?.map((x) => x._id));
                }}
                selectableRowsHighlight
                highlightOnHover
              />
            </div>
          </div>
          {allChangelog?.records && (
            <div className="bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[4px]">
              <div className="hidden sm:block">
                <p className="text-sm flex text-gray-700 space-x-4">
                  <div>
                    Current Page :
                    <span className="font-semibold ms-1">
                      {allChangelog?.currentPage || ""}
                    </span>
                  </div>
                  <div>
                    Total Records :
                    <span className="font-semibold ms-1">
                      {allChangelog?.totalRecords || ""}
                    </span>
                  </div>
                </p>
              </div>
              <div>
                {allChangelog?.totalPages !== 1 ? (
                  <Pagination count={allChangelog?.totalPages} page={page} onChange={(e, v) => setPage(v)} />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filterDD ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
          <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
            <Filter className="me-1 w-4 h-4" />
            Filter
          </p>
          <hr className="border-[#a0d0eb] my-3"></hr>
          <div className="grid gap-y-3 max-w-full overflow-hidden">
            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Search
                </span>
                <label className="relative block w-full">
                  <input
                    className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    placeholder="Search for anything..."
                    type="text"
                    name="search"
                    value={filter.search}
                    onChange={(e) => setFilter({ search: e.target.value })}
                  />
                </label>
              </div> */}
            <div>
              <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                Limit
              </span>
              <div className="relative">
                <select
                  onChange={(e) => {
                    setLimit(e.target.value)
                  }}
                  name="verification_status"
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                  value={limit}
                >
                  <option value="">All</option>
                  <option >10</option>
                  <option >20</option>
                  <option >50</option>
                </select>
                <div className="select-arrow"></div>
              </div>
            </div>
            <div>
              <Autocomplete
                className="autocomplete "
                options={
                  allChangelogCategories?.records
                    ? allChangelogCategories.records
                    : []
                }
                getOptionLabel={(category) => category.category_name}
                onChange={(e, v) =>
                  v &&
                  setFilterCat(v?._id)
                }
                renderInput={(products) => (
                  <TextField
                    className="py-1"
                    placeholder="Select changelog category products"
                    {...products}
                  />
                )}
              />
            </div>
            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort by
                </span>
                <select
                  name="receiver"
                  value={filter.a_type}
                  onChange={(e) => setFilter({ ...filter, a_type: e.target.value })}
                  className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                >
                  <option value="">All</option>
                  <option value="admin">Admin</option>
                  <option value="affiliate">Affiliate</option>
                  <option value="customer">Customer</option>
                </select>
              </div> */}

            {/* <div>
                <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                  Sort By Store Status
                </span>
                <div>
                  <>
                    <select
                      onChange={(e) => {
                        if (e.target.value === "All") {
                          setFilter({ ...filter, store_status: "" });
                        } else {
                          setFilter({
                            ...filter,
                            store_status: e.target.value,
                          });
                        }
                      }}
                      name="verification_status"
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                      value={store_status}
                    >
                      <option>All</option>
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </select>
                  </>
                </div>
              </div> */}
          </div>
        </div>
      </div>
      {viewChangelog && (
        <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
          <Popover
            open={viewChangelog}
            className="h-screen"
            onClose={() => {
              setViewChangelog(false)
              // setAddCategoryData({ ...addCategoryData, category_name: "" });
            }}
          >
            <div className="w-[800px] max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
              <div className="w-full flex p-2 justify-between">
                <h1 className="text-xl font-medium">Changelog Details</h1>
                <X
                  className="w-5 cursor-pointer"
                  onClick={() => {
                    setViewChangelog(false)
                    // setAddCategoryData({ ...addCategoryData, category_name: "" });
                  }}
                />
              </div>
              <hr />
              <div className='text-gray-800 p-2 w-full flex items-center'>
                <div className='font-semibold w-full'>
                  Title: <span className='text-2xl text-black font-bold text-center'>{viewData.title}</span>
                </div>

              </div>
              <div className='p-2 font-medium'>
                <span className='font-medium'>Short Description: </span><span>{viewData.short_description}</span>
              </div>
              <div className='p-2' dangerouslySetInnerHTML={{ __html: viewData.long_description }} />
              <div className="w-full px-3 max-md:px-5">


                <div
                  className={`w-fit text-sm font-medium rounded-[5px] px-2 ${viewData?.type === "Improvement"
                    ? "bg-yellow-100"
                    : viewData?.type === "Removed"
                      ? "bg-red-200"
                      : viewData?.type === "Changed"
                        ? "bg-[#90DFD6]"
                        : viewData?.type === "Feature"
                          ? "bg-green-200"
                          : viewData?.type === "New"
                            ? "bg-blue-100"
                            : ""
                    }`}
                >
                  {viewData?.type}
                </div>

              </div>


            </div>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default Changelog