import React, { useState } from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { ArrowLeft, Check, ChevronDown, Edit } from 'feather-icons-react/build/IconComponents';
import TextEditor from '../../../controllers/TextEditor';
import { Link } from 'react-router-dom';
import API from '../../../API';
import { useDispatch } from 'react-redux';
import { addDynamicPage } from '../../../Redux/Action/website.action';
import { ToastContainer } from 'react-toastify';

const AddDynamicPage = () => {
    const dispatch = useDispatch();
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const [meta, setMeta] = useState({
        meta_title: '',
        meta_description: ''
    })
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const [enableSlug, setEnableSlug] = useState(false);
    const [err, setErr] = useState(false)
    const [content, setContent] = useState()
    const handleEditorData = (value) => {
        setContent(value)
    }
    const [pageName, setPageName] = useState('')
    const [slugField, setSlugField] = useState(false);
    const [slugName, setSlugName] = useState();
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };
    const handleAddPage = async (e) => {
        e.preventDefault();
        try {
            const body = {
                pages_name: pageName,
                description: content,
                page_slug_name: slugName,
                ...meta,
            }
            dispatch(addDynamicPage(body));
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Add new page
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[80%] mx-auto"
                    >
                        <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                            <div className="w-full">
                                <label
                                    htmlFor="title"
                                    className="flex items-center justify-between"
                                >
                                    <p className="text-[13px]">
                                        Page Name
                                        <span className="text-red-500 ms-1">
                                            *
                                        </span>
                                    </p>
                                </label>
                                <input
                                    className={`w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                                    type="text"
                                    name="title"
                                    value={pageName}
                                    onChange={(e) => {
                                        setPageName(e.target.value);
                                    }}
                                    placeholder="e.g. Affliate Page"
                                    onBlur={() => handleSlug(pageName)}
                                />
                            </div>
                            {slugField && (
                                <div className="w-full">
                                    <label htmlFor="category_name">
                                        Page Slug name
                                    </label>
                                    <div
                                        className={`flex items-center justify-between w-full border ${err && enableSlug
                                            ? "border-red-500"
                                            : "border-gray-300"
                                            } rounded-[4px]`}
                                    >
                                        <input
                                            className="w-full  focus:outline-none"
                                            type="text"
                                            name="blog_slug_name"
                                            value={slugName}
                                            disabled={!enableSlug}
                                            onChange={(e) =>
                                                setSlugName(e.target.value)
                                            }
                                        />
                                        {enableSlug ? (
                                            <Check
                                                onClick={async () => {
                                                    setSlugName(
                                                        await GenerateSlugName(
                                                            slugName
                                                        )
                                                    );
                                                    setEnableSlug(!enableSlug);
                                                    setErr(false);
                                                }}
                                                className="text-green-500 p-1 h-8 w-8"
                                            />
                                        ) : (
                                            <Edit
                                                onClick={() =>
                                                    setEnableSlug(!enableSlug)
                                                }
                                                className="text-blue-500 p-1 h-7 w-7"
                                            />
                                        )}
                                    </div>
                                    {err && enableSlug && (
                                        <p className="text-xsm text-red-500">
                                            Please save this changes
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className="mb-4">
                                <TextEditor label={'Page Content'} handleEditorData={handleEditorData} defaultValue={content} />
                            </div>
                        </div>
                        <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
                            <summary
                                htmlFor="Meta Tag Title"
                                className="text-base flex items-center justify-between font-medium text-black"
                            >
                                <div>
                                    <span>Search engine listing</span>
                                    <p className="text-xs text-gray-500 font-normal">
                                        Add a title and description to see how
                                        this product might appear in a search
                                        engine listing
                                    </p>
                                </div>
                                <span className="transition group-open:rotate-180">
                                    <ChevronDown className="text-black w-4 h-5" />
                                </span>
                            </summary>
                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag Title"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Title
                                    </label>
                                </div>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag Title"
                                    name="meta_title"
                                    value={meta?.meta_title}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta title"
                                />
                            </div>

                            <div className="mb-4">
                                <div className="flex items-center justify-between">
                                    <label
                                        htmlFor="Meta Tag description"
                                        className="flex items-center justify-between"
                                    >
                                        Meta Description
                                    </label>
                                </div>
                                <textarea
                                    className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                    type="text"
                                    id="Meta Tag description"
                                    name="meta_description"
                                    value={meta?.meta_description}
                                    onChange={(e) => {
                                        setMeta({
                                            ...meta,
                                            [e.target.name]: e.target.value,
                                        });
                                    }}
                                    placeholder="Enter meta description"
                                />
                            </div>

                        </details>
                        <div className="flex items-center justify-between w-full">
                            <Link
                                to={`/site-data/page`}
                                className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                            >
                                <ArrowLeft className="w-3 mr-1" />
                                Go to pages
                            </Link>
                            <button
                                className={
                                    content?.length > 0 && pageName?.length > 0
                                        ? "inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        : "font-medium text-sm text-white opacity-40 btn cursor-not-allowed"
                                }
                                type="button"
                                onClick={(e) => handleAddPage(e)}
                                disabled={content?.length <= 0 && pageName?.length <= 0}
                            >
                                Add New Page
                            </button>
                        </div>
                    </form>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default AddDynamicPage