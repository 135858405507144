import API from "../../API";

export const getAllChangelogCategories = (page, limit) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/logcategory/getAllCategory`, method: 'get', params: { page, limit }
      });
      dispatch({
        type: "GET_ALL_CHANGELOG_CATEGORIES",
        payload: ApiCall,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const addChangelogCategory = (data) => {
  return async (dispatch) => {
    try {
      await API({ url: "/super/admin/logcategory/AddCategory", method: 'post', data: data});
      await dispatch(getAllChangelogCategories(1, 10));
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateChangelogCategory = (data) => {
  return async (dispatch) => {
    try {
      await API({ url: `/super/admin/logcategory/updateCategory/${data?.cat_id}`, method: 'put', data: data });
      await dispatch(getAllChangelogCategories(1, 10));
    } catch (error) {
      console.log(error);
    }
  };
};

export const changelogCategoryDelete = (data) => {
  return async (dispatch) => {
    try {
      if(window.confirm("Are you sure to remove log category?")){
      let body;
      if (Array.isArray(data)) {
        body = data;
      } else {
        body = [data];
      }
      let Api=await API({ url: `/super/admin/logcategory/DeleteCategory`, method: 'post', data: { id: body }});
      if(Api.status===200 || Api.status===304){
        dispatch(getAllChangelogCategories(1, 10));
      }
    }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getChangelog = (page,limit,sort_by,order_by,cat_id) => {
  return async (dispatch) => {
    try {
     let Apicall=await API({ url: `/super/admin/changeLog/getLogList`, method: 'post', data: {
     page:page||'',
     limit:limit||'',
     sort_by:sort_by||'',
     order_by:order_by||'',
     categories:cat_id?[cat_id]:''
     }})
     dispatch({
        type:"GET_CHANGELOG",
        payload:Apicall
     })
    } catch (error) {
      console.log(error);
    }
  };
};

export const addChangelog= (data)=>{
    return async dispatch =>{
        try {
            await API({ url: `/super/admin/changeLog/AddLog`,method: 'post', data: data})
            await dispatch(getChangelog())
        } catch (error) {
           console.log(error) 
        }
    }
}

export const updateChangelog=(data,log_id)=>{
    return async dispatch =>{
        try {
            await API({ url: `/super/admin/changeLog/updateLog/${log_id} `, method: 'put', data: data})
            await dispatch(getChangelog())
        } catch (error) {
           console.log(error) 
        }
    }
}

export const deleteChangelog=(ids)=>{
    return async dispatch =>{
        try {
          if(window.confirm("Are you sure to remove log?")){
          let body;
            if (Array.isArray(ids)) {
              body = ids;
            } else {
              body = [ids];
            }
            await API({ url: `/super/admin/changeLog/DeleteLog`,method: 'post', data: {id:body}})
            await dispatch(getChangelog())
          }
         
        } catch (error) {
           console.log(error) 
        }
    }
}
export const getPerticularLog=(id)=>{
    return async dispatch =>{
        try{
              let Apicall=await API({ url: `/super/admin/changeLog/getLog/${id}`, method: 'get' })
                dispatch({
                    type:"GET_PERTICULAR_LOG",
                    payload:Apicall
                })
        }
        catch (error) {
           console.log(error) 
        }
    }
}
