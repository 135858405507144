import React, { useState } from "react";
import AmountsTobeSettle from "./AmountsTobeSettle";
import SettleAmount from "./SettleAmount";
import Breadcrumbs from "../../controllers/breadCrumbs";
const Payments = () => {
  const [paymentSec, setpaymentSec] = useState("tobeSettled");
  return (
    <>
      <div className=" w-full pb-[64px]">
        <div>
          <Breadcrumbs crumbs={[
            { name: 'Dashboard', path: `/dashboard` },
            { name: 'Payments', path: `/payments` },
          ]} />
          <h1 className='text-xl md:text-2xl mb-4 font-semibold text-primary'>Payments</h1>
        </div>
        <div className="bg-white border border-gray-100 p-4 mx-auto rounded-lg">
          <ul
            className="flex items-center mb-0 list-none overflow-x-auto flex-row"
            role="tablist"
          >
            <li className='text-center mb-2 lg:mb-0'>
              <a
                className={
                  "text-xs font-bold break-keep uppercase px-5 xl:px-10 py-4 block leading-normal transition-all duration-150 " +
                  (paymentSec === "tobeSettled"
                    ? 'text-link border-x border-t border-gray-300 border-b border-b-white'
                    : 'text-black bg-white border-b border-gray-300 border-x border-x-white border-t border-t-white')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setpaymentSec("tobeSettled");
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                To Be Settle
              </a>
            </li>
            <li className="'text-center mb-2 lg:mb-0">
              <a
                className={
                  "text-xs font-bold break-keep uppercase px-5 xl:px-10 py-4 block leading-normal transition-all duration-150 " +
                  (paymentSec === "settled"
                    ? 'text-link border-x border-t border-gray-300 border-b border-b-white'
                    : 'text-black bg-white border-b border-gray-300 border-x border-x-white border-t border-t-white')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setpaymentSec("settled");
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Settled
              </a>
            </li>
            <li className='text-center flex-auto mb-2 lg:mb-0'>
              <a className='text-xs text-white select-none font-bold uppercase border-b border-gray-300 px-5 py-4 block leading-normal transition-all duration-150'>
                demo
              </a>
            </li>
          </ul>
          <div className="w-full border-x border-b border-gray-300 bg-white">
            {
              paymentSec === "tobeSettled" ? <AmountsTobeSettle /> : <SettleAmount />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
