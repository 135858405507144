import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { Autocomplete, TextField } from '@mui/material';
import API from '../../API';

function Chat() {
  const socket = io(process.env.REACT_APP_SOCKET_URL);
  const token = localStorage.getItem('token');
  const chatRef = useRef(null);
  if (token) {
    socket.emit('addSuperAdmin', { token });
  }

  const [message, setMessage] = useState('');
  const [userMsgs, setUserMsgs] = useState([]);
  const [customer, setCustomer] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [clientData, setClientData] = useState();

  const HandleClientInfo = async () => {
    await API({
      url: `/super/admin/chat/getLoginUser`, 
      method: "get",
    }
    ).then(async (res) => {
      if (res.status === 200 || res.status === 304) {
        let data = await res.data;
        setClientData(data?.data);
      }
    }).catch(error => {
      console.log(error);
    })
  }



  const HandleSocket = async () => {
    socket.on('msg_get', async ({ message, sender, sender_name_for_live }) => {
      setUserMsgs([...userMsgs, { message, sender, sender_name_for_live }]);
    });

    socket.on('msg_receive', async ({ message, sender, sender_name_for_live }) => {
      setUserMsgs([...userMsgs, { message, sender, sender_name_for_live }]);
    });

    chatRef.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };
  const [staff, setstaff] = useState();
  const [staffDD, setStaffDD] = useState(false);
  const [chatDetails, setChatDetails] = useState();

  const GetStaff = async () => {
    await API({
      url: `/super/admin/chat/getStaffList/${chatDetails?._id}`, 
      method: "get",
    }
    ).then(async (res) => {
      if (res.status === 200 || res.status === 304) {
        let data = await res.data;
        setstaff(data);
      }
    }).catch(error => {
      console.log(error);
    })
  }

  useEffect(() => {
    HandleSocket();
  }, [socket]);

  const saveMsg = async () => {
    try {
      if (message && message?.trim() && clientData?._id && selectedCustomer?.user) {
        socket.emit('superAdminChat', {
          url: `${process.env.REACT_APP_BACKEND_URL}/super/admin/chat/AddChat`,
          message,
          sender: clientData?._id,
          sender_name_for_live: clientData?.name ? clientData.name : clientData?.email,
          customer: selectedCustomer?.user,
          headers: {
            token: token,
          }
        });
      }
      setMessage('');
    } catch (error) {
      console.log(error);
    }
  }

  const getMsg = async () => {
    if (selectedCustomer) {
      await API({
        url: `/super/admin/chat/getChat/${selectedCustomer?.user}`, 
        method: "get",
      }
      ).then(async (res) => {
        if (res.status === 200 || res.status === 304) {
          getMsgs();
          let data = await res.data;
          setChatDetails(data?.data);
          setUserMsgs(data?.data?.chat ? data?.data?.chat : []);
        }
      }).catch(error => {
        console.log(error);
      })
    }
  }

  const getMsgs = async () => {
    await API({
      url: `/super/admin/chat/getChatList`, 
      method: "get",
    }
    ).then(async (res) => {
      if (res.status === 200 || res.status === 304) {
        let data = await res.data;
        setCustomer(data);
      }
    }).catch(error => {
      console.log(error);
    })
  }

  const assignStaff = async (userID) => {
    if (chatDetails && !chatDetails?.assign_staff?.includes(userID)) {
      socket.emit('assignSuperAdmin', {
        userId: selectedCustomer?.user,
        staff_ids: chatDetails?.assign_staff?.length ? [...new Set([...chatDetails?.assign_staff, userID])] : [clientData?._id],
      });

      await API({
        url: `/super/admin/chat/UpdateChat/${chatDetails?._id}`, 
        method: "put",
        data: {
          assign_staff: chatDetails?.assign_staff?.length
            ? [...new Set([...chatDetails?.assign_staff, userID])]
            : [clientData?._id]
        }
      }
      ).then(async (response) => {
        if (response.status === 200 || response.status === 304) {
          let data = await response.data;
          setChatDetails(data?.data);
        }
        setStaffDD(false)
      }).catch(error => {
        console.log(error);
      })
    }
  }


  useEffect(() => {
    HandleClientInfo();
    getMsgs();
  }, []);

  useEffect(() => {
    getMsg();
    getMsgs();
  }, [selectedCustomer]);

  useEffect(() => {
    assignStaff(clientData?._id);
  }, [chatDetails]);

  return (
    <>
      <div className='relative w-full pb-[64px] bg-[#f5f5f5] h-full overflow-hidden'>
        <h3 className='text-3xl font-medium mb-4 text-primary'>Chat</h3>
        <div className='w-full overflow-hidden'>
          <div className='flex h-full antialiased text-gray-800'>
            <div className='flex h-screen w-full overflow-hidden '>
              <div className='p-4 w-1/4 h-[48rem] rounded bg-white border border-gray-300'>
                <div className='flex flex-col '>
                  <span className='font-bold flex flex-row items-center justify-between text-sm'>
                    Active Conversations
                  </span>
                  <div className='flex flex-col space-y-1 mt-4 -mx-2 max-h-full overflow-y-auto'>
                    {customer?.data?.map((elem) => {
                      return (
                        <>
                          <button
                            className={
                              selectedCustomer?.user === elem?.user
                                ? 'flex w-full items-center justify-between bg-gray-100 rounded-xl p-2'
                                : 'flex hover:bg-gray-100 justify-between w-full items-center bg-white rounded-xl p-2'
                            }
                            onClick={() => setSelectedCustomer(elem)}
                          >
                            <div className='flex items-center'>
                              <div className=' items-center justify-center uppercase flex overflow-hidden h-10 w-10 bg-gray-300 rounded-full'>
                                {
                                  elem?.user_profile ? <img src={elem?.user_profile} alt={elem?.user_profile} className='object-cover object-top w-full h-full' /> :
                                    <span className='font-medium uppercase'>{elem?.user_name?.charAt(0)}</span>
                                }
                              </div>
                              <div className=' ml-2 text-sm font-semibold capitalize'>
                                {elem?.user_name}
                              </div>
                            </div>
                            {
                              elem?.unreadCount > 0 &&
                              <div className='w-6 h-6 text-xs btn flex bg-opacity-50 items-center justify-center rounded-full text-white'><span className='text-xs inline-block'>{elem?.unreadCount}</span></div>
                            }
                          </button>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              {selectedCustomer ? (
                <>
                  <div className='w-full px-2 h-screen'>
                    <div className='flex flex-col flex-auto h-[48rem] rounded border border-gray-300 bg-white' id='scrollableDiv'>
                      <header className='flex bg-[#f1f1f1] flex-row items-center h-16 rounded-t-lg  w-full px-4 mb-1 py-02'>
                        {
                          <div className='w-full'>
                            {selectedCustomer?.user !== clientData?._id ? (
                              <div className='flex w-full items-center justify-between'>
                                <div className='flex items-center'>
                                  <span className='flex items-center justify-center uppercase h-9 w-9 text-lg overflow-hidden rounded-full bg-primary text-white'>
                                    {
                                      selectedCustomer?.user_profile ? <img src={selectedCustomer?.user_profile} alt={selectedCustomer?.user_profile} className='object-cover w-full h-full object-top' /> :
                                        <p>{selectedCustomer?.user_name?.charAt(0)}</p>
                                    }
                                  </span>
                                  <p className='ml-2 text- capitalize font-semibold'>
                                    {selectedCustomer?.user_name}
                                  </p>
                                </div>
                                <div className='relative'>
                                  <button
                                    className='secondary-btn text-white px-4 py-1 text-sm rounded-[4px]'
                                    onClick={() => { GetStaff(); setStaffDD(!staffDD) }}
                                  >
                                    Assign Chat
                                  </button>
                                  {staffDD && (
                                    <div className='absolute z-50 right-0 top-full mt-2 h-auto w-96 max-h-[30rem] divide-y-[1px] origin-top-right rounded-[4px] bg-white focus:outline-none'>
                                      <Autocomplete
                                        options={staff?.data ? staff.data : []}
                                        getOptionLabel={(staff) => staff?.name ? staff.name : staff?.email}
                                        onChange={(e, v) => {
                                          v?._id && assignStaff(v._id);
                                        }}
                                        renderInput={(StaffInfo) => (
                                          <TextField
                                            placeholder='Select Staff Member '
                                            Value={StaffInfo}
                                            className='cursor-pointer'
                                            {...StaffInfo}
                                          />
                                        )}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                      </header>
                      <div className='flex flex-col h-full overflow-x-auto mb-4'>
                        <div className='flex flex-col h-full'>
                          <div className='w-full'>
                            <div className='flex justify-between p-3 rounded-lg w-full'>
                              <div className='w-full'>
                                <div className='' ref={chatRef}>
                                  {userMsgs &&
                                    userMsgs?.map((elem) => {
                                      return (
                                        <>
                                          {
                                            selectedCustomer?.user === elem?.sender ? (
                                              <>
                                                <div className='col-start-1 col-end-8 rounded-lg'>
                                                  <div className='flex flex-row items-center'>
                                                    <div className='relative max-w-[80%] whitespace-pre-wrap break-words mb-3 text-sm bg-[#f1f1f1] py-2 px-4 rounded-t-xl rounded-br-xl'>
                                                      <div>{elem?.message}</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : (
                                              <div className='flex flex-col justify-end'>
                                                <div className='relative max-w-[80%] whitespace-pre-wrap break-words mt-3 ml-auto text-sm bg-indigo-50 py-2 px-4 rounded-t-xl rounded-bl-xl'>
                                                  <span>{elem?.message}</span>
                                                </div>
                                                <p className='flex justify-end pe-3 text-xs '>{
                                                  elem?.sender?.name
                                                    ? elem.sender.name
                                                    : elem?.sender?.email
                                                      ? elem.sender.email
                                                      : elem?.sender_name_for_live
                                                        ? elem.sender_name_for_live
                                                        : ''
                                                }</p>
                                              </div>
                                            )}
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='flex  flex-row items-center h-auto p-2 bg-[#f1f1f1] w-full'>
                        <div className='relative flex w-full items-center rounded overflow-hidden'>
                          <textarea
                            type='text'
                            className='flex items-center w-full max-h-[500px] px-5 py-2 outline-none'
                            name='message'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                saveMsg()
                              }
                            }
                            }
                            placeholder='Type your message..'
                          />
                        </div>

                        <div className='ml-4 mr-1'>
                          <button
                            className='flex items-center bg-black font-medium justify-center btn rounded-full text-white h-10 w-10'
                            onClick={() => saveMsg()}
                          >
                            <span className=''>
                              <svg
                                className='w-[17px] h-[17px] transform rotate-90 -mt-px'
                                fill='none'
                                stroke='currentColor'
                                viewBox='0 0 24 24'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  strokeWidth={2}
                                  d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className='flex items-center justify-center w-full h-[750px]'>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
