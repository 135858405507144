import { toast } from "react-toastify";
import API from "../../API";
import { GET_STORE_CATEGORY } from "../action.type";
export const getAllStores = (sort_by, order_by, searchkey, page, limit) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: `/super/admin/analytics/totalStores`, method: 'get', params: { sort_by: sort_by || "createdAt", order_by: order_by || "DESC", searchkey, page, limit }
      });
      dispatch({
        type: "GET_ALL_STORES",
        payload: ApiCall,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
export const getTotalStoreCustomers = (id, page, limit, sort_by, order_by,search) => {
  return async (dispatch) => {
    try {
      let ApiCall = await API({ url: 
      `/super/admin/store/customers/totalClientCustomers/${id}`, method: 'get', params: {
        key: search,
        page, limit, sort_by, order_by
      }
    });
    dispatch({
      type: "GET_STORE_CUSTOMERS",
      payload: ApiCall,
    });
    } catch (error) {
      console.log(error)
    }
    
  };
};
export const getTotalStoreProducts = (tenant,  page,  limit,  sort_by,  order_by,search) => {
  return async (dispatch) => {
    try {
        let ApiCall = await API({ url: `/super/admin/store/products/totalClientProducts/${tenant}`, method: 'post', params: {
        searchkey: search,
        page,
        limit,
        sort_by: sort_by || "createdAt",
        order_by: order_by || "DESC",
      }
    });
    dispatch({
      type: "GET_STORE_PRODUCTS",
      payload: ApiCall,
    });
    } catch (error) {
      console.log(error)
    }
  
  };
};
export const getTotalStoreOrders = (  tenant,  start_date,  end_date,  status,  order_type,  page,  limit,  sort_by,  order_by) => {
  return async (dispatch) => {
    try {
       let ApiCall = await API({ url: `/super/admin/store/orders/totalClientOrders/${tenant}`, method: 'post', 
      params: {
        start_date,
        end_date,
        page,
        limit,
        status,
        order_type,
        sort_by: sort_by || "createdAt",
        order_by: order_by || "DESC",
      }
    });
    dispatch({
      type: "GET_STORE_ORDERS",
      payload: ApiCall,
    });
    } catch (error) {
      console.log(error)
    }
   
  };
};

export const getTotalStorePlan = (id) => {
  return async (dispatch) => {
    try {
     let ApiCall = await API({ url: `/super/admin/store/plan/totalClientPlan/${id}`, method: 'post' });
    dispatch({
      type: "GET_STORE_PLANS",
      payload: ApiCall,
    });  
    } catch (error) {
      console.log(error)
    }
   
  };
};

export const getStoreCategory = () => {
  return async (dispatch) => {
    try {
     let ApiCall = await API({ url: `/super/admin/storecategory/getStoreCategory`, method: 'get' });
     if(ApiCall?.data) {
       dispatch({
         type: GET_STORE_CATEGORY,
         payload: ApiCall?.data,
       });  
     }
    } catch (error) {
      toast.error(error)
    }
   
  };
};

export const addStoreCategory = (categorys) => {
  return async (dispatch) => {
    try {
     let ApiCall = await API({ url: `/super/admin/storecategory/addStoreCategory`, method: 'post', data: { categorys } });
     if(ApiCall?.data) {
      toast.success(ApiCall?.data?.message);
      dispatch(getStoreCategory())
     }
    } catch (error) {
      toast.error(error)
    }
  };
};

export const deleteStoreCategory = (storeCategoryName) => {
  return async (dispatch) => {
    try {
     let ApiCall = await API({ url: `/super/admin/storecategory/deleteStoreCategory`, method: 'delete', data: { storeCategoryName } });
     if(ApiCall?.data) {
      toast.success(ApiCall?.data?.message);
      dispatch(getStoreCategory())
     }
    } catch (error) {
      toast.error(error)
    }
  };
};

export const updateStoreCategory = (id, data) => {
  return async (dispatch) => {
    try {
     let ApiCall = await API({ url: `/super/admin/storecategory/updateStoreCategory/${id}`, method: 'put', data: data });
     if(ApiCall?.data) {
      toast.success(ApiCall?.data?.message);
      dispatch(getStoreCategory())
     }
    } catch (error) {
      toast.error(error)
    }
  };
};
