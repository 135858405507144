import { Popover, Skeleton } from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../../API";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import { formatCode } from "../../../controllers/QuillToolbar";
import TextEditor from "../../../controllers/TextEditor";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { getParticularJob, updateJob } from "../../../Redux/Action/website.action";

const UpdateWes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { perticularJob } = useSelector((state) => state.websiteReducer);
    const params = useParams();
    const [warn, setwarn] = useState(false);
    const [elem, setElem] = useState();
    // image upload logic
    const [image, setimage] = useState();
    const [meta, setMeta] = useState({ meta_title: "", meta_description: "" });
    const { meta_description, meta_title } = meta;
    const [secondDescription, setSecondDescription] = useState();
    const [skillData, setSkillData] = useState();
    const [title, setTitle] = useState();
    const [experience, setExperience] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [sortOrder, setSortOrder] = useState();
    const [err, setErr] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const [formSpinner, setFormSpinner] = useState(true);
    const [color, setColor] = useState('');

    const [openState, setOpenState] = useState("");

    const handleCloseColor = () => {
        setOpenState("");
    };

    const getPerticularJob = async () => {
        setFormSpinner(true);
        dispatch(getParticularJob(params.job_slug_name))
    };
    useEffect(() => {
        getPerticularJob();
    }, []);

    useEffect(() => {
        if(perticularJob) {
            setElem(perticularJob?.data);
            setimage(perticularJob?.data?.image);
            setMeta({
                meta_title: perticularJob?.data?.meta_title || "",
                meta_description: perticularJob?.data?.meta_description || "",
            });
            setSortOrder(perticularJob?.data?.sort_order || "");
            setSecondDescription(
                perticularJob?.data?.description ? `${perticularJob?.data?.description}` : ""
            );
            setTitle(perticularJob?.data?.title ? `${perticularJob?.data.title}` : "");
            setExperience(
                perticularJob?.data?.experience ? `${perticularJob?.data.experience}` : ""
            );
            setSkillData(perticularJob?.data?.skills
                ? `${perticularJob?.data.skills
                }` : "");
            setShortDescription(
                perticularJob?.data?.short_description
                ? `${perticularJob?.data.short_description}`
                : ""
            );
            setColor(perticularJob?.data?.language_color || '')
            setSecondDescription(
                perticularJob?.data?.long_description
                ? `${perticularJob?.data.long_description}`
                : ""
            );

            setSlugName(
                perticularJob?.data?.job_slug_name ? `${perticularJob?.data.job_slug_name}` : ""
            );
            setFormSpinner(false);
        }
    }, [perticularJob]);

    const [slugName, setSlugName] = useState();

    const HandleUpdate = async (e) => {
        e.preventDefault();
        try {
            const body = {
                title: title,
                experience: experience,
                skills: skillData,
                short_description: shortDescription,
                long_description: secondDescription,
                job_slug_name: slugName,
                language_color: color,
                ...meta,
            }
            dispatch(updateJob(elem._id, body));
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };

    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const handleEditorData = (value) => {
        setSecondDescription(value);
    };

    const handleSkillData = (value) => {
        setSkillData(value);
    };

    //Quil To HTML code and Functions
    const quillRef = useRef(null);

    const [enableSlug, setEnableSlug] = useState(false);
    const [slugField, setSlugField] = useState(false);
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };

    const [html, setHtml] = useState({ htmlPP: false, code: "" });
    const { htmlPP, code } = html;
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML;
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false });
        setSecondDescription(code);
    };

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}

            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Update website job - {elem?.title}
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="w-[80%] space-y-4 sm:w-[70%] xl:w-[50%] mx-auto"
                    >
                        {formSpinner ? (
                            <div className="space-y-4">
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="flex items-center space-x-4 w-full">
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[45px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[200px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div>
                                        Media
                                        <p className="text-xs text-gray-500 font-normal">
                                            Upload captivating image to make
                                            your product stand out.
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-white group border border-gray-300  space-y-4 rounded-xl p-[25px]">
                                    <div className="w-full mb-4">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Job Title
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            name="title"
                                            // maxLength={60}
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="e.g. Tech"
                                            onBlur={() => handleSlug(title)}
                                        // onBlur={async () => setSlugName(await GenerateSlugName(title))}
                                        />
                                        {warn && (
                                            <h1 className="text-sm text-red-500">
                                                Please enter job title
                                            </h1>
                                        )}
                                    </div>
                                    {slugField && (
                                        <div className="w-full">
                                            <label htmlFor="category_name">
                                                job Slug name
                                            </label>
                                            <div
                                                className={`flex items-center justify-between w-full border ${err && enableSlug
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                    } rounded-[4px]`}
                                            >
                                                <input
                                                    className="w-full  focus:outline-none"
                                                    type="text"
                                                    name="job_slug_name"
                                                    value={slugName}
                                                    disabled={!enableSlug}
                                                    onChange={(e) =>
                                                        setSlugName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {enableSlug ? (
                                                    <Check
                                                        onClick={async () => {
                                                            setSlugName(
                                                                await GenerateSlugName(
                                                                    slugName
                                                                )
                                                            );
                                                            setEnableSlug(
                                                                !enableSlug
                                                            );
                                                            setErr(false);
                                                        }}
                                                        className="text-green-500 p-1 h-8 w-8"
                                                    />
                                                ) : (
                                                    <Edit
                                                        onClick={() =>
                                                            setEnableSlug(
                                                                !enableSlug
                                                            )
                                                        }
                                                        className="text-blue-500 p-1 h-7 w-7"
                                                    />
                                                )}
                                            </div>
                                            {err && enableSlug && (
                                                <p className="text-xsm text-red-500">
                                                    Please save this changes
                                                </p>
                                            )}
                                        </div>
                                    )}
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Job Experience
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className={
                                                (err &&
                                                    experience?.length === 0) ||
                                                    warn
                                                    ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                                    : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                            }
                                            type="text"
                                            name="title"
                                            // maxLength={60}
                                            value={experience}
                                            onChange={(e) => {
                                                setExperience(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="Enter job experience"
                                            onBlur={() =>
                                                handleSlug(experience)
                                            }
                                        />
                                        {(err && experience?.length === 0) ||
                                            warn ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter job experience
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="w-full mb-4">
                                        <label
                                            className=" text-gray-700 text-sm font-medium mb-1"
                                            htmlFor="short_description"
                                        >
                                            Short Description
                                        </label>
                                        <textarea
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500"
                                            type="text"
                                            placeholder="Short description"
                                            id="shortDescription"
                                            name="shortDescription"
                                            value={shortDescription}
                                            onChange={(e) => {
                                                setShortDescription(
                                                    e.target.value
                                                );
                                                // setwarn(false);
                                            }}
                                        // onChange={HandleInput}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <TextEditor
                                            label={"job description"}
                                            handleEditorData={handleEditorData}
                                            defaultValue={secondDescription}
                                        />
                                        {err &&
                                            secondDescription?.length === 0 && (
                                                <p className="text-red-500 text-xs">
                                                    Please enter description for
                                                    generate meta data
                                                </p>
                                            )}
                                    </div>
                                    <div className="mb-4">
                                        <TextEditor
                                            label={"Technical Skills"}
                                            handleEditorData={handleSkillData}
                                            defaultValue={skillData}
                                        />
                                    </div>
                                    <div className="w-full relative">
                                        <label>Language Color</label>
                                        <div
                                            onClick={() =>
                                                setOpenState("border_color")
                                            }
                                            className="flex cursor-pointer items-center border main-border-color p-1.5 mb-2 rounded space-x-2"
                                        >
                                            <div
                                                className="w-[30px] shadow h-[30px] rounded cursor-pointer"
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                            ></div>
                                            <span className="text-sm font-medium table-text">
                                                {color}
                                            </span>
                                        </div>
                                        {openState === "border_color" && (
                                            <SketchPicker
                                                color={color}
                                                onChange={(color) => {
                                                    setColor(color.hex)
                                                }}
                                                onBlur={() => setOpenState("")}
                                                onMouseLeave={handleCloseColor}
                                                className="absolute z-10"
                                            />
                                        )}
                                    </div>
                                </div>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Search engine listing</span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Add a title and description to
                                                see how this product might
                                                appear in a search engine
                                                listing
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag Title"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta title
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'title');
                            }}
                          >
                            Get meta title
                          </span> */}
                                            </div>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag Title"
                                                name="meta_title"
                                                value={meta_title}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="Enter meta title"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag description"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta description
                                                </label>
                                                {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'description');
                            }}
                          >
                            Get meta description
                          </span> */}
                                            </div>
                                            <textarea
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag description"
                                                name="meta_description"
                                                value={meta_description}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="e.g. We have best fashionable products"
                                            />
                                        </div>
                                    </div>
                                </details>
                                <div className="flex items-center justify-between w-full">
                                    <Link
                                        to={`/site-data/jobs`}
                                        className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                                    >
                                        <ArrowLeft className="w-3 mr-1" />
                                        Go to jobs
                                    </Link>
                                    <button
                                        className="inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        type="button"
                                        onClick={HandleUpdate}
                                    >
                                        Update Job
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
            {htmlPP && (
                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                    <Popover
                        open={htmlPP}
                        className="h-screen popup-content"
                    // onClose={() => {
                    //   setHtmlPP(false);
                    // }}
                    >
                        <div className="max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                            <div className="w-[35rem] h-96  mb-4">
                                <label
                                    className="flex justify-between text-gray-700 text-md font-bold mb-2"
                                    htmlFor="product_name"
                                >
                                    Edit HTML:
                                    <X
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are You Sure to Leave?. Your recent changes will not be included."
                                                )
                                            ) {
                                                setHtml({
                                                    ...html,
                                                    htmlPP: false,
                                                });
                                            }
                                        }}
                                    />
                                </label>
                                <label className="flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        rows={16}
                                        value={code}
                                        onChange={(e) =>
                                            setHtml({
                                                ...html,
                                                code: e.target.value,
                                            })
                                        }
                                        placeholder="<p>Code here<p/>"
                                    />
                                </label>
                            </div>
                            <button
                                className="btn w-full text-white px-3 py-2 rounded-[10px] mx-auto"
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    );
};

export default UpdateWes;
