import React, { useRef, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import module, { formatCode } from '../../../controllers/QuillToolbar';
import ReactQuill from 'react-quill';
import API from '../../../API';
import { HexColorPicker } from "react-colorful";
import { Popover } from '@mui/material';
import { PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import Notification from '../../Notification';
import ImageUploader from '../../../controllers/ImageUploader';
const CreateDummySliderGroup = () => {
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const navigate = useNavigate();
    const [status, setstatus] = useState(true);
    const [groupTitle, setGroupTitle] = useState();
    const [image, setimage] = useState();
    const [value, setValue] = useState();
    const [isVisibleButton, setIsVisibleButton] = useState(false);
    const [addSlide, setAddSlide] = useState(false);
    const [slideData, setSlideData] = useState({
        slider_button_color: '#818181',
        slider_button_text: '',
        slider_button_text_color: '#000000',
        slider_button_link: '',
        slider_button_text_hover_color: '#818181',
        slider_button_hover_color: '#000000',
        slider_button_redius: '',
        text_position: 'left'
    })
    const [dataErr, setDataErr] = useState(false);
    const handleSetImage = (value) => {
        setimage(value)
    }
    const [storeSlides, setStoreSlides] = useState([])
    const handleAddSlide = () => {
        if (!image) {
            setDataErr(true)
        } else {
            setDataErr(false)
            if (isVisibleButton) {
                setStoreSlides([...storeSlides, { ...slideData, slider_img: image, slider_text: value }])
            } else {
                setStoreSlides([...storeSlides, { slider_img: image, slider_text: value, text_position: slideData?.text_position }])
            }
            setSlideData({
                slider_button_color: '#818181',
                slider_button_text: '',
                slider_button_text_color: '#000000',
                slider_button_link: '',
                slider_button_hover_color: '#000000',
                slider_button_text_hover_color: '#818181',
                slider_button_redius: '',
                text_position: 'left'
            })
            setIsVisibleButton(false)
            setValue();
            setimage();
            setAddSlide(false)
        }
    }
    const handleAddSliderGroup = async (e) => {
        e.preventDefault();
        if (!groupTitle) {
            setDataErr(true)
        } else {
            setDataErr(false)
            try {
                await API({ url: `/super/admin/dummydata/dynamicdummyslider/addSliderGroup`, method: 'post', data: {
                    slider_title: groupTitle,
                    status: status,
                    sliders: storeSlides
                }})
                navigate('/dummy-data/module-setting/slider-group')
            } catch (error) {
                setIsNotification(true)
                setNotificationMsg(error)
                setSeverity('error')
            }
        }
    }
    const [changeButtonColor, setChangeButtonColor] = useState(false)
    const [changeButtonTextColor, setChangeButtonTextColor] = useState(false)
    const [changeButtonHoverColor, setChangeButtonHoverColor] = useState(false)
    const [changeButtonHoverTextColor, setChangeButtonHoverTextColor] = useState(false)
    const columns = [
        {
            name: 'slider image',
            cell: (row) => <img src={row.slider_img} alt={row.slider_img} className='max-w-[100px] inline-block max-h-[80%]'/>,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'button Text',
            cell: (row) => <span className='line-clamp-2'>{row.slider_button_text}</span>,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Link',
            cell: (row) => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.slider_button_link || '-' }}></span>,
            minWidth: '150px'
        },
        {
            name: 'button color',
            cell: (row) => <span className='line-clamp-2'>{row.slider_button_color}</span>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'button Text color',
            cell: (row) => <span className='line-clamp-2'>{row.slider_button_text_color}</span>,
            sortable: true,
            minWidth: '150px'
        },
    ]

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setValue(value);
    };

    const [html, setHtml] = useState({ htmlPP: false, code: '' });
    const { htmlPP, code } = html
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false })
        setValue(code)
    }

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto pb-[64px]'>
                <div className='w-full'>
                    <Breadcrumbs />
                    <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Add New Slider Group</h3>
                </div>
                <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
                    <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                        <div className='mb-4'>
                            <label htmlFor='image'>
                                Slider Group Title
                            </label>
                            <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''}`} placeholder='Slider group title' value={groupTitle} onChange={(e) => setGroupTitle(e.target.value)} />
                            {
                                dataErr && !groupTitle && <p className='text-xs text-red-500'>Please enter group name</p>
                            }
                        </div>
                        <div className='mb-4 flex items-center'>
                            <label htmlFor='image'>
                                status :
                            </label>
                            <input
                                className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                type='checkbox'
                                role='switch'
                                id='flexSwitchCheckDefault'
                                checked={status === true}
                                name='slider'
                                onChange={() => setstatus(!status)}
                            />
                        </div>
                        <hr className='border-t border-gray-200 my-4'></hr>
                        <button className='text-link underline w-full text-right flex items-center justify-end font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddSlide(true) }}><PlusCircle className="w-4 me-2 h-4" />add slider</button>
                        <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
                            <DataTable
                                columns={columns}
                                data={storeSlides}
                                selectableRowsHighlight
                                highlightOnHover
                                progressComponent={<Loader />}
                            />
                        </div>
                        <hr className='border-t border-gray-200 my-4'></hr>
                        <button className='btn text-sm text-white' onClick={(e) => handleAddSliderGroup(e)}>Add slider group</button>
                    </div>
                </form>
                {
                    addSlide &&
                    <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                        <Popover
                            open={addSlide}
                            className="h-screen popup-content"
                            onClose={() => {
                                setAddSlide(false);
                            }}
                        >
                            <form className='bg-white w-full border border-gray-300  space-y-4 rounded p-5'>
                                <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                                    <h3 className='font-medium text-xl'>Add slider</h3>
                                    <button onClick={(e) => { e.preventDefault(); setAddSlide(false) }}><X className="w-5 h-4" /></button>
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor='image'>
                                        Slider image
                                    </label>
                                    <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="slider" format="image" />
                                </div>
                                <div className='mb-4'>
                                    <div className='flex justify-between items-center'>
                                        <label htmlFor='image'>
                                            slider text
                                        </label>
                                        <section
                                            className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                                            onClick={handleViewHtml}
                                        >
                                            View HTML
                                        </section>
                                    </div>
                                    <ReactQuill
                                        modules={module}
                                        ref={quillRef}
                                        className='w-full h-auto'
                                        theme='snow'
                                        value={value}
                                        onChange={handleQuillChange}
                                    />
                                </div>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 w-full mb-4'>
                                    <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-1 text-sm'>
                                        <label className='text-[13px] w-[120px] font-[500] text-[#484848] capitalize'>text position</label>
                                        <div className='flex w-full text-black items-center text-sm justify-between px-4'>
                                            <span className={`${slideData?.text_position === "left" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setSlideData({ ...slideData, text_position: 'left' })}>Left</span>
                                            <span className={`${slideData?.text_position === "center" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setSlideData({ ...slideData, text_position: 'center' })}>Center</span>
                                            <span className={`${slideData?.text_position === "right" ? "bg-[#f5f5f5] rounded cursor-pointer block text-xs font-medium w-full text-center py-1.5" : 'cursor-pointer block w-full text-center py-1.5 text-xs'}`} onClick={() => setSlideData({ ...slideData, text_position: 'right' })}>Right</span>
                                        </div>
                                    </div>
                                    <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
                                        <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                                            Visible slider button -
                                        </p>
                                        <input
                                            className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                            type='checkbox'
                                            role='switch'
                                            id='flexSwitchCheckDefault'
                                            checked={isVisibleButton === true}
                                            name='sliderbutton'
                                            onChange={() => setIsVisibleButton(!isVisibleButton)}
                                        />
                                    </div>
                                </div>
                                {
                                    isVisibleButton ?
                                        <>
                                            <div className='flex space-x-4 w-full mb-4'>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        slider button text
                                                    </label>
                                                    <input name='slider_button_text' value={slideData?.slider_button_text} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' onChange={(e) => setSlideData({ ...slideData, slider_button_text: e.target.value })} placeholder='e.g. Click here' />
                                                </div>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        slider button link
                                                    </label>
                                                    <input name='slider_button_link' value={slideData?.slider_button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setSlideData({ ...slideData, slider_button_link: e.target.value })} />
                                                </div>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        slider button border radius
                                                    </label>
                                                    <input type='number' name='slider_button_link' value={slideData?.slider_button_redius} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. 4' onChange={(e) => setSlideData({ ...slideData, slider_button_redius: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className='flex space-x-4 w-full mb-4'>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        slider button color
                                                    </label>
                                                    <div className='relative'>
                                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                            <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonColor(!changeButtonColor)} style={{ backgroundColor: slideData?.slider_button_color }}></div>
                                                            <span className='text-sm font-medium text-gray-500'>{slideData?.slider_button_color}</span>
                                                        </div>
                                                        {
                                                            changeButtonColor &&
                                                            <HexColorPicker color={slideData?.slider_button_color} onChange={(e) => setSlideData({ ...slideData, slider_button_color: e })} onBlur={() => setChangeButtonColor(false)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        button text color
                                                    </label>
                                                    <div className='relative'>
                                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                            <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonTextColor(!changeButtonTextColor)} style={{ backgroundColor: slideData?.slider_button_text_color }}></div>
                                                            <span className='text-sm font-medium text-gray-500'>{slideData?.slider_button_text_color}</span>
                                                        </div>
                                                        {
                                                            changeButtonTextColor &&
                                                            <HexColorPicker color={slideData?.slider_button_text_color} onChange={(e) => setSlideData({ ...slideData, slider_button_text_color: e })} onBlur={() => setChangeButtonTextColor(false)} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex space-x-4 w-full mb-4'>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        slider button hover color
                                                    </label>
                                                    <div className='relative'>
                                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                            <div className='w-[30px] h-[30px] rounded cursor-pointer' onClick={() => setChangeButtonHoverColor(!changeButtonHoverColor)} style={{ backgroundColor: slideData?.slider_button_hover_color }}></div>
                                                            <span className='text-sm font-medium text-gray-500'>{slideData?.slider_button_color}</span>
                                                        </div>
                                                        {
                                                            changeButtonHoverColor &&
                                                            <HexColorPicker color={slideData?.slider_button_hover_color} onChange={(e) => setSlideData({ ...slideData, slider_button_hover_color: e })} onBlur={() => setChangeButtonHoverColor(false)} />
                                                        }
                                                    </div>
                                                </div>
                                                <div className='w-full'>
                                                    <label htmlFor='image'>
                                                        button hover text color
                                                    </label>
                                                    <div className='relative'>
                                                        <div className='flex items-center border border-gray-300 p-1 mb-2 rounded space-x-2'>
                                                            <div className='w-[30px] h-[30px] rounded' onClick={() => setChangeButtonHoverTextColor(!changeButtonHoverTextColor)} style={{ backgroundColor: slideData?.slider_button_text_hover_color }}></div>
                                                            <span className='text-sm font-medium text-gray-500'>{slideData?.slider_button_text_hover_color}</span>
                                                        </div>
                                                        {
                                                            changeButtonHoverTextColor &&
                                                            <HexColorPicker color={slideData?.slider_button_text_hover_color} onChange={(e) => setSlideData({ ...slideData, slider_button_text_hover_color: e })} onBlur={() => setChangeButtonHoverTextColor(false)} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </> : <></>
                                }
                                <hr className='border-t border-gray-200 mt-3'></hr>
                                <button onClick={(e) => { e.preventDefault(); handleAddSlide() }} className='btn text-sm text-white'>Add slider</button>
                            </form>
                        </Popover>
                    </div>
                }
                {htmlPP && (
                    <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
                        <Popover
                            open={htmlPP}
                            className='h-screen popup-content'
                        // onClose={() => {
                        //   setHtmlPP(false);
                        // }}
                        >
                            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
                                <div className='w-[35rem] h-96  mb-4'>
                                    <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                                        Edit HTML:
                                        <X onClick={() => {
                                            if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                                                setHtml({ ...html, htmlPP: false })
                                            }
                                        }} />
                                    </label>
                                    <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                                        <textarea
                                            className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                            type='text'
                                            rows={16}
                                            value={code}
                                            onChange={(e) => setHtml({ ...html, code: e.target.value })}
                                            placeholder='<p>Code here<p/>'
                                        />
                                    </label>
                                </div>
                                <button
                                    className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                                    onClick={saveHTML}
                                >
                                    save
                                </button>
                            </div>
                        </Popover>
                    </div>
                )}
            </div>
        </>
    )
}

export default CreateDummySliderGroup