import { Link } from 'react-router-dom';

function Breadcrumbs({ crumbs }) {
  return (
    <>
      {crumbs?.length > 0 &&
        <div className="flex flex-wrap items-center w-full leading-3 text-[12px]">
          {crumbs?.map((crumb, index) => (
            <span key={index} className="inline-flex breadcrumb-item">
              {index !== 0 && <p className='mx-1 leading-3 text-[12px]'> / </p>}
              {index !== crumbs.length - 1 ? (
                <Link to={crumb.path}>
                  {crumb.name}
                </Link>
              ) : (
                crumb.name // Last crumb, no link
              )}
            </span>
          ))}
        </div>
      }
    </>
  );
}

export default Breadcrumbs;