import React from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import { useState, useEffect } from 'react'
import ColorPicker from '../Color/ColorPicker'
import API from '../../../API';
import { Link } from 'react-router-dom'
import { ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents'
const CommonSetting = () => {
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const [settingBody, setSettingBody] = useState({})
    const [openState, setOpenState] = useState('')
    const [commonSetting, setCommonSetting] = useState({
        primary_button_color: '#222222',
        primary_button_text_color: '#ffffff',
        secondary_button_color: "#ff4e73",
        secondary_button_text_color: "#ffffff",
        primary_body_text_color: "#666666",
        primary_body_bg_color: "#ffffff",
        primary_header_text_color: "#111111",
        secondary_header_text_color: "#ff4e73",
        price_color: "#111111",
        compare_price_color: "#eeeeee",
        border_color: "#e5e5e5",
        main_title_color: "#111111",
        product_title_color: "#111111",
        header_bg_color: "#222222",
        footer_bg_color: "#222222",
        primary_footer_title_color: "#111111",
        primary_footer_sub_title_color: "#ff4e73",
        secondary_footer_sub_title_color: '#ffffff',
        secondary_body_text_color: "#666666",
        secondary_body_bg_color: '#ffffff',
        container_width: 1320,
        section_space: 70,
        button_redius: null
    })
    const getSettingData = async () => {
        try {
            const apicall = await API({ url: `/super/admin/dummydata/dynamicdummycommonsettings/getCommonSettings`, method: 'get' });
            setCommonSetting({
                primary_button_color: apicall?.data?.data?.primary_button_color || '#222222',
                primary_button_text_color: apicall?.data?.data?.primary_button_text_color || '#ffffff',
                secondary_button_color: apicall?.data?.data?.secondary_button_color || "#ff4e73",
                secondary_button_text_color: apicall?.data?.data?.secondary_button_text_color || "#ffffff",
                primary_body_text_color: apicall?.data?.data?.primary_body_text_color || "#666666",
                primary_body_bg_color: apicall?.data?.data?.primary_body_bg_color || "#ffffff",
                primary_header_text_color: apicall?.data?.data?.primary_header_text_color || "#111111",
                secondary_header_text_color: apicall?.data?.data?.secondary_header_text_color || "#ff4e73",
                price_color: apicall?.data?.data?.price_color || "#111111",
                compare_price_color: apicall?.data?.data?.compare_price_color || "#eeeeee",
                border_color: apicall?.data?.data?.border_color || "#e5e5e5",
                main_title_color: apicall?.data?.data?.main_title_color || "#111111",
                product_title_color: apicall?.data?.data?.product_title_color || "#111111",
                header_bg_color: apicall?.data?.data?.header_bg_color || "#222222",
                footer_bg_color: apicall?.data?.data?.footer_bg_color || "#222222",
                primary_footer_title_color: apicall?.data?.data?.primary_footer_title_color || "#111111",
                secondary_footer_sub_title_color: apicall?.data?.data?.secondary_footer_sub_title_color || "#ffffff",
                primary_footer_sub_title_color: apicall?.data?.data?.primary_footer_sub_title_color || "#ff4e73",
                secondary_body_text_color: apicall?.data?.data?.secondary_body_text_color || "#000000",
                secondary_body_bg_color: apicall?.data?.data?.secondary_body_bg_color || '#f5f5f5',
                container_width: apicall?.data?.data?.container_width || 1320,
                section_space: apicall?.data?.data?.section_space || 70
            })
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getSettingData()
    }, [])

    const handleChange = (value) => {
        if (openState === "primary_button_color") {
            setSettingBody({ ...settingBody, primary_button_color: value })
            setCommonSetting({ ...commonSetting, primary_button_color: value })
        } else if (openState === "primary_button_text_color") {
            setSettingBody({ ...settingBody, primary_button_text_color: value })
            setCommonSetting({ ...commonSetting, primary_button_text_color: value })
        } else if (openState === "secondary_button_color") {
            setSettingBody({ ...settingBody, secondary_button_color: value })
            setCommonSetting({ ...commonSetting, secondary_button_color: value })
        } else if (openState === "secondary_button_text_color") {
            setSettingBody({ ...settingBody, secondary_button_text_color: value })
            setCommonSetting({ ...commonSetting, secondary_button_text_color: value })
        } else if (openState === "primary_body_text_color") {
            setSettingBody({ ...settingBody, primary_body_text_color: value })
            setCommonSetting({ ...commonSetting, primary_body_text_color: value })
        } else if (openState === "primary_body_bg_color") {
            setSettingBody({ ...settingBody, primary_body_bg_color: value })
            setCommonSetting({ ...commonSetting, primary_body_bg_color: value })
        } else if (openState === "primary_header_text_color") {
            setSettingBody({ ...settingBody, primary_header_text_color: value })
            setCommonSetting({ ...commonSetting, primary_header_text_color: value })
        } else if (openState === "secondary_header_text_color") {
            setSettingBody({ ...settingBody, secondary_header_text_color: value })
            setCommonSetting({ ...commonSetting, secondary_header_text_color: value })
        } else if (openState === "price_color") {
            setSettingBody({ ...settingBody, price_color: value })
            setCommonSetting({ ...commonSetting, price_color: value })
        } else if (openState === "compare_price_color") {
            setSettingBody({ ...settingBody, compare_price_color: value })
            setCommonSetting({ ...commonSetting, compare_price_color: value })
        } else if (openState === "border_color") {
            setSettingBody({ ...settingBody, border_color: value })
            setCommonSetting({ ...commonSetting, border_color: value })
        } else if (openState === "main_title_color") {
            setSettingBody({ ...settingBody, main_title_color: value })
            setCommonSetting({ ...commonSetting, main_title_color: value })
        } else if (openState === "product_title_color") {
            setSettingBody({ ...settingBody, product_title_color: value })
            setCommonSetting({ ...commonSetting, product_title_color: value })
        } else if (openState === "header_bg_color") {
            setSettingBody({ ...settingBody, header_bg_color: value })
            setCommonSetting({ ...commonSetting, header_bg_color: value })
        } else if (openState === "footer_bg_color") {
            setSettingBody({ ...settingBody, footer_bg_color: value })
            setCommonSetting({ ...commonSetting, footer_bg_color: value })
        } else if (openState === "primary_footer_title_color") {
            setSettingBody({ ...settingBody, primary_footer_title_color: value })
            setCommonSetting({ ...commonSetting, primary_footer_title_color: value })
        } else if (openState === "secondary_footer_sub_title_color") {
            setSettingBody({ ...settingBody, secondary_footer_sub_title_color: value })
            setCommonSetting({ ...commonSetting, secondary_footer_sub_title_color: value })
        } else if (openState === "primary_footer_sub_title_color") {
            setSettingBody({ ...settingBody, primary_footer_sub_title_color: value })
            setCommonSetting({ ...commonSetting, primary_footer_sub_title_color: value })
        } else if (openState === "secondary_body_text_color") {
            setSettingBody({ ...settingBody, secondary_body_text_color: value })
            setCommonSetting({ ...commonSetting, secondary_body_text_color: value })
        } else if (openState === "secondary_body_bg_color") {
            setSettingBody({ ...settingBody, secondary_body_bg_color: value })
            setCommonSetting({ ...commonSetting, secondary_body_bg_color: value })
        }
    }
    const handleCloseColor = () => {
        setOpenState('')
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await API({ url: `/super/admin/dummydata/dynamicdummycommonsettings/changeCommonSetting`, method: 'put', data: settingBody})
            setIsNotification(true)
            setNotificationMsg('Common setting updated successfully!')
            setSeverity('success')
            setSettingBody({})
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity('error')
            setSettingBody({})
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto relative overflow-y-auto pb-[130px]'>
                <div className='flex'>

                    <div className='w-full'>
                        <Breadcrumbs />
                        <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Common Setting</h3>
                    </div>

                </div>
                <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
                    <details
                        open
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Button color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    Primary button color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_button_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_button_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_button_color}</span>
                                </div>
                                {
                                    openState === "primary_button_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_button_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary button text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_button_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_button_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_button_text_color}</span>
                                </div>
                                {
                                    openState === "primary_button_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_button_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary button color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_button_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_button_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_button_color}</span>
                                </div>
                                {
                                    openState === "secondary_button_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_button_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary button text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_button_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_button_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_button_text_color}</span>
                                </div>
                                {
                                    openState === "secondary_button_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_button_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full'>
                                <label htmlFor='container_width'>button border radius</label>
                                <input
                                    className='w-full px-3 py-3 border border-gray-300 rounded-[10px] focus:outline-none'
                                    type='number'
                                    id='container_width'
                                    name='container_width'
                                    value={commonSetting.button_redius}
                                    onChange={(e) => { setCommonSetting({ ...commonSetting, button_redius: e.target.value }); e?.target?.value?.length > 0 && setSettingBody({ ...settingBody, button_redius: e.target.value }) }}
                                    placeholder='e.g. 4'
                                />
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Body color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary body background color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_body_bg_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_body_bg_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_body_bg_color}</span>
                                </div>
                                {
                                    openState === "primary_body_bg_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_body_bg_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary body text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_body_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_body_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_body_text_color}</span>
                                </div>
                                {
                                    openState === "primary_body_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_body_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary body background color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_body_bg_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_body_bg_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_body_bg_color}</span>
                                </div>
                                {
                                    openState === "secondary_body_bg_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_body_bg_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary body text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_body_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_body_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_body_text_color}</span>
                                </div>
                                {
                                    openState === "secondary_body_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_body_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    border color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("border_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.border_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.border_color}</span>
                                </div>
                                {
                                    openState === "border_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.border_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        id='headerColor'
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Header color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary header text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_header_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_header_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_header_text_color}</span>
                                </div>
                                {
                                    openState === "primary_header_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_header_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary header text color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_header_text_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_header_text_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_header_text_color}</span>
                                </div>
                                {
                                    openState === "secondary_header_text_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_header_text_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    header background color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("header_bg_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.header_bg_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.header_bg_color}</span>
                                </div>
                                {
                                    openState === "header_bg_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.header_bg_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        id='priceColor'
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Price color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    price color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("price_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.price_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.price_color}</span>
                                </div>
                                {
                                    openState === "price_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.price_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    compare price color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("compare_price_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.compare_price_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.compare_price_color}</span>
                                </div>
                                {
                                    openState === "compare_price_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.compare_price_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        id='titleColor'
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Title color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    main title color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("main_title_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.main_title_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.main_title_color}</span>
                                </div>
                                {
                                    openState === "main_title_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.main_title_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    product title color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("product_title_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.product_title_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.product_title_color}</span>
                                </div>
                                {
                                    openState === "product_title_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.product_title_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        id='footerColor'
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Footer color
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full gap-4'>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    footer background color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("footer_bg_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.footer_bg_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.footer_bg_color}</span>
                                </div>
                                {
                                    openState === "footer_bg_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.footer_bg_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary footer title color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_footer_title_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_footer_title_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_footer_title_color}</span>
                                </div>
                                {
                                    openState === "primary_footer_title_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_footer_title_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    primary footer sub title color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("primary_footer_sub_title_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.primary_footer_sub_title_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.primary_footer_sub_title_color}</span>
                                </div>
                                {
                                    openState === "primary_footer_sub_title_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.primary_footer_sub_title_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                            <div className='w-full relative'>
                                <label htmlFor='image'>
                                    secondary footer sub title color
                                </label>
                                <div className='flex items-center border border-gray-300 p-1.5 mb-2 rounded space-x-2'>
                                    <div onClick={() => setOpenState("secondary_footer_sub_title_color")} className='w-[30px] shadow h-[30px] rounded cursor-pointer' style={{ backgroundColor: commonSetting?.secondary_footer_sub_title_color }}></div>
                                    <span className='text-sm font-medium text-gray-500'>{commonSetting?.secondary_footer_sub_title_color}</span>
                                </div>
                                {
                                    openState === "secondary_footer_sub_title_color" &&
                                    <ColorPicker handleChange={handleChange} setting={commonSetting?.secondary_footer_sub_title_color} handleCloseColor={handleCloseColor} />
                                }
                            </div>
                        </div>
                    </details>
                    <details
                        open
                        id='footerColor'
                        className='bg-white group shadow  space-y-4 rounded-xl p-[25px]'
                    >
                        <summary className='text-base flex items-center justify-between font-medium text-black'>
                            Container & Spacing
                            <span className='transition group-open:rotate-180'>
                                <ChevronDown className='text-black w-4 h-5' />
                            </span>
                        </summary>
                        <hr className='my-4 border-t border-gray-300'></hr>
                        <div className='grid grid-cols-1  xl:grid-cols-2 w-full gap-4'>
                            <div className='max-w-full mb-4'>
                                <label htmlFor='container_width'>Container Width </label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                    type='number'
                                    id='container_width'
                                    name='container_width'
                                    value={commonSetting.container_width}
                                    onChange={(e) => { setCommonSetting({ ...commonSetting, container_width: e.target.value }); setSettingBody({ ...settingBody, container_width: e.target.value }) }}
                                    placeholder='Container width..'
                                />
                            </div>
                            <div className='max-w-full mb-4'>
                                <label htmlFor='section_space'>Section Space </label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                                    type='number'
                                    id='section_space'
                                    name='section_space'
                                    value={commonSetting.section_space}
                                    onChange={(e) => { setCommonSetting({ ...commonSetting, section_space: e.target.value }); setSettingBody({ ...settingBody, section_space: e.target.value }) }}
                                    placeholder='Section space..'
                                />
                            </div>
                        </div>
                    </details>

                    <div className='w-[90%] flex items-center justify-between lg:w-[90%] xl:w-[90%] mx-auto'>
                        <Link
                            to={`/dummy-data/module-setting`}
                            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                        >
                            <ArrowLeft className='w-3 mr-1' />
                            Go to Products
                        </Link>
                        <button className='btn text-white text-sm' onClick={(e) => handleSubmit(e)}>Apply Changes</button>
                    </div>

                </form>
            </div>
        </>
    )
}

export default CommonSetting