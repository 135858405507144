import "./App.css";
import Login from "./Components/AuthUser/Login";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Tenants from "./Components/Tenant/Tenants";
import AddTenant from "./Components/Tenant/AddTenant";
import ViewTenant from "./Components/Tenant/ViewTenant";
import CreateAnnouncement from "./Components/Announcements/CreateAnnouncement";
import Sidebar from "./Components/Main/Sidebar";
import DashBoard from "./Components/Main/DashBoard";
import MyAnnouncements from "./Components/Announcements/MyAnnouncements";
import UpdateAnnouncement from "./Components/Announcements/UpdateAnnouncement";
import ViewAnnouncement from "./Components/Announcements/ViewAnnouncement";
import Chat from "./Components/Socket/Chat";
import SubscriptionPlan from "./Components/Subscriptions/SubscriptionPlans";
import AddSubscriptionPlan from "./Components/Subscriptions/AddSubscriptionPlan";
import Subscriptions from "./Components/Subscriptions/Subscriptions";
import ViewSubscription from "./Components/Subscriptions/ViewSubscription";
import Payments from "./Components/Payments/Payments";
import { useEffect, useState } from "react";
import UpdateSubscription from "./Components/Subscriptions/UpdateSubscription";
import AllStatus from "./Components/Status/AllStatus";
import AddStatus from "./Components/Status/AddStatus";
import UpdateStatus from "./Components/Status/UpdateStatus";
import MyGuide from "./Components/Guide/MyGuide";
import AddGuide from "./Components/Guide/AddGuide";
import UpdateGuide from "./Components/Guide/UpdateGuide";
import ViewGuide from "./Components/Guide/ViewGuide";
import Changelogcategories from "./Components/Changelogs/Changelogcategories";
import Changelog from "./Components/Changelogs/Changelog";
import AddChangelog from "./Components/Changelogs/AddChangelog";
import UpdateChangeLog from "./Components/Changelogs/UpdateChangeLog";
import RestrictedDomains from "./Components/RestrictedDomains/RestrictedDomains";
import MyGuideCategory from "./Components/GuideCategory/MyGuideCategory";
import AddGuideCategory from "./Components/GuideCategory/AddGuideCategory";
import UpdateGuideCategory from "./Components/GuideCategory/UpdateGuideCategory";
import AllStores from "./Components/Stores/AllStores";
import PerticularStore from "./Components/Stores/PerticularStore";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Components/Main/Navbar";
import CreateDummyCategory from "./Components/Dummy/DummyCategory/CreateDummyCategory"
import StoreCategory from "./Components/Stores/StoreCategory";
import UpdateDummyCategory from "./Components/Dummy/DummyCategory/UpdateDummyCategory";
import CreateDummyBlog from "./Components/Dummy/DummyBlog/CreateDummyBlog";
import AddDummyBlogCategories from "./Components/Dummy/DummyBlog/AddDummyBlogCategories";
import MyDummyBlogs from "./Components/Dummy/DummyBlog/MyDummyBlog";
import MyDummyBlogCategories from "./Components/Dummy/DummyBlog/MyDummyBlogCategories";
import DummyCategoryTable from "./Components/Dummy/DummyCategory/DummyCategoryTable";
import UpdateDummyBlog from "./Components/Dummy/DummyBlog/UpdateDummyBlog";
import MyDummyPortfolioCategories from "./Components/Dummy/DummyPortfolio.js/MyDummyPortfolioCategories";
import AddDummyPortfolioCategories from "./Components/Dummy/DummyPortfolio.js/AddDummyPortfolioCategories";
import UpdateDummyBlogCategories from "./Components/Dummy/DummyBlog/UpdateDummyBlogCategories";
import MyDummyPortfolio from "./Components/Dummy/DummyPortfolio.js/MyDummyPortfolio";
import CreateDummyPortfolio from "./Components/Dummy/DummyPortfolio.js/CreateDummyPortfolio";
import UpdateDummyPortfolio from "./Components/Dummy/DummyPortfolio.js/UpdateDummyPortfolio";
import DummyManufacturerTable from "./Components/Dummy/DummyManufacturer/DummyManufacturerTable";
import CreateDummyManufacturer from "./Components/Dummy/DummyManufacturer/CreateDummyManufacturer";
import UpdateDummyManufacturer from "./Components/Dummy/DummyManufacturer/UpdateDummyManufacturer";
import CreateDummyPages from "./Components/Dummy/DummyPages/CreateDummyPages";
import DummyPagesTable from "./Components/Dummy/DummyPages/DummyPagesTable";
import UpdateDummyPages from "./Components/Dummy/DummyPages/UpdateDummyPages";
import DummyVariantTable from "./Components/Dummy/DummyGroupVariants/DummyVariantTable";
import CreateDummyVariant from "./Components/Dummy/DummyGroupVariants/CreateDummyVariant";
import UpdateDummyVariant from "./Components/Dummy/DummyGroupVariants/UpdateDummyVariant";
import DummyCustomeTabTable from "./Components/Dummy/DummyCustomeTab/DummyCustomeTabTable";
import CreateDummyCustomeTab from "./Components/Dummy/DummyCustomeTab/CreateDummyCustomeTab";
import UpdateDummyCustomeTab from "./Components/Dummy/DummyCustomeTab/UpdateDummyCustomeTab";
import ModuleSetting from "./Components/Dummy/Setting/ModuleSetting"
import DummySliderGroupTable from "./Components/Dummy/DummySlider/DummySliderGroupTable";
import CreateDummySliderGroup from "./Components/Dummy/DummySlider/CreateDummySliderGroup";
import UpdateDummySliderGroup from "./Components/Dummy/DummySlider/UpdateDummySliderGroup";
import DummyBannerGroupTable from "./Components/Dummy/DummyBanner/DummyBannerGroupTable";
import CreateDummyBannerGroup from "./Components/Dummy/DummyBanner/CreateDummyBannerGroup";
import UpdateDummyBannerGroup from "./Components/Dummy/DummyBanner/UpdateDummyBannerGroup";
import MyDummyBlogGroup from "./Components/Dummy/DummyBlog/MyDummyBlogGroup";
import CreateDummyBlogGroup from "./Components/Dummy/DummyBlog/CreateDummyBlogGroup";
import UpdateDummyBlogGroup from "./Components/Dummy/DummyBlog/UpdateDummyBlogGroup";
import DummyCategoryGroupTable from "./Components/Dummy/DummyCategory/DummyCategoryGroupTable";
import CreateDummyCategoryGroup from "./Components/Dummy/DummyCategory/CreateDummyCategoryGroup";
import UpdateDummyCategoryGroup from "./Components/Dummy/DummyCategory/UpdateDummyCategoryGroup";
import DummyProductTable from "./Components/Dummy/DummyProducts/DummyProductTable";
import CreateDummyProduct from "./Components/Dummy/DummyProducts/CreateDummyProduct";
import UpdateDummyProduct from "./Components/Dummy/DummyProducts/UpdateDummyProduct";
import DummyManufacturerGroupTable from "./Components/Dummy/DummyManufacturer/DummyManufacturerGroupTable";
import CreateDummyManufacturerGroup from "./Components/Dummy/DummyManufacturer/CreateDummyManufacturerGroup";
import UpdateDummyManufacturerGroup from "./Components/Dummy/DummyManufacturer/UpdateDummyManufacturerGroup";
import DummyReassuranceGroupTable from "./Components/Dummy/DummyReassurance/DummyReassuranceGroupTable";
import CreateDummyReassuranceGroup from "./Components/Dummy/DummyReassurance/CreateDummyReassuranceGroup";
import UpdateDummyReassuranceGroup from "./Components/Dummy/DummyReassurance/UpdateDummyReassuranceGroup";
import DummyCmsOffer from "./Components/Dummy/DummyCmsOffer/DummyCmsOffer";
import CreateCmsOffer from "./Components/Dummy/DummyCmsOffer/CreateCmsOffer";
import UpdateCmsOffer from "./Components/Dummy/DummyCmsOffer/UpdateCmsOffer";
import CommonSetting from "./Components/Dummy/Setting/CommonSetting";
import DummyProductGroupTable from "./Components/Dummy/DummyProducts/DummyProductGroupTable";
import CreateDummyProductGroup from "./Components/Dummy/DummyProducts/CreateDummyProductGroup";
import UpdateDummyProductGroup from "./Components/Dummy/DummyProducts/UpdateDummyProductGroup";
import UpdateDummyPortfolioCategories from "./Components/Dummy/DummyPortfolio.js/UpdateDummyPortfolioCategories";
import DummyDataCount from "./Components/Dummy/DummyData/DummyDataCount";
import CreateDummyLayout from "./Components/Dummy/DummyLayout/CreateDummyLayout";
import DummyLayoutTable from "./Components/Dummy/DummyLayout/DummyLayoutTable";
import DummyHeaderGroup from "./Components/Dummy/DummyHeader/DummyHeaderGroup";
import CreateDummyHeaderGroup from "./Components/Dummy/DummyHeader/CreateDummyHeaderGroup";
import UpdateDummyHeaderGroup from "./Components/Dummy/DummyHeader/UpdateDummyHeaderGroup";
import DummyFooterGroupTable from "./Components/Dummy/DummyFooter/DummyFooterGroupTable";
import CreateDummyFooterGroup from "./Components/Dummy/DummyFooter/CreateDummyFooterGroup";
import UpdateDummyFooterGroup from "./Components/Dummy/DummyFooter/UpdateDummyFooterGroup";
import AllCompany from "./Components/Company/AllCompany";
import AddCompany from "./Components/Company/AddCompany";
import EditCompany from "./Components/Company/EditCompany";
import ImportCompany from "./Components/Company/ImportCompany";
import AddPlugin from "./Components/Modules/AddPlugin";
import GetPlugin from "./Components/Modules/GetPlugin";
import UpdatePlugin from "./Components/Modules/UpdatePlugin";
import { FAVICON_IMAGE } from "./Assets/StaticData/StaticImage";
import WebsiteDataCount from "./Components/Website/WebsiteData/WebsiteDataCount";
import MyWebsiteBlogs from "./Components/Website/WebsiteBlog/MyWebsiteBlog";
import CreateWebsiteBlog from "./Components/Website/WebsiteBlog/CreateWebsiteBlog";
import MyWebsiteBlogCategories from "./Components/Website/WebsiteBlog/MyWebsiteBlogCategories";
import AddWebsiteBlogCategories from "./Components/Website/WebsiteBlog/AddWebsiteBlogCategories";
import UpdateWebsiteBlogCategories from "./Components/Website/WebsiteBlog/UpdateWebsiteBlogCategories";
import UpdateWebsiteBlog from "./Components/Website/WebsiteBlog/UpdateWebsiteBlog";
import WebsiteComment from "./Components/Website/WebsiteBlog/WebsiteBlogComment";
import WebsitJobs from "./Components/Website/WebsiteBlog/WebsitJobs";
import AddWebsitJobs from "./Components/Website/WebsiteBlog/AddWebsitJobs";
import UpdateWebsiteJob from "./Components/Website/WebsiteBlog/UpdateWebsiteJob";
import WebsiteTool from "./Components/Website/WebsiteBlog/WebsiteTool";
import AddWebsiteTool from "./Components/Website/WebsiteBlog/AddWebsiteTool";
import UpdateWebsiteTool from "./Components/Website/WebsiteBlog/UpdateWebsiteTool";
import WebsiteApps from "./Components/Website/WebsiteBlog/WebsiteApps";
import AddWebsiteApps from "./Components/Website/WebsiteBlog/AddWebsiteApps";
import UpdateWebsiteApps from "./Components/Website/WebsiteBlog/UpdateWebsiteApps";
import AllAppReview from "./Components/Website/WebsiteAppReview/AllAppReview";
import ParticularAppReview from "./Components/Website/WebsiteAppReview/ParticularAppReview";
import Themes from "./Components/Website/WebsiteTheme/Themes";
import AddWebsiteTheme from "./Components/Website/WebsiteTheme/AddWebsiteTheme";
import EditWebsiteTheme from "./Components/Website/WebsiteTheme/EditWebsiteTheme";
import Domains from "./Components/Main/Domains";
import ErrorLogs from "./Components/ErrorLogs/ErrorLogs";
import RestrictedIP from "./Components/RestrictedDomains/RestrictedIP";
import AddDynamicPage from "./Components/Website/DynamicPages/AddDynamicPage";
import EditDynamicPage from "./Components/Website/DynamicPages/EditDynamicPage";
import ViewDynamicPages from "./Components/Website/DynamicPages/ViewDynamicPages";
import NetworkStatus from "./controllers/NetworkStatus";
function getFaviconEl() {
  return document.getElementById("favicon");
}
function App() {
  const token = localStorage.getItem("token")
  const [openTab, setOpenTab] = useState(1)
  const getTab = (tab) => {
    setOpenTab(tab)
  }
  const [opendummyTab, setOpenDummyTab] = useState(1)

  const getDummyTab = (tabs) => {
    setOpenDummyTab(tabs)
  }
  const handleFavicon = () => {
    const favicon = getFaviconEl();
    favicon.href = `${FAVICON_IMAGE}`;
  };
  handleFavicon()
  const [url, seturl] = useState()
  useEffect(() => {
    seturl(window.location.href.split('/').pop())
  }, [window.location.href])
  return (
    <>
      <div className="bg-[#f5f5f5]">
          <BrowserRouter>
            {/* <Navbar/> */}
            <div className={`flex w-screen h-screen ${url?.length > 0 && token ? `py-2 pe-2` : ``} bg-[#0c2639] overflow-hidden`}>
              {token && url !== '' ? <Sidebar getTab={getTab} getDummyTab={getDummyTab} /> : <></>}
              {/* {token ? <Sidebar getDummyTab={getDummyTab}/> : <></>} */}
              <div className={`w-full h-full overflow-hidden ${url?.length > 0 && token ? `rounded-xl` : ``} relative z-40 transition-all duration-500`}>
                {token && url !== '' ? <Navbar /> : <></>}
                <div className={`w-full h-full ${url?.length > 0 ? `p-4 rounded-b-xl` : `p-0`}  bg-[#f5f5f5] overflow-y-auto relative z-40 transition-all duration-500`}>
                  <NetworkStatus />
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<DashBoard />} />
                    {/* Tenants */}
                    <Route path="/tenants" element={token ? <Tenants /> : <Login />} />
                    <Route path="/tenants/addtenant" element={token ? <AddTenant /> : <Login />} />
                    <Route path="/tenants/:tenant_id" element={token ? <ViewTenant /> : <Login />} />
                    <Route path="/domains" element={token ? <Domains /> : <Login />} />

                    {/* Annnoucements */}
                    <Route path="/announcement" element={token ? <MyAnnouncements /> : <Login />} />
                    <Route path="/announcement/createannouncement" element={token ? <CreateAnnouncement /> : <Login />} />
                    <Route path="/announcement/:annauncement_id" element={token ? <ViewAnnouncement /> : <Login />} />
                    <Route path="/announcement/updateannouncement" element={token ? <UpdateAnnouncement /> : <Login />} />


                    {/* Guide */}
                    <Route path="/guide" element={token ? <MyGuide /> : <Login />} />
                    <Route path="/guide/addguide" element={token ? <AddGuide /> : <Login />} />
                    <Route path="/guide/updateguide/:guide_id" element={token ? <UpdateGuide /> : <Login />} />
                    <Route path="/guide/:guide_id" element={token ? <ViewGuide /> : <Login />} />

                    {/* Guide Categories */}
                    <Route path="/guide-categories" element={<MyGuideCategory />} />
                    <Route path="/guide-categories/add-guide-categories" element={<AddGuideCategory />} />
                    <Route path="/guide-categories/update-categories/:guide_cat_id" element={<UpdateGuideCategory />} />


                    {/* Modules */}
                    <Route path="/apps" element={token ? <GetPlugin /> : <Login />} />
                    <Route path="/apps/add-app" element={token ? <AddPlugin /> : <Login />} />
                    <Route path="/apps/:appId" element={token ? <UpdatePlugin /> : <Login />}
                    />

                    {/* company  */}
                    <Route path="/company" element={token ? <AllCompany /> : <Login />} />
                    <Route path="/company/add-company" element={token ? <AddCompany /> : <Login />} />
                    <Route path="/company/:companyId" element={token ? <EditCompany /> : <Login />} />
                    <Route path="/company/import-company-info" element={token ? <ImportCompany /> : <Login />} />

                    {/* Status */}
                    <Route path="/all-status" element={token ? <AllStatus /> : <Login />} />
                    {/* <Route path="/all-status/add-status" element={<AddStatus />} />
                  <Route path="/all-status/update-status/:status_id" element={<UpdateStatus />} /> */}

                    {/* Restricted Sub Domains */}
                    <Route path="/restricted-domains" element={token ? <RestrictedDomains /> : <Login />} />
                    <Route path="/restricted-ip" element={token ? <RestrictedIP /> : <Login />} />

                    {/* Subscriptions */}
                    <Route path="/subscription-plans" element={token ? <SubscriptionPlan /> : <Login />} />
                    <Route path="/subscription-plans/addsubscriptionplan" element={token ? <AddSubscriptionPlan /> : <Login />} />
                    <Route path="/subscriptions" element={token ? <Subscriptions /> : <Login />} />
                    <Route path="/subscriptions/viewsubscription" element={token ? <ViewSubscription /> : <Login />} />
                    <Route path="/mysubscriptions/updatesubscription" element={token ? <UpdateSubscription /> : <Login />} />

                    {/*Changelog categories  */}
                    <Route path="/changelog" element={token ? <Changelog /> : <Login />} />
                    <Route path="/changelog/add-changelog" element={token ? <AddChangelog /> : <Login />} />
                    <Route path="/changelog/update-changelog/:logID" element={token ? <UpdateChangeLog /> : <Login />} />
                    <Route path="/changelog/changelog-categories" element={token ? <Changelogcategories /> : <Login />} />

                    {/* Payments */}
                    <Route path="/payments" element={token ? <Payments /> : <Login />} />

                    {/* Dummy */}
                    <Route path="/dummy-data" element={token ? <DummyDataCount /> : <Login />} />
                    <Route path="/dummy-data/dummy-category" element={token ? <DummyCategoryTable /> : <Login />} />


                    <Route path="/dummy-data/dummy-category/add-dummy-categories" element={token ? <CreateDummyCategory /> : <Login />} />
                    <Route path="/dummy-data/dummy-category/:category_slug_name" element={token ? <UpdateDummyCategory /> : <Login />} />

                    <Route path="/dummy-data/dummy-product" element={token ? <DummyProductTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-product/add-dummy-product" element={token ? <CreateDummyProduct /> : <Login />} />
                    <Route path="/dummy-data/dummy-product/:slugname" element={token ? <UpdateDummyProduct /> : <Login />} />
                    <Route path="/dummy-data/module-setting/product-group" element={token ? <DummyProductGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/product-group/add-product-group" element={token ? <CreateDummyProductGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/product-group/:groupId" element={token ? <UpdateDummyProductGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/category-group" element={token ? <DummyCategoryGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/category-group/add-category-group" element={token ? <CreateDummyCategoryGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/category-group/:groupId" element={token ? <UpdateDummyCategoryGroup /> : <Login />} />

                    <Route path="/dummy-data/dummy-blog" element={token ? <MyDummyBlogs /> : <Login />} />
                    <Route path="/dummy-data/dummy-blog/add-dummy-blog" element={token ? <CreateDummyBlog /> : <Login />} />
                    <Route path="/dummy-data/dummy-blog/:blog_slug_name" element={token ? <UpdateDummyBlog /> : <Login />} />

                    <Route path="/dummy-data/dummy-blog-category" element={token ? <MyDummyBlogCategories /> : <Login />} />
                    <Route path="/dummy-data/dummy-blog-category/add-dummy-blog-categories" element={token ? <AddDummyBlogCategories /> : <Login />} />
                    <Route path="/dummy-data/dummy-blog-category/:dummy_blog_categories_slug_name" element={token ? <UpdateDummyBlogCategories /> : <token />} />

                    <Route path="/dummy-data/module-setting/blog-group" element={token ? <MyDummyBlogGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/blog-group/add-blog-group" element={token ? <CreateDummyBlogGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/blog-group/:groupId" element={token ? <UpdateDummyBlogGroup /> : <Login />} />

                    <Route path="/dummy-data/dummy-portfolio-category" element={token ? <MyDummyPortfolioCategories /> : <Login />} />
                    <Route path="/dummy-data/dummy-portfolio-category/add-dummy-portfolio-categories" element={token ? <AddDummyPortfolioCategories /> : <Login />} />
                    <Route path="/dummy-data/dummy-portfolio-category/:dummy_portfolio_categories_slug_name" element={token ? <UpdateDummyPortfolioCategories /> : <Login />} />

                    <Route path="/dummy-data/dummy-portfolio" element={token ? <MyDummyPortfolio /> : <Login />} />
                    <Route path="/dummy-data/dummy-portfolio/add-dummy-portfolio" element={token ? <CreateDummyPortfolio /> : <Login />} />
                    <Route path="/dummy-data/dummy-portfolio/:portfolio_slug_name" element={token ? <UpdateDummyPortfolio /> : <Login />} />

                    <Route path="/dummy-data/dummy-Manufacturer" element={token ? <DummyManufacturerTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-Manufacturer/add-dummy-Manufacturer" element={token ? <CreateDummyManufacturer /> : <Login />} />
                    <Route path="/dummy-data/dummy-Manufacturer/update-dummy-Manufacturer/:brand_slug_name" element={token ? <UpdateDummyManufacturer /> : <Login />} />

                    <Route path="/dummy-data/module-setting/manufacture-group" element={token ? <DummyManufacturerGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/manufacture-group/add-manufacture-group" element={token ? <CreateDummyManufacturerGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/manufacture-group/:groupId" element={token ? <UpdateDummyManufacturerGroup /> : <Login />} />

                    <Route path="/dummy-data/dummy-pages" element={token ? <DummyPagesTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-pages/add-dummy-pages" element={token ? <CreateDummyPages /> : <Login />} />
                    <Route path="/dummy-data/dummy-pages/update-dummy-pages/:dummy_pages_slug_name" element={token ? <UpdateDummyPages /> : <Login />} />

                    <Route path="/dummy-data/dummy-group-variants" element={token ? <DummyVariantTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-group-variants/add-dummy-group-variants" element={token ? <CreateDummyVariant /> : <Login />} />
                    <Route path="/dummy-data/dummy-group-variants/update-dummy-group-variants/:variantsid" element={token ? <UpdateDummyVariant /> : <Login />} />

                    <Route path="/dummy-data/dummy-custometab" element={token ? <DummyCustomeTabTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-custometab/add-dummy-custometab" element={token ? <CreateDummyCustomeTab /> : <Login />} />
                    <Route path="/dummy-data/dummy-custometab/:tabid" element={token ? <UpdateDummyCustomeTab /> : <Login />} />

                    <Route path="/dummy-data/dummy-layout" element={token ? <DummyLayoutTable /> : <Login />} />
                    <Route path="/dummy-data/dummy-layout/:layoutName" element={token ? <CreateDummyLayout /> : <Login />} />

                    <Route path="/dummy-data/module-setting" element={token ? <ModuleSetting /> : <Login />} />

                    <Route path="/dummy-data/module-setting/header-menu" element={token ? <DummyHeaderGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/add-header-section" element={token ? <CreateDummyHeaderGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/header-menu/:groupId" element={token ? <UpdateDummyHeaderGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/footer" element={token ? <DummyFooterGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/footer/add-footer-section" element={token ? <CreateDummyFooterGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/footer/:groupId" element={token ? <UpdateDummyFooterGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/slider-group" element={token ? <DummySliderGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/slider-group/add-slider-group" element={token ? <CreateDummySliderGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/slider-group/:groupId" element={token ? <UpdateDummySliderGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/banner-group" element={token ? <DummyBannerGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/banner-group/add-banner-group" element={token ? <CreateDummyBannerGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/banner-group/:groupId" element={token ? <UpdateDummyBannerGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/reassurance-group" element={token ? <DummyReassuranceGroupTable /> : <Login />} />
                    <Route path="/dummy-data/module-setting/reassurance-group/add-reassurance-group" element={token ? <CreateDummyReassuranceGroup /> : <Login />} />
                    <Route path="/dummy-data/module-setting/reassurance-group/:groupId" element={token ? <UpdateDummyReassuranceGroup /> : <Login />} />

                    <Route path="/dummy-data/module-setting/cms-offer" element={token ? <DummyCmsOffer /> : <Login />} />
                    <Route path="/dummy-data/module-setting/cms-offer/add-cms-offer" element={token ? <CreateCmsOffer /> : <Login />} />
                    <Route path="/dummy-data/module-setting/cms-offer/:groupId" element={token ? <UpdateCmsOffer /> : <Login />} />

                    <Route path="/dummy-data/module-setting/common-setting" element={token ? <CommonSetting /> : <Login />} />

                    {/* Stores */}
                    <Route path="/all-stores" element={token ? <AllStores /> : <Login />} />
                    <Route path="/all-stores/:tenant_id" element={token ? <PerticularStore openTab={openTab} /> : <Login />} />
                    <Route path="/store-category" element={token ? <StoreCategory /> : <Login />} />

                    {/* website */}
                    <Route path="/website-data" element={token ? <WebsiteDataCount /> : <Login />} />

                    <Route path="/site-data/blog" element={token ? <MyWebsiteBlogs /> : <Login />} />
                    <Route path="/site-data/blog/add-website-blog" element={token ? <CreateWebsiteBlog /> : <Login />} />
                    <Route path="/site-data/blog/:blog_slug_name" element={token ? <UpdateWebsiteBlog /> : <Login />} />

                    {/* website blog categories */}
                    <Route path="/site-data/blog-category" element={token ? <MyWebsiteBlogCategories /> : <Login />} />
                    <Route path="/site-data/blog-category/add-website-blog-categories" element={token ? <AddWebsiteBlogCategories /> : <Login />} />
                    <Route path="/site-data/blog-category/:website_blog_categories_slug_name" element={token ? <UpdateWebsiteBlogCategories /> : <token />} />

                    {/* website jobs */}
                    <Route path="/site-data/jobs" element={token ? <WebsitJobs /> : <Login />} />
                    <Route path="/site-data/jobs/add-website-jobs" element={token ? <AddWebsitJobs /> : <Login />} />
                    <Route path="/site-data/jobs/:job_slug_name" element={token ? <UpdateWebsiteJob /> : <token />} />

                    <Route path="/site-data/tool" element={token ? <WebsiteTool /> : <Login />} />
                    <Route path="/site-data/tool/add-website-tool" element={token ? <AddWebsiteTool /> : <Login />} />
                    <Route path="/site-data/tool/:tool_slug_name" element={token ? <UpdateWebsiteTool /> : <Login />} />

                    {/* website dynamic page */}
                    <Route path="/site-data/page/add-page" element={token ? <AddDynamicPage /> : <Login />} />
                    <Route path="/site-data/page/update-page/:id" element={token ? <EditDynamicPage /> : <Login />} />
                    <Route path="/site-data/page" element={token ? <ViewDynamicPages /> : <Login />} />

                    {/* <Route path="/site-data/apps" element={token ? <WebsiteApps /> : <Login />} />
                    <Route path="/site-data/apps/add-website-app" element={token ? <AddWebsiteApps /> : <Login />} />
                    <Route path="/site-data/apps/:app_slug_name" element={token ? <UpdateWebsiteApps /> : <Login />} /> */}

                    {/* website blog comments */}
                    <Route path="/site-data/blog-comments" element={token ? <WebsiteComment /> : <Login />} />

                    {/* Socket */}
                    <Route path="/chat" element={token ? <Chat /> : <Login />} />

                    {/* Review */}
                    <Route path="/apps/appreviews" element={token ? <AllAppReview /> : <Login />} />
                    <Route path="/apps/appreview/:app_review_slug_name" element={token ? <ParticularAppReview /> : <Login />} />

                    {/* Theme */}
                    <Route path="/site-data/themes" element={token ? <Themes /> : <Login />} />
                    <Route path="/site-data/themes/addThemes" element={token ? <AddWebsiteTheme /> : <Login />} />
                    <Route path="/site-data/themes/:theme_slug_name" element={token ? <EditWebsiteTheme /> : <Login />} />

                    {/* Error Log */}
                    <Route path="/error-logs" element={token ? <ErrorLogs /> : <Login />} />
                  </Routes>
                </div>
              </div>
            </div>
          </BrowserRouter>
      </div>
    </>
  );
}

export default App;
