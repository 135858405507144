import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AddTenants } from "../../Redux/Action/tenant.action";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import API from "../../API";

const AddTenant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [err, seterr] = useState(false);
  const [success, setsuccess] = useState(false);
  let [storeName, setStoreName] = useState('');
  const [RegData, setRegData] = useState({
    email: "",
    // password: "",
    business_name: "",
    business_category: "",
    // addressLine1: '',
    // addressLine2: '',
    // city: '',
    // state: '',
    // pincode: '',
  });
  const {
    email,
    // password,
    business_name,
    business_category,
    // addressLine1,
    // addressLine2,
    // city,
    // state,
    // pincode,
  } = RegData;

  //Show And Hide Password
  const [message, setmessage] = useState("");
  // get All Entered  Input Data In our UseState

  const HandleInput = (e) => {
    setRegData({ ...RegData, [e.target.name]: e.target.value });
  };

  // Api Call for Register
  // const HandleRegister = async () => {
  //   // try {
  //      await fetch(`${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/addTenant`,{  
  //         method:"POST",
  //        headers: {
  //           "content-type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           email,
  //           business_category,
  //           store_id: storeName,
  //           business_name,
  //         })
  //       }).then((res)=>{
  //       if(res.status===400||res.status===500){
  //         toast.error("Something Went Wrong !  Check The Data You Entered ")
  //       }
  //       else{
  //         navigate("/tenants")
  //       }

  //     });   
  // };

  const HandleRegister = async (e) => {
    e.preventDefault();
    dispatch(AddTenants(email, business_category,storeName,business_name)).then((res)=>{
      if(res.status === 400 || res.status === 500){
        toast.error("Something Went Wrong ! Check The Data You Entered")
      }
      else{
        toast.success("Tenant Added Successfully")

        setTimeout(() => {
          navigate("/tenants")
        }, 2000);
      }
    })
    
    
};


  const Handleblur = () => {
    let store;
    if (!storeName) {
      store = business_name;
    } else {
      store = storeName;
    }
    const uniqueStore = store.toLowerCase().replaceAll(" ", "_");
    setStoreName(uniqueStore);
  };

  const storeapidata = async () => {
    try {
      if (storeName) {
        const storeApiData = await API({ url: `/super/admin/tenant/storeIdVarify/${storeName}`, method:"get" });
        if (storeApiData.status === 403) {
          seterr(true);
          setsuccess(false);
          setIsVarified(false);
        } else if (storeApiData.status === 200 || storeApiData.status === 304) {
          setsuccess(true);
          seterr(false);
          setIsVarified(true);
        } else {
          toast.error('something went Wrong!', { position: 'top-right' });
          console.log('error');
        }
      }
    } catch (error) {
      console.log(error);
      toast.error('something went wrong!', {
        position: 'top-right',
      });
      console.log('error');
    }
  };
  //captcha
  const [isVarified, setIsVarified] = useState(false);
  // const RecaptchaLoaded = () => {
  // };
  return (
    <>
      <div className="flex h-screen  pb-[64px] items-center justify-center  py-8 sm:px-6 ">
        <div className="w-full sm:w-8/12 xl:w-1/3 mx-auto my-10 bg-gray-200 p-5 rounded-xl ">
          <div>
            {/* <img className="mx-auto h-8 w-auto" src={logo} alt="Your Company" /> */}
            <h2 className="mt-4 text-center text-xl font-bold tracking-tight text-gray-900">
              Add New Tenant
            </h2>
            <h1 className="text-xs mt-5 mb-2 text-red-600">
              Fields That Marked * are Compulsary
            </h1>
          </div>
          <div>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md  space-y-4 shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={HandleInput}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none"
                  placeholder="email address*"
                />
              </div>
              {/* <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={eyeopen ? "text" : "password"}
                    value={password}
                    onChange={HandleInput}
                    required
                    className="relative block w-full rounded-b-md border-0 py-2.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10  focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                    placeholder="Password *"
                  />
                  {!eyeopen ? (
                    <Eye
                      className="absolute top-2 right-2 w-4 h-4 text-slate-400 z-10"
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className="absolute top-2 right-2 w-4 h-4 text-slate-400 z-10"
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  )}
                </div>
              </div> */}
              <div>
                <label htmlFor="Businessname" className="sr-only">
                  BusinessName
                </label>
                <input
                  id="BusinessName"
                  name="business_name"
                  type="Text"
                  value={business_name}
                  onBlur={Handleblur}
                  onChange={HandleInput}
                  required
                  className="relative block w-full rounded-b-md border-0 pl-4 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10  focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                  placeholder="BusinessName*"
                />
              </div>
              <div>
                <label htmlFor="Store Id" className="sr-only">
                  Store Id
                </label>
                <div className="relative">
                  <input
                    id="Store Id"
                    name="storeName"
                    type="text"
                    value={storeName}
                    onChange={(e) =>
                      setStoreName(
                        e.target.value.toLowerCase().replaceAll(" ", "_")
                      )
                    }
                    required
                    className="relative block w-full rounded-b-md border-0 pl-4 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10  focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                    placeholder="Store Id"
                    verifyCallback={(response) =>
                      response ? setIsVarified(true) : setIsVarified(false)
                    }
                    onFocus={() =>
                      setmessage(
                        <span className="text-red-500">
                          please click on verify
                        </span>
                      )
                    }
                    onBlur={() => setmessage("")}
                  />
                  {message}
                  {storeName ? (
                    <span
                      className="absolute z-10 py-1 top-2 right-4  justify-end rounded-md bg-indigo-600 px-3 text-xs font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={storeapidata}
                    >
                      Verify
                    </span>
                  ) : null}
                </div>
              </div>
              {err && (
                <h1 className="text-yellow-500">This Id Is Already Exist</h1>
              )}
              {success && (
                <h1 className="text-green-500">This Store Id Is Available</h1>
              )}

              <div>
                <label htmlFor="BusinessCategory" className="sr-only">
                  Business Category
                </label>
                <input
                  id="BusinessCategory"
                  name="business_category"
                  type="text"
                  value={business_category}
                  onChange={HandleInput}
                  required
                  className="relative block w-full rounded-b-md border-0 pl-4 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10  focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                  placeholder="BusinessCategory*"
                />
              </div>
            </div>
            <div className="mt-3">
              {/* <Recaptcha
                sitekey="6LcG1O8lAAAAAND5E4JhN2fiTPpLF0gh-5Di8-tB"
                render="explicit"
                onloadCallback={RecaptchaLoaded}
                verifyCallback={(response) =>
                  response ? setCatptcha(true) : setCatptcha(false)
                }
              /> */}
              {!isVarified  ? (
                <>
                  <button className="mt-4 opacity-40 grayscale-0 cursor-not-allowed group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Add Tenant
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="mt-4 group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={HandleRegister}
                  >
                    Add Tenant
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddTenant;
