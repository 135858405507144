import { Pagination } from "@mui/material";
import {
  Filter,
  Move,
} from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { getPlanList, updatePlan } from "../../Redux/Action/subscription.action";
import { ToastContainer } from "react-toastify";

const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { planList } = useSelector((state) => state.subscriptionReducer);
  const [SubscriptionInfo, setSubscriptionInfo] = useState();
  const [filterDD, setFilterDD] = useState(false);
  const [filter, setFilter] = useState({ limit: 10, tag: '', page: 1, search: '' })
  const { page, limit, search, tag } = filter
  // Get Module Information
  const GetSubscription = async () => {
    if (token) {
      try {
        dispatch(getPlanList(filter));
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    GetSubscription();
  }, [tag, page, limit, search]);

  useEffect(() => {
    if(planList) {
      setSubscriptionInfo(planList)
    }
  },[planList])

  // Update Module
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
      e.preventDefault();
      let _sort = [...SubscriptionInfo?.data?.records];
      const dragItemContent = _sort.splice(DragItem.current, 1)[0];
      _sort.splice(DragOverItem.current, 0, dragItemContent);
      sortData(DragOverItem.current, dragItemContent._id);
      DragItem.current = null;
      DragOverItem.current = null;
      let FinalArray = { records: _sort };
      setSubscriptionInfo(FinalArray);
  };
  const sortData = async (index, id) => {
    try {
      const body = {newIndex: index === 0 ? 0 : Number(index)}
      dispatch(updatePlan(id, body, filter, true));
        GetSubscription();
    } catch (error) {
        console.log(error)
    }
};
  const UpdateSubscription = async (key, id, value) => {
    let body;
    if (key === "status") {
      body = {
        status: value
      }
    } else {
      body = {
        free_plan: value
      }
    }
    dispatch(updatePlan(id, body, filter, true));
  };
  var startRecord = (SubscriptionInfo?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(SubscriptionInfo?.data?.currentPage * limit, SubscriptionInfo?.data?.totalRecords);
  return (
    <>
      <div className=" w-full pb-[64px]">
        <div className="flex items-center justify-between  mb-4">
          <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Subscription Plans', path: `/subscription-plans` },
            ]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Subscription Plans</h1>
          </div>
          <div className="flex  space-x-3">
            <button onClick={() => navigate("/subscription-plans/addsubscriptionplan")} className=" btn px-5 py-2.5 rounded text-sm text-white font-medium">
              Add Subscription Plan
            </button>
            <button
              className="filter-btn w-11 h-11"
              onClick={() => setFilterDD(!filterDD)}
            >
              <Filter className="inline-flex rounded-md " />
            </button>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full space-y-4">
            <div className='w-full  transition-all duration-100 border border-gray-300 inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
              <table className="w-full text-left text-sm">
                <>
                  <thead className="h-[45px]">
                    <tr className="border-b text-gray-500">
                      <th className="cursor-pointer min-w-[50px] px-3"></th>
                      <th className="cursor-pointer min-w-[200px] px-3">
                        Name
                      </th>
                      {/* <th className="cursor-pointer px-3  min-w-[200px]">Type</th> */}
                      <th className="cursor-pointer px-3 min-w-[200px]">
                        Description
                      </th>
                      <th className="cursor-pointer text-right px-3">
                        status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!SubscriptionInfo?.data?.records ? (
                      <h1 className="text-gray-600 text-base p-3">No subscription plan found</h1>
                    ) : (
                      SubscriptionInfo.data &&
                      SubscriptionInfo?.data?.records.map((elem, index) => {
                        return (
                          <tr
                            key={index}
                            className="bg-white cursor-pointer text-left border-b border-slate-200 text-sm text-gray-600"
                          >
                            <td className="flex items-center justify-center">
                              <div
                                className='cursor-move flex items-center justify-center drag-btn w-full'
                                draggable
                                onDragStart={(e) => (DragItem.current = index)}
                                onDragEnter={(e) => (DragOverItem.current = index)}
                                onDragEnd={HandleSort}
                              >
                                <Move className='cursor-move w-4' />
                              </div>
                            </td>
                            <td
                              onClick={() =>
                                navigate("/mysubscriptions/updatesubscription", {
                                  state: elem._id,
                                })
                              }
                              className="p-3"
                            >
                              {elem.name}
                            </td>
                            {/* <td
                              onClick={() =>
                                navigate("/mysubscriptions/updatesubscription", {
                                  state: elem._id,
                                })
                              }
                              className="p-3"
                            >
                              {elem.plan_type}
                            </td> */}
                            <td
                              onClick={() =>
                                navigate("/mysubscriptions/updatesubscription", {
                                  state: elem._id,
                                })
                              }
                              className=" p-3 line-clamp-1"
                            >
                              {elem.description}
                            </td>
                            <td className="space-x-3 text-right pe-4">
                              <input
                                className="h-3.5 w-8 pr-8  appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                name="is_required"
                                checked={elem.status}
                                onChange={(e) =>
                                  UpdateSubscription("status", elem._id, !elem.status)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </>
              </table>
            </div>
            {SubscriptionInfo?.data?.records?.length > 0 ? (
              <div className='bg-white border border-gray-300 flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                <span className='text-sm font-medium'>
                  {
                    limit === "all" ? `${SubscriptionInfo?.data?.totalRecords} Records` :
                      `${startRecord} - ${endRecord} of ${SubscriptionInfo?.data?.totalRecords}`
                  }
                </span>
                <div>
                  {SubscriptionInfo?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={SubscriptionInfo?.data?.totalPages}
                      page={filter?.page}
                      onChange={(e, v) => setFilter({ ...filter, page: v })}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {filterDD ? (
            <div className="z-10 absolute right-5 h-auto xl:relative xl:right-0 w-auto xl:w-1/3 xl:h-full p-3 border border-gray-300 bg-white rounded-[4px] overflow-hidden">
              <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                <Filter className="me-1 w-4 h-4" />
                Filter
              </p>
              <hr className="border-[#a0d0eb] my-3"></hr>
              <div className="grid gap-y-5 max-w-full overflow-hidden">
                {/* <div className="flex flex-wrap max-w-full rounded-sm border border-slate-300 bg-white items-center">
                  <p className="text-sm text-gray-600 font-semibold mr-2 border-r border-gray-200 px-4 py-2">
                    Display
                  </p>
                  <button className="w-8 h-8  text-slate-400 hover:bg-slate-300 ease-in duration-100 hover:text-black flex items-center justify-center mr-2 rounded-l-md">
                    <List className="w-5 h-5" />
                  </button>
                  <button className="w-8 h-8  text-slate-400 hover:bg-slate-300 ease-in duration-100 hover:text-black flex items-center justify-center rounded-r-md">
                    <Grid className="w-5 h-5" />
                  </button>
                </div> */}
                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Search
                  </span>
                  <label className="relative block w-full">
                    <input
                      className="w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-12 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      placeholder="Search for anything..."
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) =>
                        setFilter({ ...filter, search: e.target.value })
                      }
                    />
                  </label>
                </div>

                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Sort By Store Status
                  </span>
                  <div className="relative">
                    <select
                      onChange={(e) => {
                        if (e.target.value === "All") {
                          setFilter({ ...filter, tag: "" });
                        } else {
                          setFilter({
                            ...filter,
                            tag: e.target.value,
                          });
                        }
                      }}
                      name="verification_status"
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                      value={filter.tag}
                    >
                      <option>All</option>
                      <option>Monthly</option>
                      <option>Yearly</option>
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </div>
                <div>
                  <span className="text-sm text-gray-600 font-semibold mb-1 inline-block">
                    Limit
                  </span>
                  <div className="relative">
                    <select
                      onChange={(e) => {
                        if (e.target.value === "All") {
                          setFilter({ ...filter, limit: "" });
                        } else {
                          setFilter({
                            ...filter,
                            limit: e.target.value,
                          });
                        }
                      }}
                      name="verification_status"
                      className="outline-none rounded-md w-full text-slate-500 font-medium text-sm border border-gray-200 p-1.5"
                      value={filter.limit}
                    >
                      <option>All</option>
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                    </select>
                    <div className="select-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default SubscriptionPlans;
