import { Camera, X } from 'feather-icons-react/build/IconComponents';
import API from '../API';
import { useEffect, useState } from 'react';
import { NOPHOTO_IMAGE } from '../Assets/StaticData/StaticImage';
import { Skeleton } from '@mui/material';
import GenerateUniqueFilename from './GenerateUniqueFilename';
import Notification from '../Components/Notification';
const ImageUploader = ({ alreadyImage, handleSetImage, folder, format, multiple }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [image, setimage] = useState('');
  useEffect(() => {
    setimage(alreadyImage ? alreadyImage : '')
  }, [alreadyImage])
  const [loader, setLoader] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const HandleImage = async (file) => {
    if (file) {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      let parts = file?.name?.split('.');
      let Ext = parts[parts?.length - 1];
      if (
        Ext === 'jpg' ||
        Ext === 'png' ||
        Ext === 'jpeg' ||
        Ext === 'webp' ||
        Ext === 'svg'
      ) {
        try {
              setLoader(true);
              const formData = new FormData();
              formData.append('file', file);
              formData.append('folder', folder);
              try {
                const res = await API({ url: '/super/admin/fileupload/upload', method: 'post', data: formData});
                setimage(res?.data?.data[0])
                handleSetImage(res?.data?.data[0], multiple ? multiple : '');
              } catch (error) {
                setIsNotification(true);
                setNotificationMsg(error.message || 'An error occurred while uploading the image');
                setSeverity('error');
              }
              setImgErr(false);
              setLoader(false);
        } catch (error) {
          setIsNotification(true);
          setNotificationMsg(error.message || 'An error occurred while compressing the image');
          setSeverity('error');
          setImgErr(true);
          setLoader(false);
        }
      } else {
        setImgErr(true);
      }
      setLoader(false);
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <div>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div
        htmlFor='dropzone-file'
        className={`${!image && !loader ? 'border-dashed border  hover:bg-gray-50 hover:border-gray-400' : ''} flex items-center h-[130px] w-[130px] justify-center border-gray-300 rounded`}
      >
        {
          !image && !loader ?
            <label className='text-center w-full h-full flex items-center justify-center cursor-pointer'>
              <input
                id='dropzone-file'
                type='file'
                name='image'
                onChange={(e) => {
                  HandleImage(e.target.files[0]);
                }}
                disabled={loader}
                className='hidden'
              />
              <Camera className="w-8 h-8" />
            </label> :
            loader === true ?
              <>
                <Skeleton className='min-w-[130px] min-h-[130px] max-w-[130px] max-h-[130px]' variant='rounded' />
              </> :
              <div className='flex relative items-center h-[130px] w-[130px] justify-center border border-gray-300 rounded'>
                <img onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={image || NOPHOTO_IMAGE} alt={image || NOPHOTO_IMAGE} className='max-w-[120px] max-h-[120px]' />
                <button
                  onClick={(e) => { e.preventDefault(); setimage(''); handleSetImage('') }}
                  className='absolute top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 bg-[#f1f1f1] border-s border-b border-gray-300 text-xs'><X className="w-4 h-4" /></button>
              </div>
        }
      </div>
      {imgErr && (
        <span className='text-xs bg-yellow-100 mt-1 inline-block px-3 py-1 rounded'>
          <span className='font-semibold'>NOTE :</span> File Should be in jpg, jpeg, png or webp format Max Size 10 MB
        </span>
      )}
    </div>
  )
};
export default ImageUploader
