import { ArrowLeft } from 'feather-icons-react/build/IconComponents'
import React from 'react'
import { Link } from 'react-router-dom'

const NoDataFound = () => {
  return (
    <main className='grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <h1 className='mt-4 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            Data not found
          </h1>
          <p className='mt-1 text-base leading-7 text-gray-600'>
            Sorry, we couldn’t find data you’re looking for.
          </p>
          <div className='mt-4 flex items-center justify-center'>
            <Link
              to='/dashboard'
              className='rounded-[4px] flex items-center text-white btn'
            >
              <ArrowLeft className="me-2 w-4 h-4"/>Go back home
            </Link>
          </div>
        </div>
      </main>
  )
}

export default NoDataFound;