import React, { useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../controllers/encryptionUtils';
const Notification = ({ message, close, severity }) => {
    const [open, setopen] = useState(true);
    const [msg, setMsg] = useState('')
    const handleClose = () => {
        close()
        setopen(false)
        setMsg('')
    }
    const ERROR_MESSAGE_STATUS_CODE = [
        401, // Unauthorized:  The request requires user authentication, and the client must provide valid credentials to access the requested resource.
        402, // Payment error
        403, // Forbidden: The server understands the request, but the client does not have the necessary permissions to access the requested resource.
        404, // Not Found: The server cannot find the requested resource or endpoint.
        408, // Request Timeout
        409, // Conflict: The request could not be completed due to a conflict with the current state of the target resource (e.g., duplicate data).
        422, // Unprocessable Entity: The server understands the request, but the provided data or entity is semantically incorrect or not processable.
        498, // Subscription plan expired
    ]
    const INTERNAL_SERVER_ERROR = [
        400, // Bad Request: The server cannot understand the request due to a client error, such as malformed syntax or invalid parameters.
        500 // Internal Server Error: The server encountered an unexpected condition that prevented it from fulfilling the request.
    ]
    const navigate = useNavigate();
    useEffect(() => {
        if (message?.response && ERROR_MESSAGE_STATUS_CODE?.includes(message?.response?.status)) {
            if (message?.response?.status === 401) {
                navigate('/');
                localStorage.removeItem('token')
                sessionStorage.removeItem('token')
            }
            const result = decryptData(message?.response?.data?.encrypted)
            setMsg(result?.error);
        } else if (message?.response && INTERNAL_SERVER_ERROR?.includes(message?.response?.status)) {
            setMsg("Something went wrong!");
        } else {
            setMsg(message)
        }
    }, [message])
    return (
        msg?.length > 0 &&
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} >
                {msg}
            </Alert>
        </Snackbar>
    )
}
export default Notification
