import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';

import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Popover, Skeleton } from '@mui/material';

function UpdateDummyCustomeTab() {
  const params = useParams();
  const navigate = useNavigate();
  const [err,setErr]=useState(false)
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [tabData, setTabData] = useState({
    title: ''
  });
  const [description, setDescription] = useState()
  const [perticulartab, setPerticularTab] = useState()
  const [sortOrder, setSortOrder] = useState();
  const [formSpinner, setFormSpinner] = useState(true)
  const GetPerticularTab = async () => {
    setFormSpinner(true)
    try {
      const ApiCall = await API({ url: `/super/admin/dummydata/dummyextratabs/getparticularExtratab/${params?.tabid}`, method: 'get' })
      setPerticularTab(ApiCall?.data?.data)
      setTabData({
        title: ApiCall?.data?.data?.tab_title
      });
      setDescription(ApiCall?.data?.data?.tab_description)
      setSortOrder(ApiCall?.data?.data?.sort_order);
      setFormSpinner(false)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    GetPerticularTab();
  }, []);
  
  // Quill Emmpty check
  const strippedContent = description?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTabData({ ...tabData, [name]: value });
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (tabData?.title === '' || trimmedContent === '') {
      setErr(true);
    } else {
    try {
      await API({ url: `/super/admin/dummydata/dummyextratabs/updateExtratab/${perticulartab._id}`, method: 'put', data: {
        tab_title: tabData ? tabData.title : '',
        tab_description: description,
        tab_status: perticulartab.tab_status,
        sort_order: sortOrder,
      }});
      navigate('/dummy-data/dummy-custometab');
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
        //Quil To HTML code and Functions
        const quillRef = useRef(null);
        const handleQuillChange = (value) => {
          setDescription(value);
        };
      
      const [html, setHtml] = useState({htmlPP:false,code:''});
      const {htmlPP,code}=html
        const handleViewHtml = (e) => {
          e.preventDefault();
          const quill = quillRef.current.getEditor();
          const htmlContent = quill.root.innerHTML
          const formattedHtml = formatCode(htmlContent);
          setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
        };
      
        const saveHTML=()=>{
          setHtml({...html,htmlPP:false})
          setDescription(code)
        }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl mb-4 font-medium text-primary'>Update custom tab - {perticulartab?.tab_title}</h1>
        <div className='flex justify-evenly'>
          <form
            method='POST'
            className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4 mx-auto'
          >
            {
              formSpinner ?
                <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded' />
                    <Skeleton className='w-full min-h-[150px]' variant='rounded' />
                  </div>
                </div> :
                <>
                  <div className='bg-white border border-gray-300  rounded-xl p-[25px]'>
                    <div className='mb-4'>
                      <label htmlFor='title'>
                        Title
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none'
                        type='text'
                        name='title'
                        placeholder='Enter tab title'
                        value={tabData.title}
                        onChange={(e) => handleChange(e)}
                      />
                      {err && tabData?.title?.length === 0 ? (
                  <p className='text-red-500 text-sm font-medium'>Please enter tab title</p>
                ) : (
                  <></>
                )}
                    </div>
                    <div className='mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='description'>
                 Description
                </label>
                <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
               </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                />
                <p className='text-right w-full text-xs mt-0 text-gray-400'>
                  of 280 words allowed
                </p>
              </div>

              <div className='mb-4'>
                        <label htmlFor='sort_order'>
                          sort order
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='number'
                          id='sort_order'
                          name='sort_order'
                          value={sortOrder}
                          onChange={(e) => { setSortOrder(e.target.value<=0 ? 0 : e.target.value)}}
                          placeholder='Enter sort order'
                        />
                      </div>
                  </div>
                  <div className='flex items-center justify-between w-full'>
                    <Link
                      to={`/dummy-data/dummy-custometab`}
                      className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                    >
                      <ArrowLeft className='w-3 mr-1' />
                      Go to custom tabs
                    </Link>
                    <button
                      className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-md transition duration-300'
                      type='submit'
                      onClick={HandleSubmit}
                    >
                      Update Custom Tab
                    </button>
                  </div>
                </>
            }
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
}

export default UpdateDummyCustomeTab;
