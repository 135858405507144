import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Edit, PlusCircle, Trash, Move } from 'feather-icons-react/build/IconComponents';
import API from '../../../API';
import { Pagination, Tooltip } from '@mui/material';
import DataTable from 'react-data-table-component';

import Loader from '../../Loader/Loader';
import { CaretDownFill, CaretUpFill, TrashFill } from 'react-bootstrap-icons';
import { useRef } from 'react';
import Notification from '../../Notification';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { getInitialPage, replacePageInUrl } from '../../../controllers/PageHandleFromQuery';

function DummyCustomeTabTable() {
  const navigate = useNavigate();
  const [Page, setPage] = useState(getInitialPage());
  const [allTab, setAllTab] = useState();
  const [isshow, setisShow] = useState(false);
  const [purticularTab, setPurticularTab] = useState();
  const [pending, setPending] = useState(true)
  const [sortBy, setSortBy] = useState('');
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  useEffect(() => {
    replacePageInUrl(Page)
  }, [Page])
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  var startRecord = (allTab?.data?.currentPage - 1) * 20 + 1;
  var endRecord = Math.min(allTab?.data?.currentPage * 20, allTab?.data?.totalRecords);
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });

  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllTabs();
  };

  const GetAllTabs = async () => {
    try {
      const gat = await API({ url: `/super/admin/dummydata/dummyextratabs/getExtratabs`, method: 'get', params: { page: Page, sort_by: sortBy, order_by: orderBy.orderByValue } });
      if (gat.status === 200 || gat.status === 304) {
        setAllTab(gat.data);
        setPending(false)
        if(Number(startRecord)>Number(gat?.data?.data?.totalRecords)){
          setPage(Page-1)
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const tableData = allTab?.data?.records;
  useEffect(() => {
    GetAllTabs();
  }, []);


  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const HandleRemove = async (id) => {
    if (window.confirm(' Delete  Tab?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummyextratabs/deleteExtratab`, method: 'post', data: {
            id: [selectedRowsId?.length > 0 ? selectedRowsId : id]
        }});
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true)
          setNotificationMsg('Tab Removed Successfully!')
          setSeverity('success')
          GetAllTabs();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };

  // Update tabs Status
  const UpdateTabStatus = async (row, value) => {
    try {
      await API({ url: `/super/admin/dummydata/dummyextratabs/updateExtratab/${row._id}`, method: 'put', data: {
        tab_title: row.tab_title,
        tab_description: row.tab_description,
        tab_status: value,
        sort_order:row.sort_order
      }});
      GetAllTabs()
    } catch (error) {
      setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
    }
  }
  const sortCategory = async (index, id) => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummyextratabs/updateExtratab/${id}`, method: 'put', data: {
        newIndex: index === 0 ? 0 : index,
      }});
      if (apiCall.status === 200) {
        GetAllTabs()
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  // Drag And Drop Functionality
  const DragItem = useRef();
  const DragOverItem = useRef();
  // sorting DnD
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...allTab?.data?.records];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortCategory(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { data: { records: _sort } };
    setAllTab(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '20px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move drag-btn text-center w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: (
        <div className='flex items-center'>
           <button className={`uppercase flex ${iconVisible && sortBy === 'tab_title' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('tab_title') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('tab_title')}>
          Title
          {
            iconVisible ?
              !orderBy.isDecending ? <CaretUpFill className='ms-2'/> : <CaretDownFill className='ms-2'/> : <></>
          }
        </button>
        </div>

      ),
      width: '150px',
      cell: row => <span className='capitalize'>{row.tab_title}</span>,
    },
    {
      name: 'Description',
      cell: row => <span className='line-clamp-1' dangerouslySetInnerHTML={{ __html: row.tab_description?.replace(/<img[^>]*>/g, '') }}></span>,
    },
    {
      name: 'status',
      width: '80px',
      cell: row => <input
        className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
        type='checkbox'
        role='switch'
        id='flexSwitchCheckDefault'
        checked={row.tab_status === true}
        name='slider'
        onClick={() => {
          UpdateTabStatus(row, !row.tab_status)
        }}
      />
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => <div className='w-full text-right'>
        <button
          href='#'
          className='p-2 text-xs font-thin text-blue-500 hover:text-blue-600'
          onClick={() => {
            navigate(`/dummy-data/dummy-custometab/${row._id}`);
          }}
        >
          <div>
            <Tooltip title='Edit' arrow>
              <button className=''>
                <Edit className='w-[17px] h-[17px]' />
              </button>
            </Tooltip>
          </div>
        </button>
        <button
          href='#'
          className='text-xs font-thin text-blue-500 hover:text-blue-600'
          onClick={() => HandleRemove(row._id)}
        >
          <div>
            <Tooltip title='Delete' arrow>
              <button className=''>
                <Trash
                  className='text-red-500 w-[17px] h-[17px]'
                />
              </button>
            </Tooltip>
          </div>
        </button>
      </div>
    }
  ]

  return (
    <div className='h-auto overflow-y-auto pb-[64px]'>
     {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='flex items-end justify-between z-40 w-full overflow-auto pb-4'>
        <div>
          <Breadcrumbs />
          <h1 className='text-xl md:text-2xl font-medium text-primary'>Custom tabs </h1>
        </div>
        <div className='flex items-center'>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-md transition duration-300'
            onClick={() => navigate('/dummy-data/dummy-custometab/add-dummy-custometab')}
          >
            <PlusCircle className="w-[18px] h-[18px] me-2" />
            Add New Custom Tab
          </button>
        </div>
      </div>
      <div className='w-full space-y-4 max-w-full inline-block overflow-x-auto overflow-y-hidden rounded-[10px]'>
        <div>
          <details className={(selectedRowsId?.length === 0) ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative` : `cursor-pointer group w-[200px] mb-1.5 relative`}>
            <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
              <span> Bulk Selection</span>
              <span className={(selectedRowsId?.length === 0) ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                <svg
                  fill='none'
                  height={14}
                  shapeRendering='geometricPrecision'
                  stroke='currentColor'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='1.5'
                  viewBox='0 0 24 24'
                  width={14}
                >
                  <path d='M6 9l6 6 6-6' />
                </svg>
              </span>
            </summary>
            <div className={(selectedRowsId?.length === 0) ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
              <button className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center' onClick={() => HandleRemove()}>
                <TrashFill className='w-3.5 h-3.5' />
                <span>Delete selection</span>
              </button>
            </div>
          </details>
        </div>
        <DataTable
          columns={columns}
          data={tableData}
          selectableRows
          onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
          selectableRowsHighlight
          highlightOnHover
          progressPending={pending}
          progressComponent={<Loader />}
          className='border border-gray-300'
        />
        {/* Paginator */}
        {allTab?.data ? (
          <div className='bg-white border border-gray-300 rounded-[10px] flex flex-1 p-4 items-center justify-between pagination overflow-hidden'>
            <div className='hidden sm:block'>
              <p className='flex w-full text-black space-x-4'>
                <span className='text-sm font-medium'>
                  {`${startRecord} - ${endRecord} of ${allTab?.data?.totalRecords}`}
                </span>
              </p>
            </div>
            <div>
              {allTab?.data?.totalPages !== 1 ? (
                <Pagination
                count={allTab?.data?.totalPages}
                page={Page}
                onChange={(e, v) => setPage(v)}
              />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {isshow ? (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity  z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen max-h-full'>
          <div className='relative w-full max-w-[800px] mx-auto max-h-full top-[50%] -translate-y-[50%]'>
            <div className='relative bg-white shadow dark:bg-gray-700'>
              <div className='flex items-center justify-between bg-[#f1f1f1] p-3 border-b border-gray-300 rounded-t dark:border-gray-600'>
                <h1 className='capitalize font-medium'>View purticular tab</h1>
                <button
                  type='button'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                  data-modal-hide='staticModal'
                  onClick={() => {
                    setisShow(false);
                  }}
                >
                  <svg
                    className='w-5 h-5'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              </div>
              {purticularTab && purticularTab ? (
                <div className='flex flex-wrap'>
                  <div className='p-8 w-full space-y-4'>
                    <h1 className='capitalize font-medium text-lg'>
                      {purticularTab && purticularTab.data.tab_title}
                    </h1>
                    <div className='max-h-52 overflow-y-auto border p-3'>
                      <p className='text-sm text-gray-500'>
                        {purticularTab && purticularTab.data.tab_description}
                      </p>
                    </div>
                    <h1 className='capitalize text-sm mb-2'>
                      <span className='font-medium me-2'>Status :</span>
                      {String(purticularTab && purticularTab.data.tab_status)}
                    </h1>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DummyCustomeTabTable;
