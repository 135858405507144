import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { X, Trash, PlusCircle, ArrowLeft } from 'feather-icons-react/build/IconComponents';
import API from '../../../API';

import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { Popover } from '@mui/material';
function CreateDummyVariant() {
  const [groupValueArray, setGroupValueArray] = useState([]);
  const [groupValue, setGroupValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [description, setDescription] = useState();
  const [varientGroup, setvarientGroup] = useState({
    group_name: 'Colour',
  });
  const navigate = useNavigate();
  const { group_name } = varientGroup;
  const handleChange = (e) => {
    e.preventDefault()
    setvarientGroup({ ...varientGroup, [e.target.name]: e.target.value });
  };
  // save Group Values In Array
  const SaveGroupValue = () => {
    if (groupValue.length > 0) {
      if (groupValue.includes(',')) {
        let TagSeperator = groupValue.split(',');
        let finalArray = []
        let productTags = [...groupValueArray, ...TagSeperator]
        productTags.filter(tag => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag))
          if (!exists) {
            finalArray.push(tag.trim())
          }
        })
        setGroupValueArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${groupValue}$`, "i");
        const exists = groupValueArray.some((tag) => searchRegex.test(tag));
        if (!exists) {
          setGroupValueArray((prev) => [...prev, groupValue]);
        }
      }
      setGroupValue('');
    }
  };
  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      groupValueArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setGroupValueArray([...groupValueArray]);
  };
  const [err, setErr] = useState(false)
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (groupValueArray.length === 0) {
      setErr(true)
    } else {
      try {
        const data = await API({ url: '/super/admin/dummydata/dummygroupvariant/addDummyGroupVariant', method: 'post', data: {
          group_name: group_name,
          group_value: groupValueArray?.filter(x => x !== ""),
          description: description
        }});
        if (data.status === 200 || data.status === 304) {
          setTimeout(() => {
            navigate('/dummy-data/dummy-group-variants');
          }, 500);
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
      setDescription(value);
    };
  
  const [html, setHtml] = useState({htmlPP:false,code:''});
  const {htmlPP,code}=html
    const handleViewHtml = (e) => {
      e.preventDefault();
      const quill = quillRef.current.getEditor();
      const htmlContent = quill.root.innerHTML
      const formattedHtml = formatCode(htmlContent);
      setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
    };
  
    const saveHTML=()=>{
      setHtml({...html,htmlPP:false})
      setDescription(code)
    }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium text-primary'>Add new group variant</h1>
        <div className='flex justify-evenly p-4'>
          <form
            method='POST'
            className='w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
            onSubmit={HandleSubmit}
          >
            <div className='bg-white p-[25px] shadow rounded-xl'>
              <div className='mb-4'>
                <div className='flex w-full justify-between'>
                  <label
                    htmlFor='product_name'
                  >
                    Group Name
                  </label>
                  <button
                    className='text-link underline inline-block text-sm transition duration-300'
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(true);
                      setvarientGroup({ group_name: "" })
                    }}
                  >
                    Add custom name
                  </button>
                </div>
                {!open && (
                  <div className='relative'>
                    <select
                      className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                      name='group_name'
                      value={group_name}
                      onChange={handleChange}
                    >
                      <option>Colour</option>
                      <option>Size</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                )}
                {open && (
                  <input
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    id='group_name'
                    name='group_name'
                    value={group_name}
                    onChange={handleChange}
                    placeholder='Group name'
                  />
                )}
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='Description'>
                    Group value
                  </label>
                  <p className='text-gray-500 font-normal text-xs'>
                    Use <b className='text-black'>Enter</b> key to separate the value
                  </p>
                </div>
                <input
                  className={err && groupValueArray.length === 0 ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none` : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`}
                  type='text'
                  id='group_value'
                  name='group_value'
                  value={groupValue}
                  onChange={(e) => setGroupValue(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' ? (e.preventDefault(), SaveGroupValue()) : ''}
                  placeholder='e.g. Black'
                />
                {
                  err && groupValueArray.length === 0 ? <p className='text-red-500 font-medium text-sm'>Please enter value</p> : <></>
                }
                <div className='flex flex-wrap items-center mt-2'>
                  {groupValueArray &&
                    groupValueArray.map((elem, index) => {
                      if (elem !== "")
                        return (
                          <span
                            key={index}
                            className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                          >
                            {elem}
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                              onClick={() => RemoveTag(index)}
                            />
                          </span>
                        );
                    })}
                </div>
              </div>
              <div className='mb-4'>
              <div className='flex justify-between items-center'>
                <label htmlFor='description'>
                  Group Description
                </label>
                <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
               </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                  onChange={handleQuillChange}
                />
                <p className='text-right w-full text-xs mt-0 text-gray-400'>
                  of 280 words allowed
                </p>
              </div>
            </div>
            <div className='flex items-center justify-between mt-4 w-full'>
              <Link to={`/group-variants`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to group variants</Link>
              <button
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                type='submit'
              >
                Add New Group Variant
              </button>
            </div>
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
}

export default CreateDummyVariant;
