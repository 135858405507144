import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTobeSettled, makePayment, viewBankDetails } from "../../Redux/Action/payment.action";
import DataTable from "react-data-table-component";
import { Eye, X } from "feather-icons-react/build/IconComponents";
import FormatAmount from "../../controllers/FormatAmount";
import { Popover } from '@mui/material'
import { ToastContainer } from "react-toastify";
const AmountsTobeSettle = () => {
  const dispatch = useDispatch();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [clearRow, setClearRow] = useState(false)
  const { tobeSettlePayment } = (useSelector((state) => state.paymentReducer));
  useEffect(() => {
    dispatch(getTobeSettled());
  }, []);
  const { viewBankDetail } = useSelector((state) => state.paymentReducer)
  const [showId, setShowId] = useState('')
  const [isView, setIsView] = useState(false)
  const [viewData, setViewData] = useState()
  const handleViewData = async (e, name) => {
    e.preventDefault()
    try {
      dispatch(viewBankDetails(name));
      setIsView(true)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if(viewBankDetail) {
      setViewData(viewBankDetail?.data)
    }
  },[viewBankDetail])
  // table Data
  const columns = [
    {
      name: 'Tenant Name',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>{row?.tenant_name}</p>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          // onClick={() => SortData("announcement_title")}
          >
            Payable Amout
          </button>
          {/* {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )
          ) : (
            <></>
          )} */}
        </div>
      ),
      width: "150px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize w-full font-medium">
          {FormatAmount(row.pay_out_data?.payable_amount)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          // onClick={() => SortData("announcement_title")}
          >
            total orders amount
          </button>
          {/* {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )
          ) : (
            <></>
          )} */}
        </div>
      ),
      width: "190px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize w-full text-center font-medium">
          {FormatAmount(row.pay_out_data?.total_orders_amount)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          // onClick={() => SortData("announcement_title")}
          >
            shopeasy service fee
          </button>
          {/* {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )
          ) : (
            <></>
          )} */}
        </div>
      ),
      width: "190px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize w-full text-center font-medium">
          {FormatAmount(row.pay_out_data?.shopeasy_service_fee ? row.pay_out_data?.shopeasy_service_fee : 0)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          // onClick={() => SortData("announcement_title")}
          >
            total shipping charges
          </button>
          {/* {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )
          ) : (
            <></>
          )} */}
        </div>
      ),
      width: "190px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize w-full text-center font-medium">
          {FormatAmount(row.pay_out_data?.total_shipping_charges ? row.pay_out_data?.total_shipping_charges : 0)}
        </span>
      ),
    },
    {
      name: (
        <div className="flex items-center">
          <button
            className="uppercase"
          // onClick={() => SortData("announcement_title")}
          >
            transacation charge
          </button>
          {/* {iconVisible && sortBy === "announcement_title" ? (
            !orderBy.isDecending ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )
          ) : (
            <></>
          )} */}
        </div>
      ),
      width: "190px",
      cell: (row) => (
        <span className="line-clamp-1 capitalize w-full text-center font-medium">
          {FormatAmount(row.pay_out_data?.transaction_charge ? row.pay_out_data?.transaction_charge : 0)}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="space-x-2">
          <button
            onClick={(e) => { handleViewData(e, row?.tenant_name); setShowId(row?.tenant_name) }}>
            <Eye className="w-[18px] h-[18px]" />
          </button>
        </div >
      ),
    },
  ];
  return (
    <div className="p-4 w-full">
      {
        selectedRowsId?.length > 0 &&
        <button
          onClick={() => {
            if (window.confirm('Pay pending settlements')) {
              dispatch(makePayment(selectedRowsId))
              setClearRow(true)
            }
          }}
        >
          <button className="btn text-sm mb-2 font-medium px-5 py-1.5 text-white rounded-[4px]">
            Pay Now
          </button>
        </button>
      }
      <div className="w-full flex border border-gray-300 category-table  overflow-x-auto overflow-y-hidden rounded-[4px]">
        <DataTable
          columns={columns}
          data={tobeSettlePayment?.records}
          fixedHeader
          selectableRows
          fixedHeaderScrollHeight="700px"
          onSelectedRowsChange={({ selectedRows }) => {
            setClearRow(false)
            setSelectedRowsId([...new Set(selectedRows?.map((x) => x.tenant))]);
          }}
          clearSelectedRows={clearRow}
          selectableRowsHighlight
          highlightOnHover
        />
      </div>
      {
        isView &&
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={isView}
            className='h-screen popup-content'
            onClose={() => {
              setIsView(false);
              setViewData();
              setShowId('')
            }}
          >
            <div className='w-[350px] md:w-[600px] max-h-full'>
              <div className='flex items-center bg-[#f5f5f5] justify-between p-4 border-b'>
                <p className='capitalize text-lg font-medium'>Bank Details - {showId}</p>
                <button aria-label='movelist'
                  type='button'
                  className='rounded-md transition-all duration-200 focus:outline-none close-btn'
                  onClick={() => {
                    setIsView(false);
                    setViewData();
                    setShowId('')
                  }}
                >
                  <X className='h-4 w-4' aria-hidden='true' />
                </button>
              </div>
              <div className="p-4 space-y-3">
                <p>
                  <span className="font-medium">Account Holder Name : </span>
                  <span className="">{viewData?.account_holder_name}</span>
                </p>
                <p>
                  <span className="font-medium">Account Number : </span>
                  <span className="">{viewData?.account_number}</span>
                </p>
                <p>
                  <span className="font-medium">IFSC Code : </span>
                  <span className="">{viewData?.IFSC_code}</span>
                </p>
                <p>
                  <span className="font-medium">Swift Code : </span>
                  <span className="">{viewData?.swift_code}</span>
                </p>
                <p>
                  <span className="font-medium">Country : </span>
                  <span className="">{viewData?.country}</span>
                </p>

              </div>
            </div>
          </Popover>
        </div>
      }
      <ToastContainer />
    </div>
  );
};

export default AmountsTobeSettle;
