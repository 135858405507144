// import axios from "axios";
// const API = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_URL,
// });

// API.interceptors.request.use(
//   (config) => {
//     let token = localStorage.getItem("token");
//     if (!token) {
//       token = sessionStorage.getItem("token");
//     }
//     config.headers.token = token || "";
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// export default API;

import { decryptData, encryptData } from '../controllers/encryptionUtils';
import instance from './apiInstance';

// Create a function to make API calls using the instance
const API = async (config = { url: '', method: '', headers: {}, params: {}, data: {} }) => {
  try {
    // Encrypt data if available and not for upload endpoints
    if (config.data && !config.url.includes('/upload')) {
      config.data = encryptData(config.data);
    }

    // Make the API call using the axios instance
    const response = await instance({
      url: config.url,
      method: config.method,
      headers: {
        'Content-Type': config.url.includes('/upload') ? 'multipart/form-data' : 'application/json',
        ...config.headers,
      },
      params: config.params,
      data: config.data,
    });

    // Handle the response, decrypting data if in production
    let responseData =
      process.env.REACT_APP_ENVIRONMENT === 'PROD' && response.data.encrypted
        ? { data: decryptData(response.data.encrypted), status: response.status }
        : response;

    return responseData;
  } catch (error) {
    // Handle the error response, decrypting if in production
    let errorLog =
      (process.env.REACT_APP_ENVIRONMENT === 'PROD' || error.response?.data?.encrypted)
        ? decryptData(error.response.data.encrypted)
        : error?.response;

    return Promise.reject(errorLog?.data?.error ?? error?.data?.message);
  }
};

export default API;

