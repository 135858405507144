import { Dialog } from '@mui/material'
import { Trash2, X } from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumbs from '../../controllers/breadCrumbs'
import { Transition } from '../../controllers/Transition'
import { useDispatch } from 'react-redux'
import { addPlan } from '../../Redux/Action/subscription.action'
import { ToastContainer } from 'react-toastify'

const AddSubscriptionPlan = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [planPrice, setPlanPrice] = useState({
    free_duration: '0',
    monthly_price: '',
    quarterly_price: '',
    semi_yearly_price: '',
    yearly_price: ''
  })
  const [isFreePlan, setIsFreePlan] = useState(false)
  const [subscriptionData, setSubscriptionData] = useState({
    name: "",
    description: '',
    price: "",
    duration: "",
    trialPeriod: "",
    plan: [
      {
        plan_type: 'Free',
        duration: planPrice?.free_duration,
        price: 0
      },
      {
        plan_type: 'Monthly',
        duration: 30,
        price: planPrice?.monthly_price
      },
      {
        plan_type: 'Quarterly',
        duration: 90,
        price: planPrice?.quarterly_price
      },
      {
        plan_type: 'Semi Yearly',
        duration: 180,
        price: planPrice?.quarterly_price
      },
      {
        plan_type: 'Yearly',
        duration: 365,
        price: planPrice?.yearly_price
      },
    ]
  })
  useEffect(() => {
    setSubscriptionData({
      ...subscriptionData,
      plan: [
        {
          plan_type: 'Free',
          duration: planPrice?.free_duration,
          price: 0
        },
        {
          plan_type: 'Monthly',
          duration: 30,
          price: planPrice?.monthly_price
        },
        {
          plan_type: 'Quarterly',
          duration: 90,
          price: planPrice?.quarterly_price
        },
        {
          plan_type: 'Semi Yearly',
          duration: 180,
          price: planPrice?.semi_yearly_price
        },
        {
          plan_type: 'Yearly',
          duration: 365,
          price: planPrice?.yearly_price
        },
      ]
    })
  }, [planPrice])

  const authorized_features = [
    'Blog',
    'Portfolio',
    'Customer',
    'Catalog',
    'Order',
    'Abandoned Cart',
    'Analytic',
    'Discount',
    'side bar filter',
    'Payout',
    'Shipping',
    'Payment',
    'GDPR',
    'Contact-us',
    'Review',
    'FAQ',
    'module setting',
    'Role',
    'Staff',
    'customer chat',
    "Setting",
    "Newsletter"
  ]


  const [credential, setCredential] = useState("")
  const [features, setfeatures] = useState([])

  const Handlefeatures = (e) => {
    e.preventDefault()
    if (!credential) return;
    setfeatures((prev) => [credential, ...prev]);
    setCredential("")
  }
  const [selectedFeatured, setSelectedFeatured] = useState([])
  const handleSelectProduct = (option) => {
    if (option) {
      if (!selectedFeatured?.includes(option)) {
        const pro = selectedFeatured?.length > 0 && selectedFeatured?.find((x) => x === option);
        if (!pro) {
          if (selectedFeatured?.length > 0) {
            setSelectedFeatured([...selectedFeatured, option]);
          } else {
            setSelectedFeatured([option])
          }
        }
      } else {
        const removeFeatured = selectedFeatured.filter((x) => x !== option);
        setSelectedFeatured(removeFeatured);
      }
    }
  }
  const handleRemove = (option) => {
    const removeFeatured = selectedFeatured.filter((x) => x !== option);
    setSelectedFeatured(removeFeatured);
  }
 const handleAllSelect = () =>{
  if(selectedFeatured?.length === authorized_features?.length){
    setSelectedFeatured([])
  }else{
    setSelectedFeatured(authorized_features?.map((x) => x))
  }
 }
  const { name, description } = subscriptionData
  // SAve Inputs
  const HandleInput = (e) => {
    setSubscriptionData({ ...subscriptionData, [e.target.name]: e.target.value })
  }
  // Add Subscription
  const HandleSubmit = async () => {
    let body = {
      name: name,
      description,
      features,
      authorized_features: selectedFeatured,
      free_plan: isFreePlan,
      status: true
    }
    if (isFreePlan) {
      body = {
        ...body,
        duration: 3653
      }
    } else {
      body = {
        ...body,
        plan: subscriptionData?.plan.filter(item => item.price !== '' && item.price !== null && item.price !== undefined)
      }
    }
    try {
      dispatch(addPlan(body));
    } catch (error) {
      console.log(error)
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="w-full pb-[64px]">
      <div>
        <Breadcrumbs crumbs={[
          { name: 'Dashboard', path: `/dashboard` },
          { name: 'Subscription Plans', path: `/subscription-plans` },
          { name: 'Add Subscription Plans', path: `/subscription-plans` },
        ]} />
        <h1 className='text-xl md:text-2xl font-semibold text-primary'>Add Subscription Plan</h1>
      </div>
      <div className=" pt-4 overflow-x-hidden pl-3  h-full w-full">
        <form
          method="POST"
          className="w-[90%] lg:w-[70%] xl:w-[60%] text-sm bg-white border border-gray-300 p-4 mx-auto rounded-lg"
        >
          <div className="mb-4 flex w-full justify-between space-x-3">
            <div className="w-full">
              <label
                className="flex text-gray-700 text-sm font-medium mb-1"
                htmlFor="receiver"
              >
                Name
              </label>
              <input
                className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={HandleInput}
                placeholder='Ex. Basic'
              />
            </div>
          </div>
          <div className="w-full mb-4">
            <label
              className=" text-gray-700 text-sm font-medium mb-1"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
              type="text"
              id="description"
              name="description"
              required
              value={description}
              onChange={HandleInput}
              placeholder='Description'
            />
          </div>
          <div className='mb-4'>
            <label className=" text-gray-700 text-sm font-medium mb-1">
              Free Forever
            </label>
            <label className='w-full cursor-pointer select-none flex items-center space-x-2 px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'>
              <input type='checkbox' value={isFreePlan} onChange={() => setIsFreePlan(!isFreePlan)} checked={isFreePlan} />
              <span className='font-normal'>Check to create as <span className='font-bold'>Free Forever</span> plan</span>
            </label>
          </div>
          {!isFreePlan &&
            <>
              <div className='mb-4'>
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="description"
                >
                  Plan Price
                </label>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
                  <div className='w-full'>
                    <span className='w-full text-[12px] inline-block text-center'>Monthly ( 1 month )</span>
                    <input placeholder='Ex. 2000' value={planPrice?.monthly_price} onChange={(e) => setPlanPrice({ ...planPrice, monthly_price: e.target.value })} className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`} />
                  </div>
                  <div className='w-full'>
                    <span className='w-full text-[12px] inline-block text-center'>Quarterly ( 3 month )</span>
                    <input placeholder='Ex. 1800' value={planPrice?.quarterly_price} onChange={(e) => setPlanPrice({ ...planPrice, quarterly_price: e.target.value })} className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`} />
                  </div>
                  <div className='w-full'>
                    <span className='w-full text-[12px] inline-block text-center'>Semi Yearly ( 6 month )</span>
                    <input placeholder='Ex. 1700' value={planPrice?.semi_yearly_price} onChange={(e) => setPlanPrice({ ...planPrice, semi_yearly_price: e.target.value })} className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`} />
                  </div>
                  <div className='w-full'>
                    <span className='w-full text-[12px] inline-block text-center'>Yearly ( 1 year )</span>
                    <input placeholder='Ex. 1600' value={planPrice?.yearly_price} onChange={(e) => setPlanPrice({ ...planPrice, yearly_price: e.target.value })} className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`} />
                  </div>
                </div>
              </div>
              <div className="mb-4 flex w-full justify-between space-x-3">
                <div className="w-full">
                  <label
                    className=" text-gray-700 text-sm font-medium mb-1"
                    htmlFor="duration"
                  >
                    Trial Duration
                  </label>
                  <input
                    className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                    type="number"
                    id="free_duration"
                    name="free_duration"
                    value={planPrice?.free_duration}
                    onChange={(e) => setPlanPrice({ ...planPrice, free_duration: e.target.value })}
                    placeholder='Ex. 30'
                  />
                </div>
              </div>
            </>
          }
          <div className="mb-4 flex w-full justify-between space-x-3">
            <div className="w-full">
              <label
                className=" text-gray-700 text-sm font-medium mb-1"
                htmlFor="trialPeriod"
              >
                authorized features
              </label>
              <button type='button' onClick={() => setIsOpen(true)} className='bulk-btn'>Add authorized features</button>
              <div className='flex flex-wrap mt-2'>
                {selectedFeatured?.map((elem, index) => {
                  return (
                    <div
                      key={index}
                      className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded'
                    >
                      <span>{elem}</span>
                      <X
                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                        onClick={() => handleRemove(elem)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <label
            className=" text-gray-700 text-sm font-medium mb-1"
            htmlFor="short_description"
          >
            Features
          </label>
          <div className="w-full flex items-center space-x-3 mb-3">
            <input
              className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
              // className={err && category_name?.length === 0 ? `w-full px-3 py-2 border border-red-500 rounded-[4px] focus:outline-none focus:border-gray-500` : `w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
              type="text"
              id="key"
              name="key"
              value={credential}
              onChange={(e) => setCredential(e.target.value)}
              placeholder='Ex.free plan'
            />

            <button onClick={(e) => Handlefeatures(e)} className=" secondary-btn text-white px-5 py-2 rounded-[4px] transition duration-300">Add</button>
          </div>
          {
            features?.map((elem, index) => {
              return (
                <div className="w-full flex items-center space-x-3 mb-3">
                  <input
                    className={`w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                    // className={err && category_name?.length === 0 ? `w-full px-3 py-2 border border-red-500 rounded-[4px] focus:outline-none focus:border-gray-500` : `w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500`}
                    type="text"
                    id="key"
                    name="key"
                    value={elem}
                    placeholder='Key  ex.AppID'
                  />
                  <div className="min-w-[50px] text-center" >
                    <Trash2 onClick={() => setfeatures(features.filter((x, v) => v !== index))} className="w-[18px] h-[18px] cursor-pointer inline-block hover:text-black transition-all duration-150 text-gray-500" />
                  </div>
                </div>
              )

            })
          }
          <button
            className="btn w-full block text-white py-2.5 text-sm font-medium rounded-[4px] transition duration-300"
            type="button"
            onClick={HandleSubmit}
          >
            Save Subscription
          </button>
        </form>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsOpen(false)
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='w-full md:w-[35rem]  p-5 rounded bg-white shadow dark:bg-gray-700'>
          <div className='flex items-center justify-between'>
            <h6 className='font-medium capitalize'>add authorized features</h6>
            <button onClick={(e) => {
              e.preventDefault(); setIsOpen(false)
            }} className='close-btn w-6 h-6 hover:bg-[#f5f5f5] rounded flex items-center justify-center transition-all duration-150'><X className="w-4 h-4" /></button>
          </div>
          <hr className='my-4 border-gray-300' />
          <div className='divide-y max-h-[500px] overflow-y-auto pe-4'>
            <label className='flex cursor-pointer select-none py-2 items-center space-x-2'>
              <input type='checkbox' checked={authorized_features?.length === selectedFeatured?.length} onChange={(e) => handleAllSelect(e)}/>
              <span>Select All</span>
            </label>
            {
              authorized_features?.map((x) => {
                return (
                  <label className='flex cursor-pointer select-none ms-4 py-2 items-center space-x-2'>
                    <input type='checkbox' checked={selectedFeatured.includes(x)} onClick={(e) => { handleSelectProduct(e.target.value) }} value={x} />
                    <span>{x}</span>
                  </label>
                )
              })
            }
          </div>
        </div>
      </Dialog>
      <ToastContainer />
    </div>
  )
}

export default AddSubscriptionPlan;