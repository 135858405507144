import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { Edit, PlusCircle, Trash } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import API from '../../../API';
import { TrashFill } from 'react-bootstrap-icons';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import Notification from '../../Notification';

const DummyHeaderGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [groupHeaderData, setGroupHeaderData] = useState();
  const [pending, setPending] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const getHeaderData = async () => {
    setPending(true);
    try {
      const data = await API({ url: `/super/admin/dummydata/dynamicdummyheader/getHeaderGroups`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setGroupHeaderData(data.data.data);
        setPending(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getHeaderData();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are You Sure To Remove This Header Section?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dynamicdummyheader/deleteHeaderGroup`, method: 'post', data: {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
        }});
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Removed SuccesFully!');
          setSeverity('success');
          getHeaderData();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const UpdateFooterStatus = async (row, value) => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dynamicdummyheader/updateHeaderGroup/${row._id}`, method: 'put', data: {
        status: value,
      }});
      if (data.status === 200 || data.status === 304) {
        getHeaderData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const columns = [
    {
      name: 'group name',
      cell: (row) => (
        <span
          className='line-clamp-2'
          dangerouslySetInnerHTML={{ __html: row.header_section_title }}
        ></span>
      ),
      sortable: true,
      minWidth: '300px',
    },
    {
      name: 'Status',
      width: '100px',
      cell: (row) => (
        <input
          className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
          type='checkbox'
          role='switch'
          id='flexSwitchCheckDefault'
          checked={row?.status === true}
          name='brand'
          onChange={() => {
            UpdateFooterStatus(row, !row?.status);
          }}
        />
      ),
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => (
        <div className='space-x-2 w-full text-right'>
          {
            !row?.cart && !row?.notification && !row?.wish_list ?
            <>
              <button onClick={() => navigate(`/dummy-data/module-setting/header-menu/${row?._id}`)}>
                <Edit className='w-[17px] h-[17px] text-blue-500' />
              </button>
              <button onClick={() => {handleDelete(row._id)}}>
                <Trash className='w-[17px] h-[17px] text-red-500' />
              </button>
            </> : <></>
          }
        </div>
      ),
    },
  ];
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div>
            <Breadcrumbs />
            <h3 className='text-xl md:text-2xl font-medium text-primary'>Header Menu</h3>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => {
                navigate('/dummy-data/module-setting/add-header-section');
              }}
            >
              <PlusCircle className='w-[18px] h-[18px] me-2' />
              Add New Header Menu
            </button>
          </div>
        </div>
        <div className='w-full space-y-1.5'>
          <div>
            <details
              className={
                selectedRowsId?.length === 0
                  ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
                  : `cursor-pointer group w-[200px] mb-1.5 relative`
              }
            >
              <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                <span> Bulk Selection</span>
                <span
                  className={
                    selectedRowsId?.length === 0
                      ? 'transition group-open:rotate-0'
                      : 'transition group-open:rotate-180'
                  }
                >
                  <svg
                    fill='none'
                    height={14}
                    shapeRendering='geometricPrecision'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    width={14}
                  >
                    <path d='M6 9l6 6 6-6' />
                  </svg>
                </span>
              </summary>
              <div
                className={
                  selectedRowsId?.length === 0
                    ? `hidden`
                    : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                }
              >
                <button
                  className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                  onClick={() => handleDelete()}
                >
                  <TrashFill className='w-3.5 h-3.5' />
                  <span>Delete selection</span>
                </button>
              </div>
            </details>
          </div>
          <div className='w-full inline-block border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              columns={columns}
              data={groupHeaderData}
              selectableRows
              onSelectedRowsChange={({ selectedRows }) => {
                setSelectedRowsId(selectedRows?.map((x) => x._id));
              }}
              selectableRowsHighlight
              highlightOnHover
              progressPending={pending}
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DummyHeaderGroup;
