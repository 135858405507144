import {Dialog} from "@mui/material";
import {X} from "feather-icons-react/build/IconComponents";
import React from "react";
import {Transition} from "./Transition";
const ConfirmationPopup = ({
    heading,
    subheading,
    confirmationPopup,
    handleCloseConfirm,
    runFunction,
}) => {
    return (
        // <></>
        <Dialog
            open={confirmationPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => handleCloseConfirm(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className="w-full sm:w-[600px] p-6 rounded">
                <div className="flex items-center justify-between">
                    <p className="text-lg font-medium">{heading}</p>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            handleCloseConfirm(false);
                        }}
                        className="w-6 h-6 flex items-center rounded duration-150 justify-center hover:bg-[#f5f5f5]"
                    >
                        <X className="w-4 h-4" />
                    </button>
                </div>
                <hr className="my-4 border-gray-300"></hr>
                <p className="text-base">{subheading}</p>
                <button
                    className="px-5 py-2 mt-5 me-2 text-sm rounded text-white bg-red-600 hover:bg-red-700 hover:shadow transition-all duration-300"
                    onClick={(e) => {
                        e.preventDefault();
                        runFunction();
                    }}
                >
                    Yes, delete
                </button>
                <button
                    className="px-5 py-2 mt-5 text-sm rounded text-white bg-blue-600 hover:bg-blue-700 hover:shadow transition-all duration-300"
                    onClick={(e) => {
                        e.preventDefault();
                        handleCloseConfirm(false);
                    }}
                >
                    No, not now
                </button>
            </div>
        </Dialog>
    );
};

export default ConfirmationPopup;
