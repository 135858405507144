import { GET_GUIDES , ADD_GUIDE_GUAIDENCE, UPDATE_GUIDE, GET_EXTENSION_NAME, GET_PERTICULAR_DATA, ADD_GUAIDE_CATERORIES_GUAIDENCE, GET_GUAIDE_CATERORIES,GET_PERTICULAR_CATERORIES_DATA} from "../action.type";

const initialState={
    allGuides:null,
    addGuidence:null,
    updateGuide:null,
    getextensionname:null,
    getPerticularExtensionData:null ,
    addGuidenceCategories:null,
    allGuidesCategories:null,
    getPerticularCategoriesData:null
}                                 

export const guidesReducer=(state=initialState,action)=> {
    switch (action.type) {
      case GET_GUIDES:
        return {
          ...state,
           allGuides: action.payload?.data?.data,
        };
      case ADD_GUIDE_GUAIDENCE:
        return {
          ...state,
          addGuidence : action.payload?.data?.data
        }
      case UPDATE_GUIDE:
        return {
          ...state,
          updateGuide: action.payload?.data?.data
        }
      case GET_EXTENSION_NAME:
        return {
          ...state,
          getextensionname: action.payload?.data?.data
        }
      case GET_PERTICULAR_DATA:
        return {
          ...state,
          getPerticularExtensionData: action.payload?.data?.data
        }
      case ADD_GUAIDE_CATERORIES_GUAIDENCE:
        return {
          ...state,
          addGuidenceCategories : action.payload?.data?.data
        }
      case GET_GUAIDE_CATERORIES:
        return {
          ...state,
           allGuidesCategories: action.payload?.data?.data,
        };
      case GET_PERTICULAR_CATERORIES_DATA:
        return {
          ...state,
          getPerticularCategoriesData : action.payload?.data?.data,
        };
      default:
        return state;
    }
  }
