import React, { useEffect, useState } from 'react'
import { Transition } from '../../controllers/Transition'
import { Dialog } from '@mui/material'
import { X } from 'feather-icons-react/build/IconComponents'

const AddAppPlan = ({ isAddPlan, closeAddPlan, handleStorePlan, type, editData, editIndex, deletIndex }) => {
    const [initialData, setInitialData] = useState({
        name: editData?.name || '',
        plan_type: editData?.plan_type || 'Free',
        duration: editData?.duration || '',
        price: editData?.price || '',
        description: editData?.description || '',
    })
    useEffect(() => {
        setInitialData({
            name: editData?.name || '',
            plan_type: editData?.plan_type || 'Free',
            duration: editData?.duration || '',
            price: editData?.price || '',
            description: editData?.description || '',
        })
    }, [editData])
    const [storePlan, setStorePlan] = useState([])
    const HandleInput = (e) => {
        if (type === "Edit") {
            storePlan[editIndex][e.target.name] = e.target.value;
            setInitialData({ ...initialData, [e.target.name]: e.target.value })
        } else {
            setInitialData({ ...initialData, [e.target.name]: e.target.value })
        }
    }
    useEffect(() => {
        if (initialData?.plan_type === 'Free') {
            setInitialData({ ...initialData, price: '0' })
        } else if (initialData?.plan_type === 'Monthly') {
            setInitialData({ ...initialData, duration: '31' })
        } else if (initialData?.plan_type === 'Yearly') {
            setInitialData({ ...initialData, duration: '365' })
        }
    }, [initialData?.plan_type])
    const handleSubmit = (e) => {
        e.preventDefault()
        if (type !== 'Edit') {
            setStorePlan([...storePlan, initialData])
        }
        setInitialData({
            name: '',
            plan_type: 'Free',
            duration: '',
            price: '',
            description: '',
        })
        closeAddPlan()
    }
    useEffect(() => {
        handleStorePlan(storePlan)
    }, [storePlan])
    useEffect(() => {
        if (deletIndex) {
            storePlan.splice(deletIndex, 1)
            closeAddPlan()
        }
    }, [deletIndex])
    return (
        <Dialog
            open={isAddPlan}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                closeAddPlan(false); setInitialData({
                    name: '',
                    plan_type: 'Free',
                    duration: '',
                    price: '',
                    description: '',
                })
            }}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full md:w-[35rem] p-5 rounded bg-white shadow dark:bg-gray-700'>
                <div className='flex items-center justify-between'>
                    <h6 className='font-medium capitalize'>{type} App Plan</h6>
                    <button onClick={(e) => {
                        e.preventDefault(); closeAddPlan(false); setInitialData({
                            name: '',
                            plan_type: 'Free',
                            duration: '',
                            price: '',
                            description: '',
                        })
                    }} className='close-btn w-6 h-6 hover:bg-[#f5f5f5] rounded flex items-center justify-center transition-all duration-150'><X className="w-4 h-4" /></button>
                </div>
                <hr className='my-4 border-gray-300' />
                <form className='space-y-4'>
                    <div className="w-full">
                        <label
                            className="text-gray-700 text-[13px] font-medium mb-1"
                            htmlFor="Title"
                        >
                            Plan Name<span className='text-red-500 ms-1'>*</span>
                        </label>
                        <input
                            className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                            type="text"
                            name="name"
                            value={initialData?.name}
                            onChange={HandleInput}
                            placeholder='Ex. Basic'
                        />
                    </div>
                    <div className="w-full">
                        <label
                            className="text-gray-700 text-[13px] font-medium mb-1"
                            htmlFor="Title"
                        >
                            Plan Type<span className='text-red-500 ms-1'>*</span>
                        </label>
                        <div className='relative'>
                            <select className='w-full' name='plan_type' value={initialData?.plan_type} onChange={HandleInput}>
                                <option value={'Free'}>Free</option>
                                <option value={'Monthly'}>Monthly</option>
                                <option value={'Yearly'}>Yearly</option>
                            </select>
                            <div className='select-arrow'></div>
                        </div>
                    </div>
                    <div className="w-full">
                        <label
                            className="text-gray-700 text-[13px] font-medium mb-1"
                            htmlFor="Title"
                        >
                            Description<span className='text-red-500 ms-1'>*</span>
                        </label>
                        <textarea name='description' onChange={HandleInput} placeholder='Enter description' className='border w-full block border-gray-300 outline-none' value={initialData?.description} />
                    </div>
                    <div className='grid grid-cols-1 gap-4'>
                        {
                            initialData?.plan_type === 'Free' &&
                            <div className="w-full">
                                <label
                                    className="text-gray-700 text-[13px] font-medium mb-1"
                                    htmlFor="Title"
                                >
                                    Duration<span className='text-red-500 ms-1'>*</span>
                                </label>
                                <div className='flex items-center'>
                                    <input
                                        className='w-full px-3 py-2 border border-gray-300 rounded-l-[4px] focus:outline-none focus:border-gray-500'
                                        type="text"
                                        name="duration"
                                        value={initialData?.duration}
                                        onChange={HandleInput}
                                        placeholder='Ex. 15'
                                    />
                                    <p
                                        disabled
                                        className='px-4 py-2 border-y border-r border-gray-300 text-sm w-fit rounded-r-[4px]'
                                    >
                                        Days
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            initialData?.plan_type !== 'Free' &&
                            <div className="w-full">
                                <label
                                    className="text-gray-700 text-[13px] font-medium mb-1"
                                    htmlFor="Title"
                                >
                                    Price<span className='text-red-500 ms-1'>*</span>
                                </label>
                                <div className='flex items-center'>
                                    <p
                                        disabled
                                        className='px-4 py-2 border-y border-l border-gray-300 text-sm w-fit rounded-s-[4px]'
                                    >₹</p>
                                    <input
                                        className='border w-full border-gray-300 appearance-none outline-none '
                                        type='number'
                                        name='price'
                                        value={initialData?.price}
                                        onChange={HandleInput}
                                        placeholder='e.g. 1500'
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <button onClick={(e) => handleSubmit(e)} className='btn capitalize text-sm text-white'>{type} Plan</button>
                </form>
            </div>
        </Dialog>
    )
}

export default AddAppPlan