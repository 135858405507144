import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import Notification from '../../Notification'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ChevronDown, TrashFill } from 'react-bootstrap-icons'
import API from '../../../API';
import { ArrowLeft, Edit, Move, Trash } from 'feather-icons-react/build/IconComponents'
import Loader from '../../Loader/Loader'
import DataTable from 'react-data-table-component'
import { useRef } from 'react'

const UpdateDummyFooterGroup = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    redirect_type: 'new',
    section_type: "Custom"
  });
  const [storeCustomData, setStoreCustomData] = useState([])
  const params = useParams();
  const navigate = useNavigate();
  const [groupTitle, setGroupTitle] = useState()
  const [dataErr, setDataErr] = useState(false);
  const [customDataErr, setCustomDataErr] = useState(false)
  const [pageData, setPageData] = useState()
  const [categoryData, setCategoryData] = useState()
  const [filterData, setFilterData] = useState();
  const [socialData, setSocialData] = useState();
  const [isEdit, setIsEdit] = useState(false)
  const [editId, setEditId] = useState()
  const getPerticularSectionData = async () => {
    try {
      const getFooterGroupById = await API({ url: `/super/admin/dummydata/dynamicdummyfooter/getFooterGroupById/${params?.groupId}`, method: 'get' })
      setGroupTitle(getFooterGroupById?.data?.data?.footer_section_title);
      setStoreList(getFooterGroupById?.data?.data?.footer)
      setStoreCustomData(getFooterGroupById?.data?.data?.footer?.filter((x) => x.section_type === "Custom"))
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const getPageData = async () => {
    try {
      const getPages = await API({ url: `/super/admin/dummydata/dummypages/getDummyPages`, method: 'get' })
      setPageData(getPages?.data?.data);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const getCategoryData = async () => {
    try {
      const getCategories = await API({ url: `/super/admin/dummydata/dummycategory/getDummyCategorys`, method: 'get' })
      setCategoryData(getCategories?.data?.data);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const getFilterData = async () => {
    try {
      const getProductFilter = await API({ url: '/admin/client/GetProductFilter', method: 'get' });
      setFilterData(Object.entries(getProductFilter?.data).map(([name, value]) => ({
        filter_name: name.toLowerCase(),
        status: value,
      })))
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const getSocialData = async () => {
    try {
      const getClient = await API({ url: '/admin/client/getClient', method: 'get' });
      setSocialData(Object.entries(getClient?.data?.data?.social_media).map(([name, value]) => ({
        social_name: name?.toLowerCase(),
        redirect_link: value,
        status: value?.length > 8 ? true : false
      })))
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  useEffect(() => {
    getPerticularSectionData();
    getPageData();
    getCategoryData();
    // getFilterData();
    // getSocialData();
  }, [])
  const [storeList, setStoreList] = useState([])
  const handleSelectProduct = async (name, slugname, type, category_id) => {
    if (slugname) {
      const existList = storeList?.find((x) => x?.section_link === slugname && x?.section_type !== "Custom");
      if (!existList) {
        setStoreList([...storeList, { section_name: name, section_link: slugname, sort_order: storeList?.length, section_type: type, category_id }])
      } else if (existList && existList.section_type !== "Custom") {
        const filteredList = storeList?.filter((x) => {
          if (x?.section_type === "Custom") {
            return x;
          } else if (x.section_link !== slugname) {
            return x;
          }
        });
        setStoreList(filteredList)
      }
    }
  }

  const handleCheck = (slug) => {
    const check = storeList?.some((x) => x?.section_link === slug && x?.section_type !== "Custom");
    return check
  }
  const handleApplyChanges = async (e) => {
    e.preventDefault();
    try {
      handleEditSection(e);
      await API({ url: `/super/admin/dummydata/dynamicdummyfooter/addFooter`, method: 'post', data: {
        footer_group_id: params?.groupId,
        footer: storeList
      }})
      getPerticularSectionData();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity('error')
    }
  }
  const handleEditSection = async (e) => {
    e.preventDefault();
    if (!groupTitle) {
      setDataErr(true)
    } else {
      setDataErr(false)
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummyfooter/updateFooterGroup/${params?.groupId}`, method: 'put', data: {
          footer_section_title: groupTitle,
        }})
        navigate(`/dummy-data/module-setting/footer`)
      } catch (error) {
        setIsNotification(false)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
  }
  const handleDelete = async (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure to remove this footer link ?")) {
      try {
        await API({ url: `/super/admin/dummydata/dynamicdummyfooter/deleteFooter`, method: 'post', data: {
          id: [id],
        }})
        getPerticularSectionData()
      } catch (error) {
        setIsNotification(false)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
  }
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeList];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = _sort.map((list, index) => { return { ...list, sort_order: index } });
    setStoreList(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'section name',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.section_name }}></span>,
      minWidth: '300px'
    }
  ]
  const handleAddCustomData = async (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true)
    } else {
      setCustomDataErr(false)
      if (isEdit) {
        try {
          await API({ url: `/super/admin/dummydata/dynamicdummyfooter/updateFooter/${editId}`, method: 'put', data: customData})
          getPerticularSectionData();
          setIsEdit(false);
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
          setEditId('')
          setIsNotification(true)
          setNotificationMsg("Link udated successfully!")
          setSeverity('success')
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      } else {
        try {
          await API({ url: `/super/admin/dummydata/dynamicdummyfooter/addFooter`, method: 'post', data: {
            footer_group_id: params?.groupId,
            footer: [...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'Custom', redirect_type: customData?.redirect_type }]
          }})
          getPerticularSectionData();
          setCustomData({
            section_name: '',
            section_link: '',
            redirect_type: 'new'
          })
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity('error')
        }
      }
    }
  }
  const handleEditCustomData = async (e, elem) => {
    e.preventDefault();
    setIsEdit(true);
    setCustomData({
      section_name: elem?.section_name,
      section_link: elem?.section_link,
      redirect_type: elem?.redirect_type
    })
    setEditId(elem?._id)
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Edit Footer Section</h3>
        </div>
        <div className='flex space-x-4 justify-between'>
          <form className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4'>
            <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='image'>
                  footer section Title
                </label>
                <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''}`} placeholder='e.g. Categories' value={groupTitle} onChange={(e) => setGroupTitle(e.target.value)} />
                {
                  dataErr && !groupTitle && <p className='text-xs text-red-500'>Please enter section name</p>
                }
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Content pages
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {
                      pageData?.records?.map((elem) => {
                        return (
                          <label className='flex items-center space-x-4'>
                            <input type='checkbox' value={elem?._id} checked={handleCheck(`/page/${elem?.page_slug_name}`)} onClick={(e) => handleSelectProduct(elem?.pages_name, `/page/${elem?.page_slug_name}`, "Pages", elem?._id)} className='block w-4 h-4' />
                            <p className='text-sm text-black font-normal break-all'>{elem?.pages_name}</p>
                          </label>
                        )
                      })
                    }
                  </div>
                </details>
              </div>
              {/* <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Product Filter
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {
                      filterData?.map((elem) => {
                        return (
                          elem?.status === true &&
                          <label className='flex items-center space-x-4'>
                            <input type='checkbox' value={elem?.filter_name} checked={handleCheck(`/filter=${elem?.filter_name}`)} onClick={(e) => handleSelectProduct(elem?.filter_name, `/filter=${elem?.filter_name}`, "Product Filter", elem?._id)} className='block w-4 h-4' />
                            <p className='text-sm text-black font-normal break-all'>{elem?.filter_name}</p>
                          </label>
                        )
                      })
                    }
                  </div>
                </details>
              </div> */}
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Categories
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {
                      categoryData?.records?.map((elem) => {
                        return (
                          <label className='flex items-center space-x-4'>
                            <input type='checkbox' value={elem?._id} checked={handleCheck(`/category/${elem?.category_slug_name}`)} onClick={(e) => handleSelectProduct(elem?.category_name, `/category/${elem?.category_slug_name}`, "Product Category", elem?._id)} className='block w-4 h-4' />
                            <p className='text-sm text-black font-normal break-all'>{elem?.category_name}</p>
                          </label>
                        )
                      })
                    }
                  </div>
                </details>
              </div>
              {/* <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Social Media
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    {
                      socialData?.map((elem) => {
                        return (
                          elem?.status &&
                          <label className='flex items-center space-x-4'>
                            <input type='checkbox' checked={handleCheck(`/category/${elem?.redirect_link}`)} value={elem?.social_name} onClick={(e) => handleSelectProduct(elem?.social_name, elem?.redirect_link, 'Social Media')} className='block w-4 h-4' />
                            <p className='text-sm text-black font-normal break-all'>{elem?.social_name}</p>
                          </label>
                        )
                      })
                    }
                  </div>
                </details>
              </div> */}
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Accounts
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/contact-us`)} onClick={(e) => handleSelectProduct("contact us", `/contact-us`, "Your Accounts")} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>Contact us</p>
                    </label>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/login`)} onClick={(e) => handleSelectProduct("login", `/login`, "Your Accounts")} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>Login</p>
                    </label>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/account`)} onClick={(e) => handleSelectProduct("my account", `/account`, "Your Accounts")} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>My account</p>
                    </label>
                  </div>
                </details>
              </div>
              <div className='mb-4'>
                <details
                  open
                  className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
                >
                  <summary className='text-base flex items-center justify-between text-black'>
                    Help
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className='text-black w-4 h-5' />
                    </span>
                  </summary>
                  <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4  border-t'>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/announcements`)} onClick={(e) => handleSelectProduct("announcement", `/announcements`, 'Help')} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>Announcement</p>
                    </label>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/blog`)} onClick={(e) => handleSelectProduct("blog", `/blog`, 'Help')} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>Blog</p>
                    </label>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/portfolio?page=1`)} onClick={(e) => handleSelectProduct("portfolio", `/portfolio?page=1`, 'Help')} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>Portfolio</p>
                    </label>
                    <label className='flex items-center space-x-4'>
                      <input type='checkbox' checked={handleCheck(`/faqs`)} onClick={(e) => handleSelectProduct("FAQ", `/faqs`, 'Help')} className='block w-4 h-4' />
                      <p className='text-sm text-black font-normal break-all'>FAQ</p>
                    </label>
                  </div>
                </details>
              </div>
              <details
                open
                className='bg-white group border border-gray-300 space-y-4 rounded p-[15px]'
              >
                <summary className='text-base flex items-center justify-between text-black'>
                  Custom content
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className='text-black w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full pt-4  border-t'>

                  <>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        Title
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`} placeholder='e.g. Google' value={customData?.section_name} onChange={(e) => setCustomData({ ...customData, section_name: e.target.value })} />
                      {
                        customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        URL
                      </label>
                      <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`} placeholder='e.g. https://google.com' value={customData?.section_link} onChange={(e) => setCustomData({ ...customData, section_link: e.target.value })} />
                      {
                        customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                      }
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='image'>
                        redirect type
                      </label>
                      <div className='relative'>
                        <select value={customData?.redirect_type} onChange={(e) => setCustomData({ ...customData, redirect_type: e.target.value })} className='w-full border border-gray-300'>
                          <option value="new">New tab</option>
                          <option value="current">Current tab</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    <button onClick={(e) => handleAddCustomData(e)} className='secondary-btn text-sm inline-block'>Add custom data</button>
                  </>
                  {
                    storeCustomData?.map((elem) => {
                      return (
                        <div className='border border-gray-300 flex items-center justify-between p-3 rounded mt-4'>
                          <div>
                            <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                            <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                          </div>
                          <div className='space-x-2'>
                            <button onClick={(e) => { handleEditCustomData(e, elem) }}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
                            <button onClick={(e) => {
                              handleDelete(e, elem._id);
                            }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </details>
            </div>
          </form>
          {
            storeList?.length > 0 ?
              <div className='w-[90%] lg:w-[70%] xl:w-[60%] space-y-4'>
                <div className='w-full inline-block border border-gray-300 rounded-[15px] overflow-x-auto overflow-y-hidden h-auto'>
                  <DataTable
                    columns={columns}
                    data={storeList}
                    highlightOnHover
                    progressComponent={<Loader />}
                  />
                </div>
              </div>
              : <></>
          }
        </div>
      <div className='w-full flex items-center justify-between lg:w-[70%] xl:w-[80%] mx-auto'>
          <Link
            to={`/dummy-data/module-setting/footer`}
            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
          >
            <ArrowLeft className='w-3 mr-1' />
            Go to Footer menu
          </Link>
          <button
            onClick={(e) => {
              handleApplyChanges(e);
            }}
            className='btn text-sm text-white'
          >
            Apply Changes
          </button>
        </div>
      </div>
    </>
  )
}

export default UpdateDummyFooterGroup