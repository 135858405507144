import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
    Autocomplete,
    Skeleton,
    TextField,
    Popover,
} from "@mui/material";
import ReactQuill from "react-quill";
import module, { formatCode } from "../../../controllers/QuillToolbar";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import NoDataFound from "../../Main/NoDataFound";
import { GenerateSlugName } from "../../../controllers/GenerateSlugName";
import { useDispatch, useSelector } from "react-redux";
import { getParticularBlogCategory, getSEBlogCategorys, updateSEBlogCategory } from "../../../Redux/Action/website.action";
const UpdateWebsiteBlogCategories = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { perticularBlogCategory, blogCategorys } = useSelector((state) => state.websiteReducer)
    const [nodata, setNodata] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const [SelectedBlogParentCategory, setSelectedBlogParentCategory] =
        useState();
    const [BlogCatData, setBlogCatData] = useState({
        _id: "",
        blogcategory_name: "",
        meta_tag_title: "",
        meta_tag_description: "",
        keyword: "",
        parent_blogcategory: "",
    });
    const [description, setDescription] = useState();
    const [blogCatId, setBlogCatId] = useState();
    const [formSpinner, setFormSpinner] = useState();
    const GetBlogCategoryById = async () => {
        setFormSpinner(true);
        try {
            dispatch(getParticularBlogCategory(params.website_blog_categories_slug_name));
        } catch (error) {
            setNodata(true);
            console.log(error);
        }
    };

    const {
        blogcategory_name,
        meta_tag_title,
        meta_tag_description,
        keyword,
        parent_blogcategory,
    } = BlogCatData;
    let name, value;
    const HandleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setBlogCatData({ ...BlogCatData, [name]: value });
    };

    const [slugName, setSlugName] = useState();
    const [enableSlug, setEnableSlug] = useState(false);
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugName(await GenerateSlugName(slug));
        }
    };

    // get Parents Category
    const [ParentCategory, setParentCategory] = useState([]);
    const GetParentCategory = async (id) => {
        try {
            dispatch(getSEBlogCategorys());
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        GetBlogCategoryById();
    }, []);

    useEffect(() => {
        if(blogCategorys) {
            setParentCategory(
                blogCategorys?.data?.records?.filter((x) => x._id !== blogCatId)
            );
        }
    },[blogCategorys])

    useEffect(() => {
        if(perticularBlogCategory) {
            setBlogCatId(perticularBlogCategory?.data?._id);
            setSlugName(perticularBlogCategory?.data?.blog_category_slug_name)
            setSelectedBlogParentCategory(
                perticularBlogCategory?.data?.parent_blogcategory?._id
            );
            setBlogCatData({
                _id: perticularBlogCategory?.data?._id
                    ? `${perticularBlogCategory?.data?._id}`
                    : "",
                blogcategory_name: perticularBlogCategory?.data?.blogcategory_name
                    ? `${perticularBlogCategory?.data?.blogcategory_name}`
                    : "",
                meta_tag_title: perticularBlogCategory?.data?.meta_tag_title
                    ? `${perticularBlogCategory?.data?.meta_tag_title}`
                    : "",
                meta_tag_description: perticularBlogCategory?.data?.meta_tag_description
                    ? `${perticularBlogCategory?.data?.meta_tag_description}`
                    : "",
                keyword: perticularBlogCategory?.data?.keyword
                    ? `${perticularBlogCategory?.data?.keyword}`
                    : "",
                parent_blogcategory: perticularBlogCategory?.data?.parent_blogcategory
                    ? `${perticularBlogCategory?.data?.parent_blogcategory}`
                    : "",
            });
            GetParentCategory(perticularBlogCategory?.data?._id);
            setDescription(perticularBlogCategory?.data?.description);
            setFormSpinner(false);
        }
    }, [perticularBlogCategory]);

    const [err, setErr] = useState(false);
    const HandleSubmit = async (e) => {
        e.preventDefault();
        if (blogcategory_name === "") {
            setErr(true);
        } else {
            try {
                const body = {
                    ...BlogCatData,
                    blog_category_slug_name: slugName,
                    description: description,
                    parent_blogcategory: SelectedBlogParentCategory,
                }
                dispatch(updateSEBlogCategory(blogCatId, body));
            } catch (error) {
                console.log(error);
            }
        }
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setDescription(value);
    };

    const [html, setHtml] = useState({ htmlPP: false, code: "" });
    const { htmlPP, code } = html;
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML;
        const formattedHtml = formatCode(htmlContent);
        setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({ ...html, htmlPP: false });
        setDescription(code);
    };
    return nodata ? (
        <NoDataFound />
    ) : (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary ">
                    Update website blog category -{" "}
                    {BlogCatData?.blogcategory_name}
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto"
                    >
                        {formSpinner ? (
                            <div className="space-y-4">
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="flex items-center space-x-4 w-full">
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[45px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[200px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[100px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[100px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-white group shadow  space-y-4 rounded-xl p-[25px]">
                                    <div className="mb-4">
                                        <label htmlFor="blogcategory_name">
                                            Name
                                        </label>
                                        <input
                                            className={
                                                blogcategory_name === "" && err
                                                    ? "w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none"
                                                    : "w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            }
                                            type="text"
                                            id="blogcategory_name"
                                            name="blogcategory_name"
                                            value={blogcategory_name}
                                            onChange={HandleInput}
                                            onBlur={() => handleSlug(blogcategory_name)}
                                            placeholder="e.g. Tech"
                                        />
                                        {blogcategory_name === "" && err ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter blog category name
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                        <div className="w-full">
                                            <label htmlFor="category_name">
                                                blog Slug name
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </label>
                                            <div
                                                className={`flex items-center justify-between w-full border ${err && enableSlug
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } rounded-[4px]`}
                                            >
                                                <input
                                                    className="w-full  focus:outline-none"
                                                    type="text"
                                                    name="blog_slug_name"
                                                    value={slugName}
                                                    disabled={!enableSlug}
                                                    onChange={(e) =>
                                                        setSlugName(e.target.value)
                                                    }
                                                />
                                                {enableSlug ? (
                                                    <Check
                                                        onClick={async () => {
                                                            if (slugName?.length > 0) {
                                                                setSlugName(
                                                                    await GenerateSlugName(
                                                                        slugName
                                                                    )
                                                                );
                                                                setEnableSlug(!enableSlug);
                                                                setErr(false);
                                                            }
                                                        }}
                                                        className="text-green-500 p-1 h-8 w-8"
                                                    />
                                                ) : (
                                                    <Edit
                                                        onClick={() =>
                                                            setEnableSlug(!enableSlug)
                                                        }
                                                        className="text-blue-500 p-1 h-7 w-7"
                                                    />
                                                )}
                                            </div>
                                            {err && enableSlug && (
                                                <p className="text-xsm text-red-500">
                                                    Please save this changes
                                                </p>
                                            )}
                                        </div>
                                    <div className="mb-4">
                                        <label htmlFor="blog Category">
                                            Select Parent Blog Category
                                        </label>
                                        <Autocomplete
                                            className="autocomplete"
                                            options={
                                                ParentCategory
                                                    ? ParentCategory
                                                    : []
                                            }
                                            getOptionLabel={(parent) =>
                                                parent.blogcategory_name
                                            }
                                            onChange={(e, v) => {
                                                v
                                                    ? setSelectedBlogParentCategory(
                                                        v._id
                                                    )
                                                    : setSelectedBlogParentCategory(
                                                        ""
                                                    );
                                            }}
                                            value={
                                                ParentCategory?.find(
                                                    (category) =>
                                                        category._id ===
                                                        BlogCatData?.parent_blogcategory
                                                ) || null
                                            }
                                            renderInput={(blogsCategory) => (
                                                <TextField
                                                    placeholder={
                                                        blogCatId
                                                            ?.parent_category
                                                            ?.blogcategory_name
                                                            ? blogCatId
                                                                ?.parent_category
                                                                ?.blogcategory_name
                                                            : "Select parent category"
                                                    }
                                                    Value={blogsCategory}
                                                    className="cursor-pointer"
                                                    {...blogsCategory}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex justify-between items-center">
                                            <label htmlFor="description">
                                                blog category Description
                                            </label>
                                            <section
                                                className="inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300"
                                                onClick={handleViewHtml}
                                            >
                                                View HTML
                                            </section>
                                        </div>
                                        <ReactQuill
                                            ref={quillRef}
                                            modules={module}
                                            theme="snow"
                                            value={
                                                description?.length &&
                                                description
                                                    ?.split(" ")
                                                    .slice(0, 280)
                                                    .join(" ")
                                            }
                                            onChange={handleQuillChange}
                                        />
                                        <p className="text-right w-full text-xs mt-0 text-gray-400">
                                            of 280 words allowed
                                        </p>
                                    </div>
                                </div>
                                <details className="bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]">
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            Search engine listing
                                            <p className="text-xs text-gray-500 font-normal">
                                                Add a title and description to
                                                see how this product might
                                                appear in a search engine
                                                listing
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <div className="mb-4">
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="Meta Tag Title"
                                                className="flex items-center justify-between"
                                            >
                                                Meta Title
                                            </label>
                                        </div>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="Meta Tag Title"
                                            name="meta_tag_title"
                                            value={meta_tag_title}
                                            onChange={HandleInput}
                                            placeholder="Enter meta title"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <div className="flex items-center justify-between">
                                            <label
                                                htmlFor="Meta Tag description"
                                                className="flex items-center justify-between"
                                            >
                                                Meta Description
                                            </label>
                                        </div>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="Meta Tag description"
                                            name="meta_tag_description"
                                            value={meta_tag_description}
                                            onChange={HandleInput}
                                            placeholder="Enter meta description"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="keyword">
                                            Meta Keyword
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="keyword"
                                            name="keyword"
                                            value={keyword}
                                            onChange={HandleInput}
                                            placeholder="e.g. Cloth fashion"
                                        />
                                    </div>
                                </details>
                                <div className="flex items-center justify-between w-full">
                                    <Link
                                        to={`/site-data/blog-category`}
                                        className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                                    >
                                        <ArrowLeft className="w-3 mr-1" />
                                        Go to blog categories
                                    </Link>
                                    <button
                                        className="inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        type="submit"
                                        onClick={HandleSubmit}
                                    >
                                        Update Blog Category
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
            {htmlPP && (
                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                    <Popover
                        open={htmlPP}
                        className="h-screen popup-content"
                    // onClose={() => {
                    //   setHtmlPP(false);
                    // }}
                    >
                        <div className="max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                            <div className="w-[35rem] h-96  mb-4">
                                <label
                                    className="flex justify-between text-gray-700 text-md font-bold mb-2"
                                    htmlFor="product_name"
                                >
                                    Edit HTML:
                                    <X
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are You Sure to Leave?. Your recent changes will not be included."
                                                )
                                            ) {
                                                setHtml({
                                                    ...html,
                                                    htmlPP: false,
                                                });
                                            }
                                        }}
                                    />
                                </label>

                                <label className="flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        rows={16}
                                        value={code}
                                        onChange={(e) =>
                                            setHtml({
                                                ...html,
                                                code: e.target.value,
                                            })
                                        }
                                        placeholder="<p>Code here<p/>"
                                    />
                                </label>
                            </div>
                            <button
                                className="btn w-full text-white px-3 py-2 rounded-[10px] mx-auto"
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    );
};

export default UpdateWebsiteBlogCategories;
