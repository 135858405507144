import React from 'react'

const AddStatus = () => {
  return (
    <div className="w-full pb-[64px]">
    <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
      <h1 className="text-3xl font-medium text-center text-primary">
        Add Status
      </h1>

    </div>
    <div className="overflow-x-hidden h-full w-full">
      <form
        method="POST"
        className="w-[90%] lg:w-[70%] xl:w-[60%] text-sm bg-white border border-gray-300 p-4 mx-auto rounded-lg"
      >
           <div className="mb-4 flex w-full justify-between space-x-3">
            <div className="w-full">
              <label
                className="text-gray-700 text-sm font-medium mb-1"
                htmlFor="Title"
              >
                Title
              </label>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                type="text"
                name="title"
                placeholder='Module Title'
              />
            </div>
          </div>
        </form>
        </div>
        </div>
  )
}

export default AddStatus