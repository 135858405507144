import React, { useState, useEffect, useRef } from 'react';
import Compressor from 'compressorjs';
import ReactQuill from 'react-quill';
import API from '../../../API';
import { Link, useNavigate, useParams } from 'react-router-dom';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import {
  TextField,
  Autocomplete,
  Backdrop,
  CircularProgress,
  Tooltip,
  Popover,
  Skeleton,
} from '@mui/material';
import { GenerateSlugName } from '../../../controllers/GenerateSlugName';
import { X, ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import Notification from '../../Notification';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ImageUploader from '../../../controllers/ImageUploader';

const UpdateDummyBlog = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [warn, setwarn] = useState(false);
  const [elem, setElem] = useState();
  // image upload logic
  const [image, setimage] = useState();
  const [meta, setMeta] = useState({ meta_title: "", meta_description: "" })
  const { meta_description, meta_title } = meta
  const [SelectCategory, setSelectCategory] = useState();
  const [secondDescription, setSecondDescription] = useState()
  const [title, setTitle] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [err, setErr] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleSetImage = (value) => {
    setimage(value)
  }
  const [formSpinner, setFormSpinner] = useState(true)
  const getPerticularBlog = async () => {
    setFormSpinner(true)
    const res = await API({ url: `/super/admin/dummydata/dummyblogs/getDummyBlog/${params.blog_slug_name}`, method: 'get' });
    setElem(res.data.data);
    setimage(res?.data?.data?.image);
    setSelectCategory(
      res?.data?.data.blogcategory ? res?.data?.data.blogcategory : '',
    );
    setMeta({
      meta_title: res?.data?.data?.meta_title || '',
      meta_description: res?.data?.data?.meta_description || '',
    });
    setSortOrder(res?.data?.data?.sort_order || '');
    setSecondDescription(res.data.data?.description ? `${res.data.data?.description}` : '');
    setTitle(res.data.data?.title ? `${res.data.data.title}` : '');
    setSlugName(res.data.data?.blog_slug_name ? `${res.data.data.blog_slug_name}` : '');
    setBlogTagsArray(res.data.data?.blog_tags)
    setFormSpinner(false)
  };
  useEffect(() => {
    getPerticularBlog();
  }, []);

  // get Store Category
  const [StoreCategory, setStoreCategory] = useState([]);
  const GetStoreCategory = async () => {
    try {
      const ApiCall = await API({ url: `/super/admin/storecategory/getStoreCategory`, method: 'get' });
      setStoreCategory(ApiCall?.data?.data?.categorys);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    GetStoreCategory();
  }, []);

  // get Parents Category
  const [Category, setCategory] = useState([]);
  const GetCategory = async () => {
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyblogcategory/getDummyBlogCategorys`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setCategory(data.data);
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const [slugName, setSlugName] = useState();
  useEffect(() => {
    GetCategory();
  }, []);




  const handleBlogCategory = (id, name) => {
    if (id) {
      const cat = SelectCategory.find((x) => x._id === id);
      if (!cat) {
        setSelectCategory([...SelectCategory, { _id: id, blogcategory_name: name }]);
      }
    }
  }
  const HandleUpdate = async (e) => {
    e.preventDefault();
    try {
      const data = await API({ url: `/super/admin/dummydata/dummyblogs/updateDummyBlog/${elem._id}`, method: 'put', data: {
        blogcategory: SelectCategory?.map((x) => x._id),
        // blog_tags: blogTagsArray,
        title: title,
        image: image,
        description: secondDescription,
        blog_slug_name: slugName,
        sort_order: sortOrder,
        blog_tags: blogTagsArray?.filter(x => x !== ""),
        ...meta,
      }});
      if (data.status === 200 || data.status === 304) {
        navigate('/dummy-data/dummy-blog');
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const [blogTagsValue, setBlogTagsValue] = useState([]);
  const [blogTagsArray, setBlogTagsArray] = useState([]);

  // save Group Values In Array
  const SaveblogTagsValue = () => {
    if (blogTagsValue.length > 0) {
      if (blogTagsValue.includes(',')) {
        let TagSeperator = blogTagsValue.split(',');

        let finalArray = []
        let productTags = [...blogTagsArray, ...TagSeperator]
        productTags.filter(tag => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, "i");
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag))
          if (!exists) {
            finalArray.push(tag.trim())
          }
        })
        setBlogTagsArray(finalArray);
      } else {

        const searchRegex = new RegExp(`^${blogTagsValue}$`, "i");

        const exists = blogTagsArray.some((tag) => searchRegex.test(tag));

        if (!exists) {
          setBlogTagsArray((prev) => [...prev, blogTagsValue]);
        }
      }
      setBlogTagsValue('');
    }
  };

  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      blogTagsArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setBlogTagsArray([...blogTagsArray]);
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const removeCategory = (id) => {
    const removeRelated = SelectCategory.filter((x) => x._id !== id);
    setSelectCategory(removeRelated);
  }

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setSecondDescription(value);
  };

  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const { htmlPP, code } = html
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };

  const saveHTML = () => {
    setHtml({ ...html, htmlPP: false })
    setSecondDescription(code)
  }

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}

      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Update dummy blog - {elem?.title}</h1>
        <div className='flex justify-evenly p-4'>
          <form method='POST' className='w-[80%] space-y-4 sm:w-[70%] xl:w-[50%] mx-auto'>
            {
              formSpinner ?
                <div className='space-y-4'>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div className='flex items-center space-x-4 w-full'>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div>
                      Media
                      <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your product stand out.</p>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                    </div>
                  </div>
                </div> :
                <>
                  <div className='bg-white group border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                    <div className='w-full mb-4'>
                      <label htmlFor='title' className='flex items-center justify-between'>
                        <p className='text-[13px]'>Blog Title<span className='text-red-500 ms-1'>*</span></p>
                        <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        name='title'
                        maxLength={60}
                        value={title}
                        onChange={(e) => { setTitle(e.target.value); setwarn(false) }}
                        placeholder='e.g. Tech'
                      // onBlur={async () => setSlugName(await GenerateSlugName(title))}
                      />
                      {warn && (
                        <h1 className='text-sm text-red-500'>
                          Please enter blog title
                        </h1>
                      )}
                    </div>
                    <div className='w-full mb-4'>
                      <label className='flex items-center justify-between' htmlFor='category_name'>
                        blog Slug name
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='blog_slug_name'
                        name='blog_slug_name'
                        value={slugName}
                        disabled
                      // onChange={(e) => setSlugName(e.target.value)}
                      // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                      />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='description'>Blog category</label>
                      <Autocomplete
                        className='autocomplete'
                        options={Category?.data ? Category?.data?.records : []}
                        getOptionLabel={(category) => category.blogcategory_name}
                        onChange={(e, v) => v ? handleBlogCategory(v?._id, v?.blogcategory_name) : ''}
                        // value={Category?.data?.find((category) => category.blogcategory_name === elem?.blogcategory?.blogcategory_name) || null}
                        renderInput={(Categories) => (
                          <TextField
                            placeholder='Select blog category'
                            className='cursor-pointer'
                            defaultValue={elem?.blogcategory?.blogcategory_name}
                            {...Categories}
                          />
                        )}
                      />
                      {
                        SelectCategory?.map((elem, index) => {
                          return (
                            <span
                              key={index}
                              className='bg-[#f1f1f1] inline-flex items-center me-2 mt-1 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded-[10px]'
                            >
                              {elem?.blogcategory_name}
                              <X
                                className='text-black cursor-pointer h-[14px] w-[14px] ms-2'
                                onClick={() => removeCategory(elem?._id)}
                              />
                            </span>
                          )
                        })
                      }
                    </div>
                    <div className='mb-4'>
                      <div className='flex space-x-3 justify-between items-center mb-1'>
                        <label
                          htmlFor='Description'
                        >
                          Blog description
                        </label>
                        <div>
                          <section
                            className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                            onClick={handleViewHtml}
                          >
                            View HTML
                          </section>
                        </div>
                      </div>
                      <ReactQuill
                        modules={module}
                        ref={quillRef}
                        className={
                          err && secondDescription?.length === 0
                            ? 'border rounded-[10px] border-red-500 w-full h-auto'
                            : 'w-full h-auto'
                        }
                        theme='snow'
                        placeholder='Enter blog description'
                        value={secondDescription}
                        onChange={setSecondDescription}
                      />
                      {err && secondDescription?.length === 0 && (
                        <p className='text-red-500 text-xs'>
                          Please enter description for generate meta data
                        </p>
                      )}
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='Description'>
                          Blog Tags
                        </label>
                        <p className='text-gray-500 font-normal text-xs'>
                          Use <b className='text-black'>Enter</b> key to separate the tags
                        </p>
                      </div>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='blog_tags'
                        name='blog_tags'
                        value={blogTagsValue}
                        onChange={(e) => setBlogTagsValue(e.target.value)}
                        onKeyDown={(e) => (e.key === 'Enter' ? SaveblogTagsValue() : '')}
                        placeholder='e.g. Black'
                      />
                      <div className='flex mt-1 flex-wrap items-center'>
                        {blogTagsArray &&
                          blogTagsArray.map((elem, index) => {
                            return (
                              <span
                                key={index}
                                className='bg-[#f5f5f5] flex items-center me-2 mb-2 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded'
                              >
                                {elem}
                                <X
                                  className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                  onClick={() => RemoveTag(index)}
                                />
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <details open className='bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                    <summary
                      className='text-base flex items-center justify-between font-medium text-black'
                    >
                      <div>
                        <span>Media</span>
                        <p className='text-xs text-gray-500 font-normal'>Upload captivating image to make your category stand out.</p>
                      </div>
                      <span className="transition group-open:rotate-180">
                        <ChevronDown className="text-black w-4 h-5" />
                      </span>
                    </summary>
                    <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="blog" format="image" />
                  </details>
                  <details open className='bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]'>
                    <summary
                      className='text-base flex items-center justify-between font-medium text-black'
                    >
                      <div>
                        <span>Search engine listing</span>
                        <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                      </div>
                      <span className="transition group-open:rotate-180">
                        <ChevronDown className="text-black w-4 h-5" />
                      </span>
                    </summary>
                    <div className='mb-4'>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label htmlFor='Meta Tag Title' className='flex items-center justify-between'>
                            Meta title
                          </label>
                          {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'title');
                            }}
                          >
                            Get meta title
                          </span> */}
                        </div>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag Title'
                          name='meta_title'
                          value={meta_title}
                          onChange={(e) => {
                            setMeta({ ...meta, [e.target.name]: e.target.value });
                          }}
                          placeholder='Enter meta title'
                        />
                      </div>
                      <div className='mb-4'>
                        <div className='flex items-center justify-between'>
                          <label htmlFor='Meta Tag description' className='flex items-center justify-between'>
                            Meta description
                          </label>
                          {/* <span
                            className='inline-block cursor-pointer text-link underline align-baseline text-sm rounded-[10px] transition duration-300'
                            onClick={() => {
                              generateMetaData(description, 'description');
                            }}
                          >
                            Get meta description
                          </span> */}
                        </div>
                        <textarea
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='text'
                          id='Meta Tag description'
                          name='meta_description'
                          value={meta_description}
                          onChange={(e) => {
                            setMeta({ ...meta, [e.target.name]: e.target.value });
                          }}
                          placeholder='e.g. We have best fashionable products'
                        />
                      </div>
                      <div className='mb-4'>
                        <label htmlFor='sort_order'>
                          sort order
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                          type='number'
                          id='sort_order'
                          name='sort_order'
                          value={sortOrder}
                          onChange={(e) => { setSortOrder(e.target.value <= 0 ? 0 : e.target.value) }}
                          placeholder='Enter sort order'
                        />
                      </div>
                    </div>
                  </details>
                  <div className='flex items-center justify-between w-full'>
                    <Link
                      to={`/blog-post`}
                      className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                    >
                      <ArrowLeft className='w-3 mr-1' />
                      Go to blogs
                    </Link>
                    <button
                      className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                      type='button'
                      onClick={HandleUpdate}
                    >
                      Update Blog
                    </button>
                  </div>
                </>
            }
          </form>
        </div>
      </div>
      {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
          // onClose={() => {
          //   setHtmlPP(false);
          // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] h-96  mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X onClick={() => {
                    if (window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")) {
                      setHtml({ ...html, htmlPP: false })
                    }
                  }} />
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({ ...html, code: e.target.value })}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              </div>
              <button
                className='btn w-full text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

export default UpdateDummyBlog;
