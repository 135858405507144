import React, { useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { EyeOff, Eye } from 'feather-icons-react/build/IconComponents';
import Recaptcha from 'react-recaptcha';
import 'react-toastify/dist/ReactToastify.css';
import { SITE_LOGO } from '../../Assets/StaticData/StaticImage';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/Action/auth.action';
// import OneSignal from 'react-onesignal';
const Login = () => {
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      window.location.href = '/dashboard';
    }
  }, [])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // hook to Save Login Informations
  const [LoginData, setLoginData] = useState({ email: '', password: '' });
  const [eyeopen, setEyeopen] = useState(false);
  const { email, password } = LoginData;

  //   useEffect(() => {

  //     OneSignal.init({ appId: '7522f903-5744-4a3e-add5-ad313895d664' }).then( ()=>{

  //       setInitialized(true);
  // console.log("initialized")

  //        let data=OneSignal.getUserId()
  //       console.log(data);
  //     // }).catch(e=>console.log(e));
  //     // });
  //   });
  //         // Occurs when the user's subscription changes to a new value.
  //     // })
  //     },[]);
  // OneSignal.on('subscriptionChange', (isSubscribed) => {
  //   if (isSubscribed) {
  //     // User is subscribed to push notifications
  //     OneSignal.getUserId().then((playerId) => {
  //       console.log('Player ID:', playerId);
  //       // You can now use the 'playerId' to send notifications to this specific user.
  //     });
  //   } else {
  //     // User is not subscribed to push notifications
  //   }
  // });
  // get All Input Data In our UseState
  const HandleInput = (e) => {
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  // Generate PlayerId

  const HandleLogin = async (e) => {
    e.preventDefault()
    dispatch(login(email, password));
  };

  //captcha
  const [isVarified, setIsVarified] = useState(false);
  const RecaptchaLoaded = () => {
    // console.log("recaptcha loaded");
  };
  return (
    <>
      <div className='bg flex w-full h-full items-center justify-center'>
        <div className='w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-xl form'>
          {/* <div>
            <img className='mx-auto h-8 w-auto' 
            src={SITE_LOGO}
            alt='Your Company' />
            <h2 className='text-center text-xl mt-4 font-bold tracking-tight text-gray-900'>
              Login To Your Account
            </h2>
          </div> */}
          <div className='mb-2 text-center'>
            <img className="mx-auto h-6 sm:h-7 w-auto" src="https://cdn.shopeasy.ai/1/category/shopeasylogo.png" alt="Your Company" />
            <span className="mt-4 text-center block text-base sm:text-xl font-medium text-gray-900">
              Login To Your Account
            </span>
            <p className=" text-center text-xs sm:text-[13px] text-gray-500">Securely access your e-commerce super administration panel.</p>
          </div>
          <hr className="my-4 border-gray-300" />
          <form className='mt-8 space-y-6' method='POST'>
            <input type='hidden' name='remember' defaultValue='true' />
            <div className='space-y-4 rounded-md shadow-sm'>
              <div>
                <label htmlFor='email-address'>
                  Email address
                </label>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  value={email}
                  onChange={HandleInput}
                  required
                  className='relative block w-full pl-4 rounded-t-md border border-gray-300 outline-none focus:border-gray-400'
                  placeholder='Email address'
                />
              </div>
              <div>
                <label htmlFor='password'>
                  Password
                </label>
                <div className='relative'>
                  <input
                    id='password'
                    name='password'
                    type={eyeopen ? 'text' : 'password'}
                    value={password}
                    onChange={HandleInput}
                    required
                    className='relative block w-full pl-4 rounded-t-md border border-gray-300 outline-none focus:border-gray-400'
                    placeholder='Password'
                  />
                  {!eyeopen ? (
                    <Eye
                      className='absolute top-4 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  ) : (
                    <EyeOff
                      className='absolute top-2 right-2 w-4 h-4 text-slate-400 z-10'
                      onClick={() => {
                        setEyeopen(!eyeopen);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* <div className='flex items-center justify-between'>
              <div className='text-sm flex item-center justify-between'>
                <Link
                  to='/forgotpassword'
                  className='font-medium text-indigo-600 hover:text-indigo-500'
                >
                  Forgot your password?
                </Link>
              </div>
              <div className='text-center'>
                Not A Customer?
                <Link className='font-bold ml-2 text-indigo-600' to='/ClientRegister'>
                  Register
                </Link>
              </div>
            </div> */}
            <Recaptcha
              sitekey={process.env.REACT_APP_SITE_KEY}
              render='explicit'
              onloadCallback={RecaptchaLoaded}
              verifyCallback={(response) => (response ? setIsVarified(true) : setIsVarified(false))}
            />
            <div>
              {isVarified ? (
                <button
                  className='group relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  onClick={HandleLogin}
                >
                  Sign in
                </button>
              ) : (
                <button disabled={!isVarified} className='opacity-40 group  grayscale cursor-not-allowed relative flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                  Sign in
                </button>
              )}
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Login;
