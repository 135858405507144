 import {combineReducers} from "redux"
 import { paymentReducer } from "./Reducers/payment.reducer"
 import { systemStatusReducer } from "./Reducers/systemStatus.reducer"
 import { guidesReducer } from "./Reducers/guide.reducer"
 import { annauncementsReducer } from "./Reducers/annauncement.reducer"
 import { changelogReducer } from "./Reducers/changelog.reducer"
 import { tenantReducer } from "./Reducers/tenant.reducer"
 import { storeReducer } from "./Reducers/store.reducer"
 import {companyReducer} from './Reducers/company.reducer'
import { authReducer } from "./Reducers/auth.reducer"
import { dashboardReducer } from "./Reducers/dashboard.reducer"
import { pluginReducer } from "./Reducers/plugin.reducer"
import { dummyCategoryReducer } from "./Reducers/dummyCategory.reducer"
import { subscriptionReducer } from "./Reducers/subscription.reducer"
import { errorLogReducer } from "./Reducers/errorLog.reducer"
import { restrictedIPReducer } from "./Reducers/restrictedIP.reducer"
import { restrictedDomainReducer } from "./Reducers/restrcitedDomain.reducer"
import { websiteReducer } from "./Reducers/website.reducer"
import { customDomainReducer } from "./Reducers/customDomain.reducer"
 const rootReducer=combineReducers({
    paymentReducer,
    systemStatusReducer,
    changelogReducer,
    guidesReducer,
    annauncementsReducer,
    tenantReducer,
    storeReducer,
    companyReducer,
    authReducer,
    dashboardReducer,
    pluginReducer,
    dummyCategoryReducer,
    subscriptionReducer,
    errorLogReducer,
    restrictedIPReducer,
    restrictedDomainReducer,
    websiteReducer,
    customDomainReducer
 })
 export default rootReducer