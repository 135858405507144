export const GET_STTLED_PAYMENTS="GET_STTLED_PAYMENTS"
export const PAYMENT_TOBE_SETTLE='PAYMENT_TOBE_SETTLE'

// Status
export const GET_SYSTEM_STATUS='GET_SYSTEM_STATUS'

// Guide
export const GET_GUIDES="GET_GUIDES"
export const ADD_GUIDE_GUAIDENCE='ADD_GUIDE_GUAIDENCE'
export const DELETE_GUAIDE = 'DELETE_GUAIDE'
export const UPDATE_GUIDE = 'UPDATE_GUIDE'
export const GET_EXTENSION_NAME = 'GET_EXTENSION_NAME'
export const GET_PERTICULAR_DATA = 'GET_PERTICULAR_DATA'

// Guide Category
export const GET_GUAIDE_CATERORIES="GET_GUAIDE_CATERORIES"
export const ADD_GUAIDE_CATERORIES_GUAIDENCE = 'ADD_GUIDE_CATERORIES_GUAIDENCE'
export const UPDATE_GUAIDE_CATERORIES = 'UPDATE_GUIDE_CATERORIES'
export const DELETE_GUAIDE_CATERORIES = 'DELETE_GUAIDE_CATERORIES'
export const GET_PERTICULAR_CATERORIES_DATA = 'GET_PERTICULAR__CATERORIES_DATA'



// Annauncements
export const ADD_ANNAUNCEMENTS='ADD_ANNAUNCEMENTS'
export const GET_ANNAUNCEMENTS='GET_ANNAUNCEMENTS'

// Guide Dummy Category
export const ADD_DUMMY_CATEGORY = 'ADD_DUMMY_CATEGORY'

// Tenants
export const ADD_TENANTS='ADD_TENANTS'
export const GET_TENANTS='GET_TENANTS'
export const GET_PERTICULAR_TENANT='GET_PERTICULAR_TENANT'
export const GET_DELETE_REQUEST = "GET_DELETE_REQUEST"

//Changelog
export const GET_ALL_CHANGELOG_CATEGORIES='GET_ALL_CHANGELOG_CATEGORIES'
export const GET_CHANGELOG="GET_CHANGELOG"
export const GET_PERTICULAR_LOG='GET_PERTICULAR_LOG'

// Store Actions
export const GET_ALL_STORES = 'GET_ALL_STORES'
export const GET_STORE_CUSTOMERS = 'GET_STORE_CUSTOMERS'
export const GET_STORE_PRODUCTS = 'GET_STORE_PRODUCTS'
export const GET_STORE_ORDERS = 'GET_STORE_ORDERS'
export const GET_STORE_PLANS = 'GET_STORE_PLANS'
export const GET_STORE_CATEGORY = 'GET_STORE_CATEGORY'
export const ADD_STORE_CATEGORY = 'ADD_STORE_CATEGORY'

//company
export const GET_COMPANY_DATA = 'GET_COMPANY_DATA'
export const GET_PERTICULER_COMPANY_DATA = 'GET_PERTICULER_COMPANY_DATA'

// Auth
export const LOGIN = 'LOGIN'

//Dashboard
export const GET_LATEST_STORE = 'GET_LATEST_STORE'
export const GET_ACTIVE_PLAN = 'GET_ACTIVE_PLAN'
export const GET_PLAN_EXPIRED = 'GET_PLAN_EXPIRED'
export const GET_TOTAL_EXTENSION = 'GET_TOTAL_EXTENSION'

//Payment
export const VIEW_BANK_DETAIL = 'VIEW_BANK_DETAIL'

//Plugin
export const GET_SUPER_EXTENSION = 'GET_SUPER_EXTENSION'
export const GET_PARTICULAR_EXTENSION = 'GET_PARTICULAR_EXTENSION'
export const GET_EXTENSION_REVIEW = 'GET_EXTENSION_REVIEW'
export const GET_PARTICULAR_EXTENSION_REVIEW = 'GET_PARTICULAR_EXTENSION_REVIEW'

//Dummy Category
export const GET_DUMMY_CATEGORY_ALLPARENTS = 'GET_DUMMY_CATEGORY_ALLPARENTS'
export const GET_CATEGORY_PRODUCT = 'GET_CATEGORY_PRODUCT'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_DUMMY_CATEGORY = 'GET_DUMMY_CATEGORY'
export const GET_PARTICULAR_CATEGORY = 'GET_PARTICULAR_CATEGORY'

//Subscription
export const GET_PLAN_LIST = 'GET_PLAN_LIST'
export const GET_PURCHASE_PLAN = 'GET_PURCHASE_PLAN'
export const GET_PARTICULAR_PLAN = 'GET_PARTICULAR_PLAN'
export const GET_PLAN_DETAIL = 'GET_PLAN_DETAIL'

//ErrorLogs
export const GET_ERROR_LOG = 'GET_ERROR_LOG'

//Restricted IP
export const GET_RESTRITED_IP = 'GET_RESTRITED_IP'

//Restricted Domain
export const GET_RESTRITED_DOMAIN = 'GET_RESTRITED_DOMAIN'

//Websites
export const GET_WEBSITE_BLOG = 'GET_WEBSITE_BLOG'
export const GET_BLOG_CATEGORYS = 'GET_BLOG_CATEGORYS'
export const GET_PARTICULAR_BLOG = 'GET_PARTICULAR_BLOG'
export const GET_PARTICULAR_BLOG_CATEGORY = 'GET_PARTICULAR_BLOG_CATEGORY'
export const GET_JOBS = 'GET_JOBS'
export const GET_PARTICULAR_JOB = 'GET_PARTICULAR_JOB'
export const GET_TOOLS = 'GET_TOOLS'
export const GET_PARTICULAR_TOOL = 'GET_PARTICULAR_TOOL'
export const GET_THEME = 'GET_THEME'
export const GET_PARTICULAR_THEME = 'GET_PARTICULAR_THEME'
export const GET_DYNAMIC_PAGES = 'GET_DYNAMIC_PAGES'
export const GET_PARTICULAR_DYNAMIC_PAGE = 'GET_PARTICULAR_DYNAMIC_PAGE'

//Custom Domain
export const GET_CUSTOM_DOMAIN_DETAIL = 'GET_CUSTOM_DOMAIN_DETAIL'
export const CUSTOM_DOMAIN_ERROR = 'CUSTOM_DOMAIN_ERROR'


