import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewAnnauncements } from "../../Redux/Action/annauncement.action";

const ViewAnnouncement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const {getPerticularAnnauncementData} = useSelector((state)=>state.annauncementsReducer)
  useEffect(() => {
    dispatch(viewAnnauncements(params.annauncement_id));
  }, [params.annauncement_id]);
  return (
    <div className="w-full pb-[64px]">
      <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <h1 className="text-3xl font-medium text-center text-primary">
          View Announcement
        </h1>
        <button
          className="border py-2 px-5 rounded-md bg-gray-700 hover:bg-gray-800 text-white"
          type="submit"
          onClick={() => navigate("/announcement")}
        >
          My Announcements
        </button>
      </div>

      <div className="h-full w-full">
        <div className="bg-white shadow-sm rounded-[4px]">
          <div className="text-gray-700  flex items-start justify-start">
            <div className="grid md:grid-cols-2 w-full py-5 text-sm">
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Title :</div>
                <div className="px-4 py-2">
                  {getPerticularAnnauncementData?.announcement_title}
                </div>
              </div>
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Type :</div>
                <div className="px-4 py-2">
                  {getPerticularAnnauncementData?.announcement_type}
                </div>
              </div>
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Link :</div>
                <div className="px-4 py-2">{getPerticularAnnauncementData?.link}</div>
              </div>
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Tag :</div>
                <div className="px-4 py-2">{getPerticularAnnauncementData?.tag}</div>
              </div>

              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Short Description :</div>
                <div className="px-4 py-2">{getPerticularAnnauncementData?.short_description}</div>
              </div>
              <div></div>
              <div className="flex mb-2">
                <div className="px-4 py-2 font-semibold">Description:</div>
                <div className="px-4 py-2" dangerouslySetInnerHTML={{ __html: getPerticularAnnauncementData?.description}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAnnouncement;
