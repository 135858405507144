import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalStorePlan } from "../../Redux/Action/store.action";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import Breadcrumbs from "../../controllers/breadCrumbs";
import { ToastContainer } from "react-toastify";

const StorePlan = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const { allStorePlans } = useSelector(state => state.storeReducer)
  useEffect(() => {
    dispatch(getTotalStorePlan(params?.tenant_id));
  }, []);
  return (
    <div className="w-full">
      <div className="mb-4">
        <Breadcrumbs crumbs={[
          { name: 'Dashboard', path: `/dashboard` },
          { name: 'Stores', path: `/all-stores` },
          { name: 'Store plan', path: `/store-plan` },
        ]} />
        <h1 className='text-xl md:text-2xl font-semibold text-primary'>Store Plan</h1>
      </div>
      <div className="bg-white p-5 rounded-[4px]">
        <div className="text-gray-700  flex items-start justify-start">
          <div className="grid md:grid-cols-2 w-full text-sm">

            <div className="flex mb-2">
              <div className="py-2 font-semibold">Business Email : </div>
              <div className="py-2 ms-1">
                {allStorePlans?.business_email}
              </div>
            </div>
            <div className="flex mb-2">
              <div className="py-2 font-semibold">Plan Name : </div>
              <div className="py-2 ms-1">{allStorePlans?.plan_name}</div>
            </div>
            <div className="flex mb-2">
              <div className="py-2 font-semibold">Start Date : </div>
              <div className="py-2 ms-1"> {moment(allStorePlans?.start_date)
                .tz("Asia/Kolkata")
                .format("LL")}</div>
            </div>

            <div className="flex mb-2">
              <div className="py-2 font-semibold">End date : </div>
              <div className="py-2 ms-1">{moment(allStorePlans?.end_date)
                .tz("Asia/Kolkata")
                .format("LL")}</div>
            </div>


          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default StorePlan;
