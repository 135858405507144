import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { Edit, PlusCircle, Trash } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import API from '../../../API';
import { TrashFill } from 'react-bootstrap-icons';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import Notification from '../../Notification';

const DummyBannerGroupTable = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const navigate = useNavigate();
  const [groupBannerData, setGroupBannerData] = useState();
  const [pending, setPending] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([])
  const getBannerdata = async () => {
    setPending(true)
    try {
      const data = await API({ url: `/super/admin/dummydata/dynamicdummybanner/getBannerGroups`, method: 'get' });
      if (data.status === 200 || data.status === 304) {
        setGroupBannerData(data.data.data);
        setPending(false)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    getBannerdata();
  }, [])
  const handleDelete = async (id) => {
    if (window.confirm('Are You Sure To Remove This banner group?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dynamicdummybanner/deleteBannerGroup`, method: 'post', data: {
          id: [selectedRowsId?.length > 0 ? selectedRowsId : id]
        }});
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true)
          setNotificationMsg('Removed SuccesFully!')
          setSeverity('success')
          getBannerdata();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const columns = [
    {
      name: 'group name',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.banner_title }}></span>,
      sortable: true,
      minWidth: '300px'
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => <div className='space-x-2 w-full text-right'><button onClick={() => navigate(`/dummy-data/module-setting/banner-group/${row?._id}`)}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
        <button onClick={() => {
          handleDelete(row._id);
        }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
      </div>
    }
  ]
  // const ExpandedComponent = ({ data }) =>
  //   <div className="max-w-full m-3">
  //     <table className="leading-normal w-full border rounded border-gray-200">
  //       <thead>
  //         <tr className="bg-[#f5f5f5] text-gray-500 border-b border-gray-200 text-left">
  //           <th className="py-3 ps-2 min-w-[100px] xl:min-w-[200px] text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             title
  //           </th>
  //           <th className="py-3 min-w-[169px] xl:min-w-[200px] text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             image
  //           </th>
  //           <th className="py-3 min-w-[169px] xl:min-w-[150px] text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             button text
  //           </th>
  //           <th className="py-3 min-w-[169px] xl:min-w-[150px] text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             button link
  //           </th>
  //           <th className="py-3 pe-2 min-w-[100px] xl:min-w-[150px] text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             button color
  //           </th>
  //           <th className="py-3 pe-2 min-w-[100px] xl:min-w-[150px] text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
  //             button text color
  //           </th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {
  //           data &&
  //           data?.banners?.map((elem) => {
  //             return (
  //               <tr className='h-[55px]  border-b border-gray-200'>
  //                 <td className="ps-2 py-2 bg-white text-sm">
  //                   <p dangerouslySetInnerHTML={{ __html: elem.banner_text || "-" }} className='line-clamp-2'></p>
  //                 </td>
  //                 <td className=" py-2 bg-white text-sm">
  //                   <img src={elem?.banner_img} className='max-w-full max-h-[50px]' />
  //                 </td>
  //                 <td className=" py-2 bg-white text-sm">
  //                   <p className='line-clamp-2'>{elem?.banner_button_text || "-"}</p>
  //                 </td>
  //                 <td className=" py-2  bg-white text-sm">
  //                   <p className='line-clamp-2'>{elem?.banner_button_link || "-"}</p>
  //                 </td>
  //                 <td className=" py-2 bg-white text-sm text-right">
  //                   <p className='line-clamp-2'>{elem?.banner_button_color || "-"}</p>
  //                 </td>
  //                 <td className=" py-2 bg-white text-sm text-right pe-2">
  //                   <p className='line-clamp-2'>{elem?.banner_button_text_color || "-"}</p>
  //                 </td>
  //               </tr>
  //             )
  //           })
  //         }
  //       </tbody>
  //     </table>
  //   </div>
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-4'>
          <div>
            <Breadcrumbs />
            <h3 className='text-xl md:text-2xl font-medium text-primary'>Banner Group</h3>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
              onClick={() => {
                navigate('/dummy-data/module-setting/banner-group/add-banner-group');
              }}
            >
              <PlusCircle className="w-[18px] h-[18px] me-2" />
              Add New Banner Group
            </button>
          </div>
        </div>
        <div className='w-full space-y-1.5'>
          <div>
            <details className={(selectedRowsId?.length === 0) ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative` : `cursor-pointer group w-[200px] mb-1.5 relative`}>
              <summary className='flex bg-white border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                <span> Bulk Selection</span>
                <span className={(selectedRowsId?.length === 0) ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                  <svg
                    fill='none'
                    height={14}
                    shapeRendering='geometricPrecision'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    width={14}
                  >
                    <path d='M6 9l6 6 6-6' />
                  </svg>
                </span>
              </summary>
              <div className={(selectedRowsId?.length === 0) ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
                <button className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center' onClick={() => handleDelete()}>
                  <TrashFill className='w-3.5 h-3.5' />
                  <span>Delete selection</span>
                </button>
              </div>
            </details>
          </div>
          <div className='w-full inline-block border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden'>
            <DataTable
              columns={columns}
              data={groupBannerData}
              selectableRows
              // expandableRows
              // expandableRowsComponent={ExpandedComponent}
              onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
              selectableRowsHighlight
              highlightOnHover
              progressPending={pending}
              progressComponent={<Loader />}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DummyBannerGroupTable