import React, { useEffect, useRef } from 'react'
import Notification from '../../Notification'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import { useState } from 'react'
import { Edit, Move, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents'
import Loader from '../../Loader/Loader'
import DataTable from 'react-data-table-component'
import { Popover } from '@mui/material';
import API from '../../../API'
import { useNavigate, useParams } from 'react-router-dom'
import { TrashFill } from 'react-bootstrap-icons'
import { IconTab1, IconTab2, IconTab3 } from '../../../Assets/StaticData/ReassuranceIcon'
import ImageUploader from '../../../controllers/ImageUploader'

const UpdateDummyReassuranceGroup = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const params = useParams()
  const navigate = useNavigate();
  const [dataErr, setDataErr] = useState(false);
  const [groupTitle, setGroupTitle] = useState('');
  const [storeReassurance, setStoreReassurance] = useState([])
  const [addReassurance, setAddReassurance] = useState(false);
  const [image, setimage] = useState();
  const [groupStatus, setGroupStatus] = useState(true)
  const [reassuranceStatus, setReassuranceStatus] = useState(false)
  const [reassuranceData, setReassuranceData] = useState({
    reassurance_title: '',
    reassurance_description: '',
    reassurance_redirect_link: '',
  })
  const [iconDD, setIconDD] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [openTab, setOpenTab] = useState(1)
  const [icon, seticon] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [EditId, setEditId] = useState()
  const [selectedRowsId, setSelectedRowsId] = useState([])
  let enabledReassurances = storeReassurance?.length && storeReassurance?.filter(reassurance => reassurance?.reassurance_status);
  const getPerticulerGroupData = async () => {
    try {
      const apicall = await API({ url: `/super/admin/dummydata/dynamicreassurance/getReassuranceGroup/${params?.groupId}`, method: 'get' });
      setGroupTitle(apicall?.data?.data?.reassurance_title)
      setGroupStatus(apicall?.data?.data?.status)
      setStoreReassurance(apicall?.data?.data?.reassurances)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  useEffect(() => {
    getPerticulerGroupData();
  }, [])
  const handleSetImage = (value) => {
    setimage(value)
}
  const handleAddReassurance = async (e) => {
    e.preventDefault();

    if (enabledReassurances?.length > 4 && reassuranceStatus) {
      setIsNotification(true)
      setNotificationMsg("you can enable only 4 reasurance")
      setSeverity("error")
    } else {
      if (isEdit) {
        try {
          await API({ url: `/super/admin/dummydata/dynamicreassurance/updateReassurance/${EditId}`, method: 'put', data: {
            ...reassuranceData,
            reassurance_img: icon?.length > 0 ? icon : image,
            reassurance_status: reassuranceStatus
          }})
          setIsEdit(false);
          getPerticulerGroupData();
          setAddReassurance(false);
          setReassuranceData({
            reassurance_title: '',
            reassurance_description: '',
            reassurance_redirect_link: '',
          })
          setimage();
          seticon('')
          setReassuranceStatus(false)
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity("error")
        }
      } else {
        try {
          await API({ url: `/super/admin/dummydata/dynamicreassurance/addReassurance`, method: 'post', data: {
            reassurance_group_id: params?.groupId,
            reassurance: { ...reassuranceData, reassurance_img: icon?.length > 0 ? icon : image, reassurance_status: reassuranceStatus }
          }})
          getPerticulerGroupData();
          setAddReassurance(false)
          setReassuranceData({
            reassurance_title: '',
            reassurance_description: '',
            reassurance_redirect_link: '',
          })
          setimage();
          seticon('')
          setReassuranceStatus(false)
        } catch (error) {
          setIsNotification(true)
          setNotificationMsg(error)
          setSeverity("error")
        }
      }
    }
  }
  const [err, setErr] = useState(false)
  const handleEditReassuranceGroup = async (e) => {
    e.preventDefault();
    if (groupTitle?.length === 0) {
      setErr(true)
    } else {
      setErr(false)
      try {
        await API({ url: `/super/admin/dummydata/dynamicreassurance/updateReassuranceGroup/${params?.groupId}`, method: 'put', data: {
          reassurance_title: groupTitle,
          status: groupStatus,
        }})
        navigate('/dummy-data/module-setting/reassurance-group')
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  }
  const handleEditReassurance = (e, elem) => {
    e.preventDefault();
    setAddReassurance(true)
    setIsEdit(true);
    setEditId(elem?._id)
    setReassuranceData({
      reassurance_title: elem?.reassurance_title,
      reassurance_description: elem?.reassurance_description,
      reassurance_redirect_link: elem?.reassurance_redirect_link,
    })
    if (elem?.reassurance_img?.includes('<svg')) {
      seticon(elem?.reassurance_img)
    } else {
      setimage(elem?.reassurance_img)
    }
    setReassuranceStatus(elem?.reassurance_status);
  }
  const UpdateReassuranceStatus = async (id, value) => {
    if (enabledReassurances?.length === 4 && value) {
      setIsNotification(true)
      setNotificationMsg("you can enable only 4 reassurance")
      setSeverity("error")
    } else {
      try {
        const data = await API({ url: `/super/admin/dummydata/dynamicreassurance/updateReassurance/${id}`, method: 'put', data: {
          reassurance_status: value,
        }});
        if (data.status === 200 || data.status === 304) {
          getPerticulerGroupData()
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  }
  const sortReassurance = async (index, id) => {
    try {
      await API({ url: `/super/admin/dummydata/dynamicreassurance/updateReassurance/${id}`, method: 'put', data: {
        newIndex: index === 0 ? 0 : Number(index),
      }});
      getPerticulerGroupData();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...storeReassurance];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortReassurance(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { storeReassurance: _sort };
    setStoreReassurance(FinalArray);
  };
  const handleDelete = async (e, id) => {
    e.preventDefault();
    if (window.confirm('Are You Sure To Remove This Reassurance?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dynamicreassurance/deleteReassurance`, method: 'post', data: {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
        }});
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true)
          setNotificationMsg('Reassurance Removed SuccesFully!')
          setSeverity('success')
          getPerticulerGroupData();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  const columns = [
    {
      name: '',
      width: '30px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    // {
    //   name: 'Image',
    //   width: '150px',
    //   cell: (row) => row?.reassurance_img?.includes("<svg") ? <div dangerouslySetInnerHTML={{ __html: row.reassurance_img }} className='inline-block max-h-full mx-auto'></div> : <img width={80} height={50} src={row.reassurance_img} className='p-1 max-w-full object-contain max-h-full' />,
    // },
    {
      name: 'Reassurance title',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.reassurance_title }}></span>,
      minWidth: '250px'
    },
    {
      name: 'redirect url',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.reassurance_redirect_link }}></span>,
      minWidth: '250px'
    },
    {
      name: 'Status',
      width: '100px',
      cell: row => <input
        className="mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
        type='checkbox'
        role='switch'
        id='flexSwitchCheckDefault'
        checked={row?.reassurance_status}
        name='slider'
        onChange={() => {
          UpdateReassuranceStatus(row?._id, !row.reassurance_status)
        }}
      />,
    },
    {
      name: <span className='w-full text-right'>Action</span>,
      width: '100px',
      cell: (row) => <div className='space-x-2 w-full text-right'><button onClick={(e) => handleEditReassurance(e, row)}><Edit className='w-[17px] h-[17px] text-blue-500' /></button>
        <button onClick={(e) => {
          handleDelete(e, row?._id);
        }}><Trash className='w-[17px] h-[17px] text-red-500' /></button>
      </div>
    }
  ]
  const [msg, setMsg] = useState(false)
  useEffect(() => {
    if (enabledReassurances?.length > 4) {
      setMsg(true)
    } else {
      setMsg(false)
    }
  }, [storeReassurance])
  useEffect(() => {
    if (enabledReassurances?.length > 4) {
      setIsNotification(true)
      setNotificationMsg("you can enable only 4 reassurance")
      setSeverity("error")
    }
  }, [msg])

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Edit Reassurance Group</h3>
        </div>
        <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
          <div className='bg-white w-full border border-gray-300  space-y-4 rounded-xl p-[25px]'>
            <div className='mb-4'>
              <label htmlFor='image'>
                Reassurance Group Title
              </label>
              <input type='text' className={`border w-full outline-none rounded-[10px] border-gray-300 px-4 py-2 text-sm ${err ? 'border-red-500' : ''}`} placeholder='Reassurance group title' value={groupTitle} onChange={(e) => {setGroupTitle(e.target.value);setErr(false)}} />
              {
                err && groupTitle?.length === 0 && <p className='text-xs text-red-500'>Please enter group name</p>
              }
            </div>
            <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
              <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                Reassurance group status -
              </p>
              <input
                className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={groupStatus}
                name='sliderbutton'
                onChange={() => setGroupStatus(!groupStatus)}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <div className='flex items-end justify-between'>
              <details
                className={
                  selectedRowsId?.length === 0
                    ? `opacity-60 cursor-not-allowed group w-[200px] relative`
                    : `cursor-pointer group w-[200px] relative`
                }
              >
                <summary className='flex bg-white  border border-gray-300 px-2 py-2 text-sm rounded-[4px] justify-between items-center list-none'>
                  <span> Bulk Selection</span>
                  <span
                    className={
                      selectedRowsId?.length === 0
                        ? 'transition group-open:rotate-0'
                        : 'transition group-open:rotate-180'
                    }
                  >
                    <svg
                      fill='none'
                      height={14}
                      shapeRendering='geometricPrecision'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1.5'
                      viewBox='0 0 24 24'
                      width={14}
                    >
                      <path d='M6 9l6 6 6-6' />
                    </svg>
                  </span>
                </summary>
                <div
                  className={
                    selectedRowsId?.length === 0
                      ? `hidden`
                      : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                  }
                >
                  <button
                    className='text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center'
                    onClick={(e) => { handleDelete(e) }}
                  >
                    <TrashFill className='w-3.5 h-3.5' />
                    <span>Delete selection</span>
                  </button>
                </div>
              </details>
              <button className='text-link inline-flex items-center  mb-0 underline text-right font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddReassurance(true) }}><PlusCircle className="w-4 me-2 h-4" />Add Reassurance</button>
            </div>
            <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
              <DataTable
                columns={columns}
                data={storeReassurance}
                selectableRows
                onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                selectableRowsHighlight
                highlightOnHover
                progressComponent={<Loader />}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='btn text-sm text-white' onClick={(e) => handleEditReassuranceGroup(e)}>
              Edit Reassurance Group
            </button>
          </div>
        </form>
        {
          addReassurance &&
          <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
            <Popover
              open={addReassurance}
              className="h-screen popup-content"
              onClose={() => {
                setAddReassurance(false);
                setReassuranceData({
                  reassurance_title: '',
                  reassurance_description: '',
                  reassurance_redirect_link: '',
                })
                setimage();
                seticon('')
                setReassuranceStatus(false)
              }}
            >
              <form className='bg-white w-[700px] max-w-[600px] lg:max-w-full border border-gray-300  space-y-4 rounded p-5'>
                <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                  <h3 className='font-medium text-xl'>
                    {isEdit ? "Edit Reassurance" : "Add Reassurance"}
                  </h3>
                  <button onClick={(e) => {
                    e.preventDefault(); setAddReassurance(false); setReassuranceData({
                      reassurance_title: '',
                      reassurance_description: '',
                      reassurance_redirect_link: '',
                    })
                    setimage();
                    seticon('')
                    setReassuranceStatus(false)
                  }}><X className="w-5 h-4" /></button>
                </div>
                <div className='mb-4'>
                  <div className='flex items-center justify-between'>
                    <label htmlFor='image'>
                      Reassurance image
                    </label>
                    <button
                      aria-describedby='iconDD'
                      variant="contained"
                      className='text-link text-sm hover:underline'
                      onClick={(e) => {
                        { e.preventDefault(); setAnchorEl(e.currentTarget); setIconDD(true) }
                      }}
                    >
                      Select icon
                    </button>
                  </div>
                  <form>
                    <label className={`block border border-gray-300 p-2 hover:bg-gray-50 border-dashed w-full rounded hover:border-gray-400 cursor-pointer text-gray-900 outline-none text-sm ${dataErr && !image ? 'border-red-500' : ''}`}>
                      {
                        icon?.length ?
                          <div className='text-center'>
                            <div className='mx-auto inline-block' dangerouslySetInnerHTML={{ __html: icon }}></div>
                            <br></br>
                            <button onClick={(e) => { e.preventDefault(); seticon('') }} className='text-link text-sm font-normal hover:underline'>Remove icon</button>
                          </div> :
                           <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="reassurance" format="image" />
                      }
                    </label>
                    {
                      dataErr ? <p className='text-red-500 text-xs'>Please enter image</p> : <></>
                    }
                  </form>
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance redirect link
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_redirect_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_redirect_link: e.target.value })} />
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance title
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_title} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. Free Shipping World Wide' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_title: e.target.value })} />
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>
                    Reassurance description
                  </label>
                  <input name='slider_button_link' value={reassuranceData?.reassurance_description} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='e.g. Free Shipping World Wide' onChange={(e) => setReassuranceData({ ...reassuranceData, reassurance_description: e.target.value })} />
                </div>
                {/* <div className='border w-full border-gray-300 flex items-center space-x-2 rounded px-4 py-2 text-sm'>
                  <p className='text-[13px] font-[500] text-[#484848] capitalize'>
                    Reassurance status -
                  </p>
                  <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={reassuranceStatus}
                    name='sliderbutton'
                    onChange={() => setReassuranceStatus(!reassuranceStatus)}
                  />
                </div> */}
                <button className='btn inline-block text-sm text-white' onClick={(e) => handleAddReassurance(e)}>
                  {isEdit ? "Edit Reassurance" : "Add Reassurance"}
                </button>
              </form>
              {
                iconDD &&
                <Popover
                  id={'iconDD'}
                  open={iconDD}
                  anchorEl={anchorEl}
                  onClose={() => setIconDD(!iconDD)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className="h-auto w-96 max-w-screen bg-white  border-gray-600  z-50 rounded-[4px]">
                    <ul
                      className='flex items-center mb-0 list-none overflow-x-auto flex-row'
                      role='tablist'
                    >
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 1
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(1);
                          }}
                          data-toggle='tab'
                          href='#link1'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab1?.title}</div>
                        </a>
                      </li>
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 2
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(2);
                          }}
                          data-toggle='tab'
                          href='#link2'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab2?.title}</div>
                        </a>
                      </li>
                      <li className='text-center lg:mb-0'>
                        <a
                          className={
                            'text-xs max-h-[50px] font-bold break-keep uppercase block leading-normal transition-all duration-150 ' +
                            (openTab === 3
                              ? 'text-black border-b-2 border-b-[#6a63fb]'
                              : 'text-gray-600 bg-white border-b-2 border-white')
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(3);
                          }}
                          data-toggle='tab'
                          href='#link3'
                          role='tablist'
                        >
                          <div className='max-h-[50px] m-3'>{IconTab3?.title}</div>
                        </a>
                      </li>
                    </ul>
                    {
                      openTab === 1 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab1?.icons?.map((elem) => {
                            return (
                              <div className={`text-center cursor-pointer ${elem === icon ? 'bg-gray-100 rounded' : ''}`}>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    {
                      openTab === 2 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab2?.icons?.map((elem) => {
                            return (
                              <div className='text-center cursor-pointer'>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    {
                      openTab === 3 &&
                      <div className='grid grid-cols-3 mt-4 gap-4'>
                        {
                          IconTab3?.icons?.map((elem) => {
                            return (
                              <div className='text-center cursor-pointer'>
                                <div dangerouslySetInnerHTML={{ __html: elem }} onClick={(e) => { seticon(elem); setDataErr(false); setIconDD(false) }} className='inline-block mx-auto'></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                    <div className='border-t p-4 text-center'>
                      <button className='text-link' onClick={(e) => { seticon(''); setDataErr(false); setIconDD(false) }}>Select none</button>
                    </div>
                  </div>
                </Popover>
              }
            </Popover>
          </div>
        }
      </div>
    </>
  )
}

export default UpdateDummyReassuranceGroup