import React, { useState } from 'react'
import Breadcrumbs from '../../../controllers/breadCrumbs'
import API from '../../../API';
import { Popover } from '@mui/material';
import { PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import Notification from '../../Notification';

const CreateDummyManufacturerGroup = () => {
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }
  const navigate = useNavigate();
  const [brandGroupTitle, setBrandGroupTitle] = useState('')
  const [brandData, setBrandData] = useState({
    brand_limit: '5',
    carousel_status: true,
    status: true,
    brand_name_display: true,
    brand_title_display: true,
    brand_image_width: '',
    brand_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '/manufacturers'
  })
  const [addBrand, setAddBrand] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddBrand = () => {
    setAddBrand(false)
  }
  const [selectedBrand, setSelectedBrand] = useState([])
  const handleSelectBrand = (brand, name, logo) => {
    if (brand) {
      const existBrand = selectedBrand.find((x) => x?.brand === brand);
      if (!existBrand) {
        setSelectedBrand([...selectedBrand, { brand, name, logo }]);
      } else {
        const removeSelected = selectedBrand.filter((x) => x?.brand !== brand)
        setSelectedBrand(removeSelected);
      }
    }
  }
  const [allBrands, setAllBrands] = useState([])
  const [search, setSearch] = useState('')
  const GetManufactures = async () => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummybrand/GetManufacturerWithProductCount`, method: 'get', params: { searchkey: search }});
      if (apiCall.status === 200 || apiCall.status === 304) {
        setAllBrands(apiCall?.data?.data?.records)
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addBrand === true && (search?.length === 0 || search?.length >= 3)) {
      GetManufactures();
    }
  }, [addBrand, search])
  const handleAddBrandGroup = async (e) => {
    e.preventDefault();
    if (brandGroupTitle?.length === 0) {
      setDataErr(true)
    } else {
      setDataErr(false)
      try {
        let body = {
          brand_section_title: brandGroupTitle,
          button_text: brandData?.button_text,
          button_link: brandData?.button_link,
          brand_limit: brandData?.brand_limit,
          carousel_status: brandData?.carousel_status,
          status: brandData?.status,
          brand_name_display: brandData?.brand_name_display,
          brand_title_display: brandData?.brand_title_display,
          carousel_speed_ms: brandData?.carousel_speed_ms,
          loop_forever: brandData?.loop_forever,
          auto_play: brandData?.auto_play,
          pause_on_hover: !brandData?.auto_play ? false : brandData?.pause_on_hover,
          brands: selectedBrand
        }
        if (brandData?.brand_image_height?.length > 0 || brandData?.brand_image_width?.length > 0) {
          body = {
            ...body,
            brand_image_width: brandData?.brand_image_width,
            brand_image_height: brandData?.brand_image_height,
          }
        }
        await API({ url: `/super/admin/dummydata/dynamicdummybrand/addBrandGroup`, method: 'post', data: body})
        navigate('/dummy-data/module-setting/manufacture-group')
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity('error')
      }
    }
  }
  const columns = [
    {
      name: 'Image',
      width: '150px',
      cell: (row) => <img width={80} height={50} src={row.logo} alt={row?.logo} className='p-1 max-w-full max-h-full' />,
    },
    {
      name: 'manufacture name',
      cell: (row) => <span className='line-clamp-2' dangerouslySetInnerHTML={{ __html: row.name }}></span>,
      minWidth: '250px'
    },
  ]

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='w-full'>
          <Breadcrumbs />
          <h3 className='text-xl md:text-2xl font-medium text-primary  pb-4'>Add New Manufacture Group</h3>
        </div>
        <form className='w-[90%] space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'>
          <div className='bg-white w-full border  border-gray-300  space-y-4 rounded-xl p-[25px]'>
            <div className='w-full mb-4'>
                <label htmlFor='group title'>
                  Manufacture group title
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                  type='text'
                  id='group title'
                  name='group title'
                  value={brandGroupTitle}
                  onChange={(e)=>setBrandGroupTitle(e.target.value)}
                  placeholder='Enter title....'
                />
              {
                dataErr && brandGroupTitle?.length === 0 && <p className='text-xs text-red-500'>Please enter group title</p>
              }
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full'>
              <div className='mb-4 flex items-center'>
                <label>Manufacture Group Status</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.status}
                    name='status'
                    onChange={(e) => {
                      setBrandData({ ...brandData, status: !brandData?.status })
                    }}
                />
              </div>
              <div className='w-full'>
                <label>Manufacture Limit</label>
                <div className='relative'>
                  <select className='w-full' value={brandData?.brand_limit} onChange={(e) => setBrandData({ ...brandData, brand_limit: e.target.value })}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center'>
                <label>manufacture name display</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.brand_name_display}
                    name='status'
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_name_display: !brandData?.brand_name_display })
                    }}
                />
              </div>
              <div className='mb-4 flex items-center'>
                <label>manufacture title display</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.brand_title_display}
                    name='brand_title_display'
                    onChange={(e) => {
                      setBrandData({ ...brandData, brand_title_display: !brandData?.brand_title_display })
                    }}
                />
              </div>
              <div className='mb-4 flex items-center'>
                <label>carousel status</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.carousel_status}
                    name='carousel_status'
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_status: !brandData?.carousel_status })
                    }}
                />
              </div>
              <div className='w-full'>
                <label>carousel speed ms</label>
                <div className='relative'>
                  <input value={brandData?.carousel_speed_ms} disabled={brandData?.carousel_status === 'disabled'} onChange={(e) => setBrandData({ ...brandData, carousel_speed_ms: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 5000' />
                </div>
              </div>
              <div className='mb-4 flex items-center'>
                <label>Carousel Loop</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.loop_forever}
                    name='loop_forever'
                    onChange={(e) => {
                      setBrandData({ ...brandData, loop_forever: !brandData?.loop_forever })
                    }}
                />
              </div>
              <div className='mb-4 flex items-center'>
                <label>Autoplay</label>
                <input
                    className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    checked={brandData?.auto_play}
                    name='auto_play'
                    onChange={(e) => {
                      setBrandData({ ...brandData, auto_play: !brandData?.auto_play })
                    }}
                />
              </div>
              {
                brandData?.auto_play &&                 
                <div className='mb-4 flex items-center'>
                  <label>pause on hover</label>
                  <input
                      className="relative mx-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                      type='checkbox'
                      role='switch'
                      id='flexSwitchCheckDefault'
                      checked={brandData?.pause_on_hover}
                      name='pause_on_hover'
                      onChange={(e) => {
                        setBrandData({ ...brandData, pause_on_hover: !brandData?.pause_on_hover })
                      }}
                  />
                </div>
              }
              <div className='w-full'>
                <label htmlFor='image'>
                  button text
                </label>
                <input value={brandData?.button_text} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' onChange={(e) => setBrandData({ ...brandData, button_text: e.target.value })} placeholder='e.g. Explore All' />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>
                  button link
                </label>
                <input value={brandData?.button_link} className='w-full border border-gray-300 px-4 py-2 text-sm outline-none focus:border-gray-400 rounded' placeholder='Add link' onChange={(e) => setBrandData({ ...brandData, button_link: e.target.value })} />
              </div>
              <div className='w-full'>
                <label>manufacture image width</label>
                <div className='relative'>
                  <input value={brandData?.brand_image_width} onChange={(e) => setBrandData({ ...brandData, brand_image_width: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 100' />
                </div>
              </div>
              <div className='w-full'>
                <label>manufacture image height</label>
                <div className='relative'>
                  <input value={brandData?.brand_image_height} onChange={(e) => setBrandData({ ...brandData, brand_image_height: e.target.value })} className='w-full border border-gray-300 px-4 py-2 rounded text-sm outline-none focus:border-gray-500' placeholder='e.g. 150' />
                </div>
              </div>
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='text-link underline w-full text-right flex items-center justify-end font-medium text-sm text-white' onClick={(e) => { e.preventDefault(); setAddBrand(true) }}><PlusCircle className="w-4 me-2 h-4" />Add Manufacture</button>
            <div className='w-full inline-block border border-gray-300 rounded-[4px] overflow-x-auto overflow-y-hidden'>
              <DataTable
                columns={columns}
                data={!addBrand && selectedBrand}
                selectableRowsHighlight
                highlightOnHover
                progressComponent={<Loader />}
              />
            </div>
            <hr className='border-t border-gray-200 my-4'></hr>
            <button className='btn text-sm text-white' onClick={(e) => handleAddBrandGroup(e)}>Add Manufacture group</button>
          </div>
        </form>
        {
          addBrand ?
            <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
              <Popover
                open={addBrand}
                className="h-screen popup-content"
                onClose={() => {
                  setAddBrand(false);
                }}
              >
                <div className='bg-white w-full lg:w-[600px] border border-gray-300  space-y-4 rounded p-5'>
                  <div className='flex border-b border-gray-300 pb-3 items-center justify-between'>
                    <h3 className='font-medium text-xl'>Select manufactures</h3>
                    <button onClick={(e) => { e.preventDefault(); setAddBrand(false) }}><X className="w-5 h-4" /></button>
                  </div>
                  <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full border border-gray-300 px-4 py-2 rounded outline-none focus:border-gray-500 text-sm' placeholder='Search manufacture..' />
                  <div className='h-[450px] overflow-y-auto divide-y'>
                    {
                      allBrands?.map((elem) => {
                        return (
                          <label className='flex py-2 items-start justify-between'>
                            <div className='flex'>
                              <input type='checkbox' checked={selectedBrand?.some((x) => x.brand === elem?._id)} value={elem?._id} onClick={(e) => handleSelectBrand(elem?._id, elem?.brand_name, elem?.brand_logo)} className='block mt-1 w-4 h-4 me-4' />
                              <div className='w-[60px] h-[60px] p-1 me-2 border border-gray-200 flex items-center justify-center'>
                                <img src={elem?.brand_logo} alt={elem?.brand_logo} className='max-w-full max-h-full' />
                              </div>
                              <p className='text-base break-all'>{elem?.brand_name}</p>
                            </div>
                            <p className='me-2 text-base text-black'>({elem?.product_count})</p>
                          </label>
                        )
                      })
                    }
                  </div>
                  <button className='btn text-sm text-white' onClick={() => handleAddBrand()}>add manufacture</button>
                </div>
              </Popover>
            </div> : <></>
        }
      </div>
    </>
  )
}

export default CreateDummyManufacturerGroup