import React, { useState } from 'react'
import TotalCustomers from './TotalCustomers'
import TotalProducts from './TotalProducts'
import TotalOrders from './TotalOrders'
import StorePlan from './StorePlan'

const PerticularStore = ({ openTab }) => {

  return (
    <>
      <div className="w-full overflow-hidden pb-[64px]">
        {
          openTab === 1 &&
          <><TotalCustomers /></>
        }
        {
          openTab === 2 &&
          <TotalProducts />
        }
        {
          openTab === 3 &&
          <TotalOrders />
        }
        {
          openTab === 4 &&
          <StorePlan />
        }
      </div>
    </>
  )
}

export default PerticularStore