import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import API from '../../API';
import { useDispatch, useSelector } from 'react-redux';
import { getPruchasePlanDetail } from '../../Redux/Action/subscription.action';
import { ToastContainer } from 'react-toastify';

const ViewSubscription = () => {
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const { planDetail } = useSelector((state) => state.subscriptionReducer);
    const location = useLocation()

    const [viewSubscriptionInfo, setViewSubscriptionInfo] = useState()

    const GetViewSubscription = async () => {
        try {
            dispatch(getPruchasePlanDetail(location?.state._id));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        GetViewSubscription();
    }, []);

    useEffect(() => {
        if(planDetail) {
            setViewSubscriptionInfo(planDetail);
        }
    },[planDetail])

    return (
        <>
            <h1 className='text-2xl font-bold p-2'>Subscription Information</h1>
            <h1 className='text-2xl font-bold p-2'>Active Plan</h1>
            {viewSubscriptionInfo?.data?.records.map((elem) => {
                return (
                    <>{elem.active_plan === true ?
                        <div className='max-w-[50%] mx-auto bg-white p-3 shadow-sm rounded'>
                            <div className='flex items-center justify-between text-2xl space-x-2 font-semibold text-gray-900 leading-8'>
                                <div className='text-gray-700 flex items-start '>
                                    <div className='grid md:grid-cols-2 text-sm'>
                                        <div className='grid grid-cols-2 mb-2'>
                                            <div className='px-4 py-2 font-semibold'>Email:</div>
                                            <div className='px-4 py-2'>{elem.tenant_email}</div>
                                        </div>
                                        <div className='grid grid-cols-2 mb-2'>
                                            <div className='px-4 py-2 font-semibold'>Purchase Plan Name:</div>
                                            <div className='px-4 py-2'>{elem.plan_name}</div>
                                        </div>
                                        <div className='grid grid-cols-2 mb-2'>
                                            <div className='px-4 py-2 font-semibold'>Start Date:</div>
                                            <div className='px-4 py-2'>{moment(elem.start_date).tz('Asia/Kolkata').format('DD-MM-YYYY')}</div>
                                        </div>
                                        <div className='grid grid-cols-2 mb-2'>
                                            <div className='px-4 py-2 font-semibold'>End Date:</div>
                                            <div className='px-4 py-2'>{moment(elem.end_date).tz('Asia/Kolkata').format('DD-MM-YYYY')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>}
                    </>
                )
            })
            }
            <h1 className='text-2xl font-bold p-2'>History</h1>
            <table className="w-full  border border-slate-300 text-2xl">
                <thead>
                    <tr className="bg-gray-50 border-b border-[#a0d0eb] text-gray-500">
                        <th className="cursor-pointer text-sm font-semibold">Purchase Plan</th>
                        <th className="cursor-pointer text-sm font-semibold">Start Date</th>
                        <th className="cursor-pointer text-sm font-semibold">End Date</th>
                    </tr>
                </thead>
                <tbody>
                    {viewSubscriptionInfo?.data?.records.map((elem) => {
                        return (
                            <>

                                <tr
                                    className="bg-white text-center border-b border-slate-200 text-sm text-gray-600"
                                >
                                    <td className="p-2">
                                        {elem.plan_name}
                                    </td>
                                    <td className="p-2">
                                        {moment(elem.start_date).tz('Asia/Kolkata').format('DD-MM-YYYY')}
                                    </td>
                                    <td className="p-2">
                                        {moment(elem.end_date).tz('Asia/Kolkata').format('DD-MM-YYYY')}
                                    </td>

                                </tr>
                            </>
                        )
                    })
                    }
                </tbody>
            </table>
            <ToastContainer />
        </>
    )
}

export default ViewSubscription;