import React, { useState, useEffect, useRef } from 'react';
import API from '../../../API';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown } from 'feather-icons-react/build/IconComponents';
import ReactQuill from 'react-quill';
import module, { formatCode } from '../../../controllers/QuillToolbar';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import {
  Skeleton,
} from '@mui/material';
import ImageUploader from '../../../controllers/ImageUploader';
const UpdateDummyManufacturer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [mData, setmData] = useState({ brand_name: '', meta_title: "", meta_description: '', });
  const [sortOrder, setSortOrder] = useState('');
  const [description, setDescription] = useState();
  const [image, setimage] = useState();
  const [slugName, setSlugName] = useState()
  const [brandData, setBrandData] = useState()
  const [formSpinner, setFormSpinner] = useState(true)

  const getManufacturerBySlugname = async () => {
    setFormSpinner(true)
    try {
      const ApiCall = await API({ url: `/super/admin/dummydata/dummybrand/getDummyManufacturer/${params?.brand_slug_name}`, method: 'get' })
      setBrandData(ApiCall?.data)
      setmData({
        brand_name: `${ApiCall?.data?.data?.brand_name}`,
        meta_title: ApiCall?.data?.data?.meta_title ? `${ApiCall?.data?.data?.meta_title}` : '',
        meta_description: ApiCall?.data?.data?.meta_description ? `${ApiCall?.data?.data?.meta_description}` : '',
      })
      setSortOrder(`${ApiCall?.data?.data?.sort_order}`)
      setDescription(ApiCall?.data?.data?.description)
      setimage(`${ApiCall?.data?.data?.brand_logo}`)
      setSlugName(ApiCall?.data?.brand_slug_name ? ApiCall?.data?.brand_slug_name : ApiCall?.data?.data?.brand_name)
      setFormSpinner(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getManufacturerBySlugname()
  }, [])
  const handleSetImage = (value) => {
    setimage(value)
  }
  const HandleInput = (e) => {
    setmData({ ...mData, [e.target.name]: e.target.value });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await API({ url: `/super/admin/dummydata/dummybrand/updateDummyManufacturer/${brandData?.data?._id}`, method: 'put', data: {
        brand_name: mData?.brand_name,
        brand_logo: image,
        meta_title: mData?.meta_title,
        meta_description: mData?.meta_description,
        brand_slug_name: slugName,
        description: description,
        sort_order: sortOrder,
      }});
      if (data.status === 200 || data.status === 304) {
        navigate('/dummy-data/dummy-Manufacturer');
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}

      <div className='h-auto overflow-y-auto pb-[64px]'>
        <Breadcrumbs />
        <h1 className='text-xl md:text-2xl font-medium pb-4 text-primary'>Update manufacturer - {brandData?.data?.brand_name}</h1>
        <div className='flex justify-evenly'>
          <form
            method='POST'
            className='space-y-4 w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {
              formSpinner ?
                <div className='space-y-4'>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <div className='flex items-center space-x-4 w-full'>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded' />
                      <Skeleton className='w-full min-h-[200px]' variant='rounded' />
                    </div>
                  </div>
                  <div className='bg-white shadow space-y-4 rounded-xl p-[25px]'>
                    <Skeleton className='w-full min-h-[100px]' variant='rounded' />
                  </div>
                </div> :
                <>
                  <div className='bg-white p-[25px] shadow rounded-xl'>
                    <div className='mb-4'>
                      <label
                        htmlFor='manufacturerName'
                        className='flex items-center justify-between'
                      >
                        <p className='text-[13px]'>manufacture name<span className='text-red-500 ms-1'>*</span></p>
                        <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p>
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='manufacturerName'
                        maxLength={60}
                        name='brand_name'
                        value={mData.brand_name}
                        onChange={HandleInput}
                        placeholder='e.g. Apple'
                      // onBlur={async () => setSlugName(await GenerateSlugName(mData?.manufacturerName))}
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='flex items-center justify-between' htmlFor='category_name'>
                        manufacture slug name
                      </label>
                      <input
                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                        type='text'
                        id='category_slug_name'
                        name='category_slug_name'
                        value={slugName}
                        disabled
                      // onChange={(e) => setSlugName(e.target.value)}
                      // onBlur={async (e) => setSlugName(await GenerateSlugName(e.target.value))}
                      />
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='image'>
                        <p className='text-[13px]'>manufacture image<span className='text-red-500 ms-1'>*</span></p>
                      </label>
                      <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} folder="manufacture" format="image" />
                    </div>
                    <div className='mb-4'>
                      <div className='flex space-x-3 justify-between items-center mb-1'>
                        <label
                          htmlFor='Description'
                        >
                          Description
                        </label>
                      </div>
                      <ReactQuill
                        modules={module}
                        ref={quillRef}
                        className={'w-full h-auto'}
                        theme='snow'
                        name='description'
                        value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                        onChange={handleQuillChange}
                        placeholder='Enter description'
                      />
                      <div className='flex items-center justify-between'>
                        <p className='text-xs mt-0 text-gray-400'>
                          of 280 words allowed
                        </p>
                      </div>
                    </div>
                  </div>

                  <details className='bg-white cursor-pointer group shadow  space-y-4 rounded-xl p-[25px]'>
                    <summary
                      className='text-base flex items-center justify-between font-medium text-black'
                    >
                      <div>
                        Search engine listing
                        <p className='text-xs text-gray-500 font-normal'>Add a title and description to see how this product might appear in a search engine listing</p>
                      </div>
                      <span className="transition group-open:rotate-180">
                        <ChevronDown className="text-black w-4 h-5" />
                      </span>
                    </summary>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='meta_title' className='flex items-center justify-between'>
                          Meta Title
                        </label>

                      </div>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='meta_title'
                        name='meta_title'
                        value={mData.meta_title}
                        onChange={HandleInput}
                        placeholder='Enter meta title'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='meta_description' className='flex items-center justify-between'>
                          Meta Description
                        </label>
                      </div>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='text'
                        id='meta_description'
                        name='meta_description'
                        value={mData.meta_description}
                        onChange={HandleInput}
                        placeholder='Enter meta description'
                      />
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='sort_order'>
                        sort order
                      </label>
                      <input
                        className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                        type='number'
                        id='sort_order'
                        name='sort_order'
                        value={sortOrder}
                        onChange={(e) => { setSortOrder(e.target.value <= 0 ? 0 : e.target.value) }}
                        placeholder='Enter sort order'
                      />
                    </div>
                  </details>
                </>
            }
            {
              !formSpinner &&
              <div className='flex items-center justify-between w-full'>
                <Link to={`/dummy-data/dummy-Manufacturer`} className='w-fit flex text-link font-medium hover:underline text-sm items-center '><ArrowLeft className='w-3 mr-1' />Go to manufacturer</Link>
                <button
                  className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300'
                  type='submit'
                  onClick={HandleSubmit}
                >
                  Update Manufacturer
                </button>
              </div>
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateDummyManufacturer;
