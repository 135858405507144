import React, { useState , useRef } from "react";
import API from "../../API";
import ReactQuill from "react-quill";
import { ToastContainer } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import {GenerateSlugName} from "../../controllers/GenerateSlugName"
import { useDispatch } from "react-redux";
import { AddGuidesCategory } from "../../Redux/Action/guide.action";
import { X } from 'feather-icons-react/build/IconComponents';
import Notification from "../../Popup/Notification";
import {
  Popover,
} from '@mui/material';
import module, { formatCode } from "../../controllers/QuillToolbar";
import Breadcrumbs from "../../controllers/breadCrumbs";
import ImageUploader from "../../controllers/ImageUploader";

const AddGuideCategory = () => {
  const [err, setErr] = useState(false);
  const [value, setValue] = useState();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  // Category upload image
  const [image, setimage] = useState();

  const handleSetImage = (value) => {
    setimage(value)
}

  //   popUpsetting
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState();
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg("");
    setSeverity("");
  };


  const [formData, setFormData] = useState({
    category:"",
    sort_order:""
  });
  const {  category,sort_order } = formData;

    // collect Data
    const HandleInput = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const [slugAlready, setSlugAlready] = useState(false);
    const [slugName, setSlugName] = useState();
    const handleSlug = async (slug) => {
        if (category?.length > 0) {
        setSlugAlready(true);
        }
        setSlugName(await GenerateSlugName(slug));
    };

    // Submit Form

    // const HandleSubmit = async (e) => {
    //     e.preventDefault();
    //     dispatch(AddGuidesCategory(category,slugName,sort_order,image,value));
    //     navigate('/guide-categories')
    // };

 // Quill Emmpty check
 const strippedContent = value?.replace(/<[^>]*>/g, '');
 const trimmedContent = strippedContent?.trim();

    const HandleSubmit = async (e) => {
      e.preventDefault();
      if(category === "" || sort_order === "" || !image ||trimmedContent === "" ){
        setErr(true)
      }else{
        dispatch(AddGuidesCategory(category,slugName,sort_order,image,value));
        navigate('/guide-categories')
      }
    };

    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
      setValue(value);
    };
    const [html, setHtml] = useState({htmlPP:false,code:''});
    const {htmlPP,code}=html
    const handleViewHtml = (e) => {
      e.preventDefault();
      const quill = quillRef?.current?.getEditor();
      const htmlContent = quill?.root?.innerHTML
      const formattedHtml = formatCode(htmlContent);
      setHtml({htmlPP:true,code:formattedHtml}); // Log or use the HTML content as needed
    };
  
    const saveHTML=()=>{
      setHtml({...html,htmlPP:false})
      setValue(code)
    }

    return(
        <>
            <div className="w-full pb-[64px]">
            {isNotification && notificationMsg ? (
        <Notification
          message={notificationMsg}
          close={handleClose}
          severity={severity}
        />
      ) : (
        <></>
      )}
        <div className="flex space-y-2 lg:space-y-0 items-end justify-between mb-4">
        <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Guide Categories', path: `/guide-categories` },
              { name: 'Add Guide Category', path: `/guide-categories` },
            ]} />
            <h1 className='text-xl md:text-2xl font-semibold text-primary'>Add Guide Category</h1>
          </div>
        </div>
        <div className="pb-4 overflow-x-hidden  h-full w-full">
          <form
            method="POST"
            className="w-[90%] lg:w-[70%] xl:w-[60%] bg-white border border-gray-300 p-4 mx-auto rounded"
          >
            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="Title"
                >
                  Category Name
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="category"
                  name="category" 
                  value={category}
                  placeholder="category name"
                  onChange={HandleInput}
                  onBlur={() => handleSlug(category)}
                />
                {err && category?.length === 0 ? (
                  <p className='text-red-500 text-xs'>Please enter Category name</p>
                ) : (
                  <></>
                )}
              </div>
              
            </div>
            {
            slugAlready &&
            <div className="w-full mb-4">
                <label
                  className="flex text-gray-700 text-sm font-medium mb-1"
                  htmlFor="slugname"
                >
                  slugname
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="text"
                  id="slugname"
                  name="slugname"
                  value={slugName}
                  placeholder="slugname"
                //   onChange={HandleInput}
                />
              </div>
            }

            <div className="mb-4 flex w-full justify-between space-x-3">
              <div className="w-full">
                <label
                  className=" text-gray-700 text-sm font-medium mb-1"
                  htmlFor="sort_order"
                >
                  sort category
                </label>
                <input
                  className='w-full px-3 py-2 border border-gray-300 rounded-[4px] focus:outline-none focus:border-gray-500'
                  type="number"
                  id="sort_order"
                  name="sort_order" 
                  value={sort_order}
                  placeholder="sort category"
                  onChange={(e)=>setFormData({ ...formData, sort_order: e.target.value<=0 ? 0 : e.target.value })}
                />
                {err && sort_order?.length === 0 ? (
                  <p className='text-red-500 text-xs'>Please enter sort number</p>
                ) : (
                  <></>
                )}
              </div>
              
            </div>

            <div className='w-full'>
              <label htmlFor='image'>image</label>
              <ImageUploader alreadyImage={image} handleSetImage={handleSetImage} format="image" folder="guide-category" />
            </div>
            
            <div className="mb-4">
            <div className='flex space-x-3 justify-between items-center mb-1'>
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="description"
              >
                Description
              </label>
              <section
                    className='inline-block m-1 cursor-pointer lg:-tracking-tighter text-link hover:underline text-white text-sm font-medium py-1 px-2 lg:px-3 rounded-[10px] transition duration-300'
                    onClick={handleViewHtml}
                  >
                    View HTML
                  </section>
                  </div>
              <div>
              
              {
                err && (trimmedContent===''|| value==='') && 
                <p className='text-xs text-red-500'>Page enter description</p>
              }
                <ReactQuill
                  className={trimmedContent === '' && err ? 'border rounded border-red-500' : 'w-full h-auto'}
                  placeholder="Description"
                  ref={quillRef}
                  modules={module}
                  value={value}
                  onChange={handleQuillChange}
                />
                
              </div>
            </div>
            <button
              className="block w-full btn text-white text-sm font-medium py-2.5 px-8 rounded-[4px] transition duration-300"
              type="submit"
              onClick={HandleSubmit}
            >
              Add Guide Category
            </button>
          </form>
        </div>
        <ToastContainer />
          </div>
          {htmlPP && (
        <div className='h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500'>
          <Popover
            open={htmlPP}
            className='h-screen popup-content'
            // onClose={() => {
            //   setHtmlPP(false);
            // }}
          >
            <div className='max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700'>
              <div className='w-[35rem] mb-4'>
                <label className='flex justify-between text-gray-700 text-md font-bold mb-2' htmlFor='product_name'>
                  Edit HTML:
                  <X className="cursor-pointer" onClick={()=>{
                    if(window.confirm("Are You Sure to Leave?. Your recent changes will not be included.")){
                      setHtml({...html,htmlPP:false})
                    }
                  }}/>
                </label>
                <label className='flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                  <textarea 
                    className='w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none'
                    type='text'
                    rows={16}
                    value={code}
                    onChange={(e) => setHtml({...html,code:e.target.value})}
                    placeholder='<p>Code here<p/>'
                  />
                </label>
              <button
                className='btn w-full mt-3 text-white px-3 py-2 rounded-[10px] mx-auto'
                onClick={saveHTML}
              >
                save
              </button>
              </div>
            </div>
          </Popover>
        </div>
      )}
        </>
    )
}
export default AddGuideCategory

