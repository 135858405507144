import React, { useEffect, useState, useRef } from 'react';
import { ChevronLeft, ChevronRight, Edit, Filter, Trash, Move, PlusCircle } from 'feather-icons-react/build/IconComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../API';
import { Pagination, Tooltip } from '@mui/material';
import DataTable from 'react-data-table-component';

import Loader from '../../Loader/Loader';
import { CaretDownFill, CaretUpFill, TrashFill } from 'react-bootstrap-icons';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import Notification from '../../Notification';
import { getInitialPage, replacePageInUrl } from '../../../controllers/PageHandleFromQuery';
function DummyVariantTable() {
  const location = useLocation()
  const navigate = useNavigate();
  const [search, setSearch] = useState(location?.state ? location.state : '');
  const [Page, setPage] = useState(getInitialPage());
  const [allVarientGroup, setallVarientGroup] = useState([]);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [pending, setPending] = useState(true)
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  var startRecord = (allVarientGroup?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(allVarientGroup?.data?.currentPage * limit, allVarientGroup?.data?.totalRecords);
  useEffect(() => {
    replacePageInUrl(Page)
  }, [Page])
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    getVariantsGroup();
  };

  useEffect(() => {
    if(search===''||search?.length>2){
      getVariantsGroup()
    }
  }, [search])

  const getVariantsGroup = async () => {
         try {
      const data = await API({ url: `/super/admin/dummydata/dummygroupvariant/getDummyGroupVariants`, method: 'get', params: { page: Page, limit: initialLimit, searchkey: search.length > 2 ? search : '', sort_by: sortBy, order_by: orderBy.orderByValue }});
      if (data.status === 200 || data.status === 304) {
        setallVarientGroup(data.data);
        setPending(false)
        if(Number(startRecord)>Number(data?.data?.data?.totalRecords)){
          setPage(Page-1)
        }
      }
    } catch (error) {
      setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
    }
  };
  const tableData = allVarientGroup?.data?.records;
  // Drag And Drop Functionality
  const DragItem = useRef();
  const DragOverItem = useRef();
  const sorVarient = async (index, id) => {
    try {
      const apiCall = await API({ url: `/super/admin/dummydata/dummygroupvariant/updateDummyGroupVariant/${id}`, method: 'put', data: {
        newIndex: index
      }})
      if (apiCall.status === 200) {
        getVariantsGroup()
      }
    } catch (error) {
      setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
    }
  }
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...allVarientGroup?.data?.records];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sorVarient(DragOverItem.current, dragItemContent._id)
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { data: { records: _sort } }
    setallVarientGroup(FinalArray);
  };
  const [selectedRowsId, setSelectedRowsId] = useState([])
  const HandleRemove = async (id) => {
    if (window.confirm('Delete group variant?')) {
      try {
        const data = await API({ url: `/super/admin/dummydata/dummygroupvariant/deleteDummyGroupVariant`, method: 'post', data: {
          id: selectedRowsId?.length > 0 ? selectedRowsId : [id]
        }});
        if (data.status === 200 || data.status === 304) {
          getVariantsGroup();
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  useEffect(() => {
    getVariantsGroup();
  }, [limit, Page]);
  const [filter, setFilter] = useState(true);
  const columns = [
    {
      name: '',
      width: '20px',
      padding: '0px',
      cell: (row, index) => <div className='cursor-move drag-btn text-center w-full' draggable
        onDragStart={(e) => (DragItem.current = index)}
        onDragEnter={(e) => (DragOverItem.current = index)}
        onDragEnd={HandleSort}>
        <Move className='cursor-move w-4' />
      </div>
    },
    {
      name: (
        <div className='flex items-center'> 
        <button className={`uppercase flex ${iconVisible && sortBy === 'group_name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('group_name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('group_name')}>
          Group Name
        </button>
          {
            iconVisible && sortBy === 'group_name' ?
              !orderBy.isDecending ? <CaretUpFill className='ms-2'/> : <CaretDownFill className='ms-2'/> : <></>
          }
        </div>

      ),
      cell: row => <span className='capitalize'>{row.group_name}</span>,
      width:'200px'
    },
    {
      name: 'Group value',
      cell: row => <div className='flex overflow-auto line-clamp-1'>
        {row.group_value?.map((value, index) => {
          return (
            <span
              key={index}
              className='bg-[#f1f1f1] me-2 mb-2 inline-block whitespace-nowrap text-black text-xs text-center px-3 capitalize py-1.5 rounded-[10px]'
            >{value}
            </span>
          );
        })}
      </div>
    },
    {
      name: <span className='w-full text-right block text-xs uppercase'>Action</span>,
      cell: (row) => <div className='space-x-2 w-full flex items-center justify-end'>
        <button
          href='#'
          onClick={() => navigate(`/dummy-data/dummy-group-variants/update-dummy-group-variants/${row._id}`)}
          className='text-xs font-thin text-blue-500 hover:text-blue-600'
        >
          <div>
            <Tooltip title='Edit' arrow>
              <button className=''>
                <Edit className='w-[17px] h-[17px]' />
              </button>
            </Tooltip>
          </div>
        </button>
        <button
          href='#'
          className='text-xs font-thin text-red-500 hover:text-red-600'
          onClick={() => HandleRemove(row._id)}
        >
          <Tooltip title='Delete' arrow>
            <button className=''>
              <Trash className='w-[17px] h-[17px]' />
            </button>
          </Tooltip>
        </button>
      </div>,
      width:'100px'
    }
  ]
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
    {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
    <div className='h-auto overflow-y-auto pb-[64px]'>
      <div className='flex items-end justify-between w-full pb-4'>
        <div>
          <Breadcrumbs />
          <h1 className='text-xl md:text-2xl font-medium text-primary'>Group variant </h1>
        </div>
        <div className='flex items-center '>
          <button
            className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[10px] transition duration-300'
            onClick={() => navigate('/dummy-data/dummy-group-variants/add-dummy-group-variants')}
          >
            <PlusCircle className="w-[18px] h-[18px] me-2" />
            Add New Group Variant
          </button>
        </div>
      </div>
      <div className='flex justify-between w-full space-x-2'>
        <div className='w-full space-y-1.5'>
          <div className='flex items-center justify-between'>
            <details className={(selectedRowsId?.length === 0) ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative` : `cursor-pointer group w-[200px] mb-1.5 relative`}>
              <summary className='flex bg-white border border-gray-300 px-2 py-2  text-sm rounded-[4px] justify-between items-center list-none'>
                <span> Bulk Selection</span>
                <span className={(selectedRowsId?.length === 0) ? 'transition group-open:rotate-0' : 'transition group-open:rotate-180'}>
                  <svg
                    fill='none'
                    height={14}
                    shapeRendering='geometricPrecision'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                    viewBox='0 0 24 24'
                    width={14}
                  >
                    <path d='M6 9l6 6 6-6' />
                  </svg>
                </span>
              </summary>
              <div className={(selectedRowsId?.length === 0) ? `hidden` : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`}>
                <button className=' text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center' onClick={() => HandleRemove()}>
                  <TrashFill className='w-3.5 h-3.5' />
                  <span>Delete selection</span>
                </button>
              </div>
            </details>
            <button
              className={'filter-btn'}
              onClick={() => setFilter(!filter)}
            >
              <Tooltip title='Filter' arrow>
                <button className=''>
                  <Filter className='h-5 w-5' />
                </button>
              </Tooltip>
            </button>
          </div>
          <div className={filter ? 'flex w-full h-full overflow-hidden 2xl:space-x-4 relative' : 'flex w-full h-full overflow-hidden relative'}>
            <div className='w-full'>
              <div className='w-full  transition-all duration-100 inline-block mb-2.5 border border-gray-300 rounded-[10px] overflow-hidden '>
                <DataTable
                  columns={columns}
                  data={tableData}
                  selectableRows
                  onSelectedRowsChange={({ selectedRows }) => { setSelectedRowsId((selectedRows?.map((x) => x._id))) }}
                  selectableRowsHighlight
                  highlightOnHover
                  progressPending={pending}
                  progressComponent={<Loader />}
                />
              </div>
              {allVarientGroup.data ? (
                <div className='bg-white border border-gray-300 hidden sm:flex flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]'>
                  <div className='hidden sm:block'>
                    <p className='flex w-full text-black space-x-4'>
                      <span className='text-sm font-medium'>
                        {
                          limit === "all" ? `${allVarientGroup?.data?.totalRecords}` + " " + "Records" :
                            `${startRecord} - ${endRecord} of ${allVarientGroup?.data?.totalRecords}`
                        }
                      </span>
                    </p>
                  </div>
                  <div>
                    {allVarientGroup?.data?.totalPages !== 1 ? (
                      <Pagination
                      count={allVarientGroup?.data?.totalPages}
                      page={Page}
                      onChange={(e, v) => setPage(v)}
                    />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
               <div className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${filter ? 'w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300' : 'p-0 w-0  -right-full'}`}>
                <p className='flex text-gray-600 font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  Filter
                </p>
                <hr className='border-gray-300 my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Search
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Variants'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) =>{ setSearch(e.target.value);setPage(1)}}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm text-gray-600 font-medium mb-1 inline-block'>
                      Set items limit
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[10px] w-full text-slate-500 font-medium text-sm border border-gray-300 p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => { e.target.value === "all" ? setLimit('all') : setLimit(e.target.value) ;setPage(1) }}
                      >
                        {allVarientGroup?.data?.totalRecords > 10 ? <><option value='10'>10</option></> : <></>}
                        {allVarientGroup?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {allVarientGroup?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default DummyVariantTable;
