export const DummyData = [
    {
        name: "Categories",
        discripion: "Customize website top with links, cart, and wishlist.",
        redirect: "/dummy-data/dummy-category"
    },
    {
        name: "Products",
        discripion: "Manage information and listings for different product brands.",
        redirect: "/dummy-data/dummy-product"
    },
    {
        name: "Blog",
        discripion: "Organize your product assortment for easy navigation and browsing.",
        redirect: "/dummy-data/dummy-blog"
    },
    {
        name: "Blog Categories",
        discripion: "Configure product display and reviews.",
        redirect: "/dummy-data/dummy-blog-category"
    },
    {
        name: "Portfolio",
        discripion: "Showcase featured products, promotions, or brand messages.",
        redirect: "/dummy-data/dummy-portfolio"
    },
    {
        name: "Portfolio Categories",
        discripion: "Manage website content creation with articles, categories, and SEO.",
        redirect: "/dummy-data/dummy-portfolio-category"
    },
    {
        name: "Manufacturer",
        discripion: "Display eye-catching promotional banners.",
        redirect: "/dummy-data/dummy-Manufacturer"
    },
    {
        name: "Pages",
        discripion: "Build trust with testimonials, guarantees, and security seals.",
        redirect: "/dummy-data/dummy-pages"
    },
    {
        name: "Group Variant",
        discripion: "Manage promotions and targeted messages.",
        redirect: "/dummy-data/dummy-group-variants"
    },
    {
        name: "Custome Tab",
        discripion: "Customize website bottom with links, social media, and copyright info.",
        redirect: "/dummy-data/dummy-custometab"
    },
    {
        name: "Layout",
        discripion: "Customize website bottom with links, social media, and copyright info.",
        redirect: "/dummy-data/dummy-layout"
    },
    {
        name: "Module setting",
        discripion: "Global site configuration like buttons, text colors, background colors etc.",
        redirect: "/dummy-data/module-setting"
    }
]