import {Pagination, Tooltip} from "@mui/material";
import {
    Edit,
    Filter,
    Move,
    PlusCircle,
} from "feather-icons-react/build/IconComponents";
import React, {useEffect, useRef, useState} from "react";
import {CaretDownFill, CaretUpFill, TrashFill} from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import {useLocation, useNavigate} from "react-router-dom";
import API from "../../../API";
import {NOPHOTO_IMAGE} from "../../../Assets/StaticData/StaticImage";
import {
    getInitialPage,
    replacePageInUrl,
} from "../../../controllers/PageHandleFromQuery";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Loader from "../../Loader/Loader";
import Notification from "../../Notification";

function WebsiteComment() {
    const [Page, setPage] = useState(getInitialPage());
    const navigate = useNavigate();
    const location = useLocation();
    const initialLimit = localStorage.getItem("limit");
    const [limit, setLimit] = useState(initialLimit);
    const [search, setSearch] = useState(location?.state ? location.state : "");
    const [blogData, setBlogData] = useState();
    const [pending, setPending] = useState(true);
    const [sortBy, setSortBy] = useState("");
    const [iconVisible, setIconVisible] = useState(false);
    const [orderBy, setOrderBy] = useState({
        orderByValue: "ASC",
        isDecending: false,
    });
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    useEffect(() => {
        replacePageInUrl(Page);
    }, [Page]);
    // Sorting Data
    const SortData = (sort_by) => {
        if (!orderBy.isDecending) {
            setOrderBy({orderByValue: "DESC", isDecending: true});
        } else {
            setOrderBy({orderByValue: "ASC", isDecending: false});
        }
        GetBlogs();
    };

    var startRecord = (blogData?.data?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(
        blogData?.data?.currentPage * limit,
        blogData?.data?.totalRecords
    );
    useEffect(() => {
        if (search === "" || search?.length > 2) {
            GetBlogs();
        }
    });
    const GetBlogs = async () => {
        try {
            const data = await API({ url: `/super/admin/se_site/blog/GetBlogComments`, method: 'get', params: {
                    page: Page,
                    limit: limit,
                    searchkey: search?.length > 2 ? search : "",
                    sort_by: sortBy,
                    order_by: orderBy.orderByValue
                }
            });
            if (data?.status === 200 || data?.status === 304) {
                setBlogData(data?.data);
                setPending(false);
                if (
                    Number(startRecord) > Number(data.data?.data?.totalRecords)
                ) {
                    setPage(Page - 1);
                }
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    // Drag And Drop Functionality
    const DragItem = useRef();
    const DragOverItem = useRef();
    const sortProduct = async (index, id) => {
        try {
            const apiCall = await API({ url: `/super/admin/dummydata/dummyblogs/updateDummyBlog/${id}`, method: 'put', 
                data: {
                    newIndex:
                        index === 0 && Page === 1
                            ? 0
                            : Page !== 1
                            ? Number(index) + Number(limit) * Number(Page - 1)
                            : Number(index),
                }
            });
            if (apiCall.status === 200) {
                GetBlogs();
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    const HandleSort = (e) => {
        e.preventDefault();
        let _sort = [...tableData];
        const dragItemContent = _sort.splice(DragItem.current, 1)[0];
        _sort.splice(DragOverItem.current, 0, dragItemContent);
        sortProduct(DragOverItem.current, dragItemContent._id);
        DragItem.current = null;
        DragOverItem.current = null;
        let FinalArray = {data: {records: _sort}};
        setBlogData(FinalArray);
    };
    const tableData = blogData?.data?.records;
    const [selectedRowsId, setSelectedRowsId] = useState([]);
    useEffect(() => {
        GetBlogs();
    });
    //   Blog Crud

    // const EditBlog = () => {
    //   navigate(`/all-stores-categories/${localStorage.getItem("category_name")}/dummy-blog/update-dummy-blog/${row?.blog_slug_name}`);
    // };
    const RemoveBlog = async (id) => {
        if (window.confirm("Are You Sure To remove This Blog?")) {
            try {
                const data = await API({ url: `/super/admin/se_site/blog/deleteSEBlog`, method: 'post', data: {
                        id: selectedRowsId?.length > 0 ? selectedRowsId : [id],
                    }
                });
                if (data.status === 200 || data.status === 304) {
                    setIsNotification(true);
                    setNotificationMsg("Removed Successfully");
                    setSeverity("success");
                    GetBlogs();
                }
            } catch (error) {
                setIsNotification(true);
                setNotificationMsg(error);
                setSeverity("error");
            }
        }
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    useEffect(() => {
        localStorage.setItem("limit", limit);
    }, [limit]);

    const [filter, setFilter] = useState(true);
    const columns = [
        {
            name: "",
            width: "20px",
            padding: "0px",
            cell: (row, index) => (
                <div
                    className="cursor-move drag-btn text-center w-full"
                    draggable
                    onDragStart={(e) => (DragItem.current = index)}
                    onDragEnter={(e) => (DragOverItem.current = index)}
                    onDragEnd={HandleSort}
                >
                    <Move className="cursor-move w-4" />
                </div>
            ),
        },
        {
            name: "Image",
            width: "100px",
            cell: (row) => (
                <img
                    width={40}
                    height={50}
                    onError={(e) => {
                        e.target.src = NOPHOTO_IMAGE;
                    }}
                    src={row?.image || NOPHOTO_IMAGE}
                    className="p-1"
                    alt=""
                />
            ),
        },
        {
            name: (
                <div className="flex items-center">
                    <button
                        className={`uppercase flex ${
                            iconVisible && sortBy === "title"
                                ? "text-gray-700"
                                : ""
                        }`}
                        onMouseEnter={() => {
                            setIconVisible(true);
                            setSortBy("title");
                        }}
                        onMouseLeave={() => {
                            setIconVisible(false);
                            setSortBy("");
                        }}
                        onClick={() => SortData("title")}
                    >
                        Blog Title
                        {iconVisible && sortBy === `title` ? (
                            !orderBy.isDecending ? (
                                <CaretUpFill className="ms-2" />
                            ) : (
                                <CaretDownFill className="ms-2" />
                            )
                        ) : (
                            <></>
                        )}
                    </button>
                </div>
            ),
            width: "300px",
            cell: (row) => <p className="line-clamp-2">{row?.title}</p>,
        },
        {
            name: (
                <div className="flex items-center">
                    <button
                        className={`uppercase flex ${
                            iconVisible && sortBy === "description"
                                ? "text-gray-700"
                                : ""
                        }`}
                        onMouseEnter={() => {
                            setIconVisible(true);
                            setSortBy("description");
                        }}
                        onMouseLeave={() => {
                            setIconVisible(false);
                            setSortBy("");
                        }}
                        onClick={() => SortData("description")}
                    >
                        description
                        {iconVisible && sortBy === `description` ? (
                            !orderBy.isDecending ? (
                                <CaretUpFill className="ms-2" />
                            ) : (
                                <CaretDownFill className="ms-2" />
                            )
                        ) : (
                            <></>
                        )}
                    </button>
                </div>
            ),

            cell: (row) => (
                <p
                    className="line-clamp-2"
                    dangerouslySetInnerHTML={{
                        __html:
                            row.description?.replace(/<img[^>]*>/g, "") || "-",
                    }}
                ></p>
            ),
        },
        {
            name: "Action",
            width: "110px",
            cell: (row) => (
                <div className="flex items-center space-x-2 justify-center">
                    <div>
                        <Tooltip title="Edit" arrow>
                            <button className="">
                                <Edit
                                    className="w-[17px] h-[17px] text-blue-500"
                                    // onClick={() => EditBlog(row)}
                                    onClick={() =>
                                        navigate(
                                            `/site-data/blog/${row?.blog_slug_name}`
                                        )
                                    }
                                />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className="h-auto overflow-y-auto overflow-x-hidden pb-[64px]">
                <div className="flex items-end justify-between pb-4">
                    <div>
                        <Breadcrumbs />
                        <h1 className="text-xl md:text-2xl font-medium text-primary">
                            Blog Comments
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <button
                            className="flex items-center btn text-white text-sm font-medium py-2 px-8 rounded-[10px] transition duration-300"
                            onClick={() =>
                                navigate(`/site-data/blog/add-website-blog`)
                            }
                        >
                            <PlusCircle className="w-[18px] h-[18px] me-2" />
                            Add New Blog Comment
                        </button>
                    </div>
                </div>
                <div className="flex justify-between space-x-3 w-full relative">
                    <div className="w-full space-y-1.5">
                        <div className="flex items-center justify-between">
                            <details
                                className={
                                    selectedRowsId?.length === 0
                                        ? `opacity-60 cursor-not-allowed group w-[200px] mb-1.5 relative`
                                        : `cursor-pointer group w-[200px] mb-1.5 relative`
                                }
                            >
                                <summary className="flex bg-white border border-gray-300  text-sm rounded-[4px] px-2 py-2 justify-between items-center list-none">
                                    <span> Bulk Selection</span>
                                    <span
                                        className={
                                            selectedRowsId?.length === 0
                                                ? "transition group-open:rotate-0"
                                                : "transition group-open:rotate-180"
                                        }
                                    >
                                        <svg
                                            fill="none"
                                            height={14}
                                            shapeRendering="geometricPrecision"
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1.5"
                                            viewBox="0 0 24 24"
                                            width={14}
                                        >
                                            <path d="M6 9l6 6 6-6" />
                                        </svg>
                                    </span>
                                </summary>
                                <div
                                    className={
                                        selectedRowsId?.length === 0
                                            ? `hidden`
                                            : `group-open:animate-fadeIn absolute w-full h-auto top-full left-0 z-20 bg-white shadow-lg border border-gray-300 rounded`
                                    }
                                >
                                    <button
                                        className=" text-gray-600 hover:bg-gray-100 w-full  px-2 py-1.5 transition-all duration-150 text-sm space-x-2 flex items-center"
                                        onClick={() => RemoveBlog()}
                                    >
                                        <TrashFill className="w-3.5 h-3.5" />
                                        <span>Delete selection</span>
                                    </button>
                                </div>
                            </details>
                            <button
                                className={"filter-btn"}
                                onClick={() => setFilter(!filter)}
                            >
                                <Tooltip title="Filter" arrow>
                                    <button className="">
                                        <Filter className="h-5 w-5" />
                                    </button>
                                </Tooltip>
                            </button>
                        </div>
                        <div
                            className={
                                filter
                                    ? "flex w-full h-full overflow-hidden 2xl:space-x-4 relative"
                                    : "flex w-full h-full overflow-hidden relative"
                            }
                        >
                            <div className="w-full">
                                <div className="w-full  transition-all duration-100 mb-3 inline-block h-auto me-4 border border-gray-300 rounded-[10px] overflow-x-auto overflow-y-hidden">
                                    <DataTable
                                        columns={columns}
                                        data={tableData}
                                        selectableRows
                                        onSelectedRowsChange={({
                                            selectedRows,
                                        }) => {
                                            setSelectedRowsId(
                                                selectedRows?.map((x) => x._id)
                                            );
                                        }}
                                        selectableRowsHighlight
                                        highlightOnHover
                                        progressPending={pending}
                                        progressComponent={<Loader />}
                                    />
                                </div>
                                {blogData?.data ? (
                                    <div className="bg-white flex border border-gray-300 flex-1 p-4 items-center justify-between pagination overflow-hidden rounded-[10px]">
                                        <div className="hidden sm:block">
                                            <p className="flex w-full text-black space-x-4">
                                                <span className="text-sm font-medium">
                                                    {limit === "all"
                                                        ? `${blogData?.data?.totalRecords}` +
                                                          " " +
                                                          "Records"
                                                        : `${startRecord} - ${endRecord} of ${blogData?.data?.totalRecords}`}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            {blogData.data &&
                                            blogData?.data?.totalPages !== 1 ? (
                                                <Pagination
                                                    count={
                                                        blogData?.data
                                                            ?.totalPages
                                                    }
                                                    page={Page}
                                                    onChange={(e, v) =>
                                                        setPage(v)
                                                    }
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div
                                className={`z-10 transition-all duration-300 fixed h-fit 2xl:relative bg-white rounded-[10px] overflow-hidden ${
                                    filter
                                        ? "w-auto 2xl:w-1/3 2xl:right-0 visible right-5 p-4 border border-gray-300"
                                        : "p-0 w-0  -right-full"
                                }`}
                            >
                                <p className="flex text-gray-600 font-semibold uppercase text-sm m-0">
                                    <Filter className="me-1 w-4 h-4" />
                                    Filter
                                </p>
                                <hr className="border-gray-300 my-3"></hr>
                                <div className="grid gap-y-5">
                                    <div>
                                        <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                                            Search
                                        </span>
                                        <label className="relative block w-full">
                                            <input
                                                className="w-full placeholder placeholder:text-slate-400 block bg-white border border-slate-300 rounded-[10px] py-1.5 pl-3 pr-12 focus:outline-none text-sm"
                                                placeholder="Search Blogs"
                                                type="text"
                                                name="search"
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    setPage(1);
                                                }}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <span className="text-sm text-gray-600 font-medium mb-1 inline-block">
                                            Set items limit
                                        </span>
                                        <div className="relative">
                                            <select
                                                className="outline-none rounded-[10px] w-full text-slate-500 text-sm border border-gray-300 px-3 py-1.5"
                                                name="limit"
                                                value={limit}
                                                onChange={(e) => {
                                                    setPage(1);
                                                    e.target.value === "all"
                                                        ? setLimit("all")
                                                        : setLimit(
                                                              e.target.value
                                                          );
                                                }}
                                            >
                                                {blogData?.data?.totalRecords >
                                                10 ? (
                                                    <>
                                                        <option value="10">
                                                            10
                                                        </option>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {blogData?.data?.totalRecords >
                                                20 ? (
                                                    <option value="20">
                                                        20
                                                    </option>
                                                ) : (
                                                    <></>
                                                )}
                                                {blogData?.data?.totalRecords >
                                                50 ? (
                                                    <option value="50">
                                                        50
                                                    </option>
                                                ) : (
                                                    <></>
                                                )}
                                                <option value="all">All</option>
                                            </select>
                                            <div className="select-arrow"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebsiteComment;
