import {
    Autocomplete,
    Box,
    Popover,
    Rating,
    Skeleton,
    TextField,
} from "@mui/material";
import {
    ArrowLeft,
    Check,
    ChevronDown,
    Edit,
    X,
} from "feather-icons-react/build/IconComponents";
import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import API from "../../../API";
import {GenerateSlugName} from "../../../controllers/GenerateSlugName";
import ImageUploader from "../../../controllers/ImageUploader";
import {formatCode} from "../../../controllers/QuillToolbar";
import TextEditor from "../../../controllers/TextEditor";
import Breadcrumbs from "../../../controllers/breadCrumbs";
import Notification from "../../Notification";
import DatePicker from "react-datepicker";
import {CalendarFill} from "react-bootstrap-icons";
import moment from "moment-timezone";

const UpdateWebsiteApps = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [warn, setwarn] = useState(false);
    const [elem, setElem] = useState();
    // image upload logic
    const [image, setimage] = useState();
    const [meta, setMeta] = useState({meta_title: "", meta_description: ""});
    const {meta_description, meta_title} = meta;
    const [secondDescription, setSecondDescription] = useState();
    const [title, setTitle] = useState();
    const [SelectCategory, setSelectCategory] = useState();
    const [useOfApp, setUseOfApp] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [language, setLanguage] = useState("");
    const [availablePlan, setAvailablePlan] = useState("");
    const [sortOrder, setSortOrder] = useState();
    const [err, setErr] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState("");
    const [severity, setSeverity] = useState();
    const handleSetImage = (value) => {
        setimage(value);
    };

    const [endDate, setEndDate] = useState(new Date());
    const [value, setValue] = useState();

    const [formSpinner, setFormSpinner] = useState(true);
    const getPerticularApps = async () => {
        setFormSpinner(true);
        const res = await API({ url: `/super/admin/se_site/se_app/GetApp/${params.app_slug_name}`, method: 'get' });
        setElem(res.data.data);
        setimage(res?.data?.data?.image);
        setMeta({
            meta_title: res?.data?.data?.meta_title || "",
            meta_description: res?.data?.data?.meta_description || "",
        });
        setSortOrder(res?.data?.data?.sort_order || "");
        setSecondDescription(
            res.data.data?.long_description
                ? `${res.data.data?.long_description}`
                : ""
        );
        setSelectCategory(
            res?.data?.data.available_in_plan
                ? res?.data?.data.available_in_plan
                : ""
        );
        setTitle(res.data.data?.title ? `${res.data.data.title}` : "");
        setUseOfApp(
            res.data.data?.use_of_app ? `${res.data.data.use_of_app}` : ""
        );
        setValue(res.data.data?.rating ? `${res.data.data.rating}` : "");
        setShortDescription(
            res.data.data?.short_description
                ? `${res.data.data.short_description}`
                : ""
        );
        setLanguage(
            res.data.data?.languages ? `${res.data.data.languages}` : ""
        );
        setSecondDescription(
            res.data.data?.long_description
                ? `${res.data.data.long_description}`
                : ""
        );
        const formattedDate = res.data.data?.launched_date
            ? moment(res.data.data.launched_date).toDate()
            : new Date();
        setEndDate(formattedDate);

        setSlugName(
            res.data.data?.app_slug_name ? `${res.data.data.app_slug_name}` : ""
        );
        setFormSpinner(false);
    };
    useEffect(() => {
        getPerticularApps();
    }, []);

    const handleBlogCategory = (id, name) => {
        if (id) {
            const cat = SelectCategory.find((x) => x._id === id);
            if (!cat) {
                setSelectCategory([
                    ...SelectCategory,
                    {_id: id, plan_type: name},
                ]);
            }
        }
    };

    const removeCategory = (id) => {
        const removeRelated = SelectCategory.filter((x) => x._id !== id);
        setSelectCategory(removeRelated);
    };

    const [Category, setCategory] = useState([]);
    const GetCategory = async () => {
        try {
            const data = await API({ url: `/super/admin/plan/getPlanList`, method: 'get' });
            if (data.status === 200 || data.status === 304) {
                setCategory(data.data);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };
    const [slugName, setSlugName] = useState();
    useEffect(() => {
        GetCategory();
    }, []);

    const HandleUpdate = async (e) => {
        e.preventDefault();
        try {
            const data = await API({ url: `/super/admin/se_site/se_app/updateApp/${elem._id}`, method: 'put', 
                data: {
                    title: title,
                    use_of_app: useOfApp,
                    available_in_plan: SelectCategory?.map((x) => x._id),
                    // available_in_plan: availablePlan,
                    short_description: shortDescription,
                    languages: language,
                    rating: value,
                    launched_date: endDate,
                    image: image,
                    long_description: secondDescription,
                    app_slug_name: slugName,
                    sort_order: sortOrder,
                    ...meta,
                }
            });
            if (data.status === 200 || data.status === 304) {
                setIsNotification(true);
                setNotificationMsg("Apps Updated Successfully!");
                setSeverity("success");
                setTimeout(() => {
                    navigate("/site-data/apps");
                }, 1000);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity("error");
        }
    };

    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg("");
        setSeverity("");
    };
    const handleEditorData = (value) => {
        setSecondDescription(value);
    };
    //Quil To HTML code and Functions
    const quillRef = useRef(null);
    const handleQuillChange = (value) => {
        setSecondDescription(value);
    };

    const [enableSlug, setEnableSlug] = useState(false);
    const [slugField, setSlugField] = useState(false);
    const handleSlug = async (slug) => {
        if (slug?.length) {
            setSlugField(true);
            setSlugName(await GenerateSlugName(slug));
        }
    };

    const [html, setHtml] = useState({htmlPP: false, code: ""});
    const {htmlPP, code} = html;
    const handleViewHtml = (e) => {
        e.preventDefault();
        const quill = quillRef.current.getEditor();
        const htmlContent = quill.root.innerHTML;
        const formattedHtml = formatCode(htmlContent);
        setHtml({htmlPP: true, code: formattedHtml}); // Log or use the HTML content as needed
    };

    const saveHTML = () => {
        setHtml({...html, htmlPP: false});
        setSecondDescription(code);
    };

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}

            <div className="h-auto overflow-y-auto pb-[64px]">
                <Breadcrumbs />
                <h1 className="text-xl md:text-2xl font-medium pb-4 text-primary">
                    Update website apps - {elem?.title}
                </h1>
                <div className="flex justify-evenly p-4">
                    <form
                        method="POST"
                        className="w-[80%] space-y-4 sm:w-[70%] xl:w-[50%] mx-auto"
                    >
                        {formSpinner ? (
                            <div className="space-y-4">
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div className="flex items-center space-x-4 w-full">
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                        <div className="w-full">
                                            <Skeleton
                                                className="w-[200px] mb-1"
                                                variant="rounded"
                                            />
                                            <Skeleton
                                                className="w-full min-h-[45px]"
                                                variant="rounded"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[45px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[200px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow space-y-4 rounded-xl p-[25px]">
                                    <div>
                                        Media
                                        <p className="text-xs text-gray-500 font-normal">
                                            Upload captivating image to make
                                            your product stand out.
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Skeleton
                                            className="w-[200px] mb-1"
                                            variant="rounded"
                                        />
                                        <Skeleton
                                            className="w-full min-h-[130px]"
                                            variant="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-white group border border-gray-300  space-y-4 rounded-xl p-[25px]">
                                    <div className="w-full mb-4">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                App Title
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            name="title"
                                            // maxLength={60}
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="e.g. Tech"
                                            onBlur={() => handleSlug(title)}
                                            // onBlur={async () => setSlugName(await GenerateSlugName(title))}
                                        />
                                        {warn && (
                                            <h1 className="text-sm text-red-500">
                                                Please enter app title
                                            </h1>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Use of app
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <input
                                            className={
                                                (err &&
                                                    useOfApp?.length === 0) ||
                                                warn
                                                    ? `w-full px-3 py-2 border border-red-500 rounded-[10px] focus:outline-none`
                                                    : `w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none`
                                            }
                                            type="text"
                                            name="useOfApp"
                                            // maxLength={60}
                                            value={useOfApp}
                                            onChange={(e) => {
                                                setUseOfApp(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="e.g. For Higher Conversations"
                                            // onBlur={() => handleSlug(useOfApp)}
                                        />
                                        {(err && useOfApp?.length === 0) ||
                                        warn ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter use of app
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Plan available
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <Autocomplete
                                            className="autocomplete"
                                            options={
                                                Category?.data
                                                    ? Category?.data?.records
                                                    : []
                                            }
                                            getOptionLabel={(category) =>
                                                category.plan_type
                                            }
                                            onChange={(e, v) =>
                                                v
                                                    ? handleBlogCategory(
                                                          v?._id,
                                                          v?.plan_type
                                                      )
                                                    : ""
                                            }
                                            renderInput={(Categories) => (
                                                <TextField
                                                    placeholder="Select blog category"
                                                    className="cursor-pointer"
                                                    defaultValue={
                                                        elem?.blogcategory
                                                            ?.available_in_plan
                                                    }
                                                    {...Categories}
                                                />
                                            )}
                                        />
                                        {SelectCategory?.map((elem, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="bg-[#f1f1f1] inline-flex items-center me-2 mt-1 min-w-[50px] text-black text-xs text-center px-3 capitalize py-1.5 rounded-[10px]"
                                                >
                                                    {elem?.plan_type}
                                                    <X
                                                        className="text-black cursor-pointer h-[14px] w-[14px] ms-2"
                                                        onClick={() =>
                                                            removeCategory(
                                                                elem?._id
                                                            )
                                                        }
                                                    />
                                                </span>
                                            );
                                        })}
                                        {(err && availablePlan?.length === 0) ||
                                        warn ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter plan available
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Short descripton
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <textarea
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="shortDescription"
                                            name="shortDescription"
                                            value={shortDescription}
                                            onChange={(e) => {
                                                setShortDescription(
                                                    e.target.value
                                                );
                                                setwarn(false);
                                            }}
                                            placeholder="Enter short description"
                                        />
                                        {(err &&
                                            shortDescription?.length === 0) ||
                                        warn ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter short description
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Language
                                                <span className="text-red-500 ms-1">
                                                    *
                                                </span>
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <textarea
                                            className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                            type="text"
                                            id="language"
                                            name="language"
                                            value={language}
                                            onChange={(e) => {
                                                setLanguage(e.target.value);
                                                setwarn(false);
                                            }}
                                            placeholder="Enter langauge"
                                        />
                                        {(err && language?.length === 0) ||
                                        warn ? (
                                            <p className="text-red-500 text-xs">
                                                Please enter language
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="title"
                                            className="flex items-center justify-between"
                                        >
                                            <p className="text-[13px]">
                                                Rating star
                                                {/* <span className="text-red-500 ms-1">
                                            *
                                        </span> */}
                                            </p>
                                            {/* <p className='text-xs text-gray-500 font-normal'>only 60 characters allowed</p> */}
                                        </label>
                                        <Box
                                            sx={{
                                                "& > legend": {mt: 2},
                                            }}
                                        >
                                            <Rating
                                                name="simple-controlled"
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                            />
                                        </Box>
                                    </div>
                                    <div className="w-full">
                                        <label>launched date</label>
                                        <label className="relative block w-full cursor-pointer">
                                            <DatePicker
                                                className="border cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none"
                                                selected={endDate}
                                                onChange={(date) =>
                                                    setEndDate(date)
                                                }
                                                selectsEnd
                                                // startDate={startDate}
                                                // endDate={endDate}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                            <span className="absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5">
                                                <CalendarFill className="text-gray-600 w-3 h-3" />
                                            </span>
                                        </label>
                                    </div>

                                    {slugField && (
                                        <div className="w-full">
                                            <label htmlFor="slug_name">
                                                app Slug name
                                            </label>
                                            <div
                                                className={`flex items-center justify-between w-full border ${
                                                    err && enableSlug
                                                        ? "border-red-500"
                                                        : "border-gray-300"
                                                } rounded-[4px]`}
                                            >
                                                <input
                                                    className="w-full  focus:outline-none"
                                                    type="text"
                                                    name="app_slug_name"
                                                    value={slugName}
                                                    disabled={!enableSlug}
                                                    onChange={(e) =>
                                                        setSlugName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                {enableSlug ? (
                                                    <Check
                                                        onClick={async () => {
                                                            setSlugName(
                                                                await GenerateSlugName(
                                                                    slugName
                                                                )
                                                            );
                                                            setEnableSlug(
                                                                !enableSlug
                                                            );
                                                            setErr(false);
                                                        }}
                                                        className="text-green-500 p-1 h-8 w-8"
                                                    />
                                                ) : (
                                                    <Edit
                                                        onClick={() =>
                                                            setEnableSlug(
                                                                !enableSlug
                                                            )
                                                        }
                                                        className="text-blue-500 p-1 h-7 w-7"
                                                    />
                                                )}
                                            </div>
                                            {err && enableSlug && (
                                                <p className="text-xsm text-red-500">
                                                    Please save this changes
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div className="mb-4">
                                        <TextEditor
                                            label={"app description"}
                                            handleEditorData={handleEditorData}
                                            defaultValue={secondDescription}
                                        />
                                        {err &&
                                            secondDescription?.length === 0 && (
                                                <p className="text-red-500 text-xs">
                                                    Please enter description for
                                                    generate meta data
                                                </p>
                                            )}
                                    </div>
                                </div>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Media</span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Upload captivating image to make
                                                your category stand out.
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <ImageUploader
                                        alreadyImage={image}
                                        handleSetImage={handleSetImage}
                                        folder="apps"
                                        format="image"
                                    />
                                </details>
                                <details
                                    open
                                    className="bg-white cursor-pointer group border border-gray-300  space-y-4 rounded-xl p-[25px]"
                                >
                                    <summary className="text-base flex items-center justify-between font-medium text-black">
                                        <div>
                                            <span>Search engine listing</span>
                                            <p className="text-xs text-gray-500 font-normal">
                                                Add a title and description to
                                                see how this product might
                                                appear in a search engine
                                                listing
                                            </p>
                                        </div>
                                        <span className="transition group-open:rotate-180">
                                            <ChevronDown className="text-black w-4 h-5" />
                                        </span>
                                    </summary>
                                    <div className="mb-4">
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag Title"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta title
                                                </label>
                                            </div>
                                            <input
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag Title"
                                                name="meta_title"
                                                value={meta_title}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="Enter meta title"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="flex items-center justify-between">
                                                <label
                                                    htmlFor="Meta Tag description"
                                                    className="flex items-center justify-between"
                                                >
                                                    Meta description
                                                </label>
                                            </div>
                                            <textarea
                                                className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                                type="text"
                                                id="Meta Tag description"
                                                name="meta_description"
                                                value={meta_description}
                                                onChange={(e) => {
                                                    setMeta({
                                                        ...meta,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                }}
                                                placeholder="e.g. We have best fashionable products"
                                            />
                                        </div>
                                    </div>
                                </details>
                                <div className="flex items-center justify-between w-full">
                                    <Link
                                        to={`/site-data/apps`}
                                        className="w-fit flex text-link font-medium hover:underline text-sm items-center "
                                    >
                                        <ArrowLeft className="w-3 mr-1" />
                                        Go to apps
                                    </Link>
                                    <button
                                        className="inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[10px] transition duration-300"
                                        type="button"
                                        onClick={HandleUpdate}
                                    >
                                        Update App
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
            {htmlPP && (
                <div className="h-screen fixed inset-0 bg-black bg-opacity-60 transition-opacity top-0 left-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 max-h-full cursor-pointer duration-500">
                    <Popover
                        open={htmlPP}
                        className="h-screen popup-content"
                        // onClose={() => {
                        //   setHtmlPP(false);
                        // }}
                    >
                        <div className="max-w-full max-h-full rounded-none  relative p-4 bg-white shadow dark:bg-gray-700">
                            <div className="w-[35rem] h-96  mb-4">
                                <label
                                    className="flex justify-between text-gray-700 text-md font-bold mb-2"
                                    htmlFor="product_name"
                                >
                                    Edit HTML:
                                    <X
                                        className="cursor-pointer"
                                        onClick={() => {
                                            if (
                                                window.confirm(
                                                    "Are You Sure to Leave?. Your recent changes will not be included."
                                                )
                                            ) {
                                                setHtml({
                                                    ...html,
                                                    htmlPP: false,
                                                });
                                            }
                                        }}
                                    />
                                </label>
                                <label className="flex flex-col items-center p-3 justify-center border border-gray-300 rounded-[10px] cursor-pointer hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    <textarea
                                        className="w-full px-3 py-2 border border-gray-300 rounded-[10px] focus:outline-none"
                                        type="text"
                                        rows={16}
                                        value={code}
                                        onChange={(e) =>
                                            setHtml({
                                                ...html,
                                                code: e.target.value,
                                            })
                                        }
                                        placeholder="<p>Code here<p/>"
                                    />
                                </label>
                            </div>
                            <button
                                className="btn w-full text-white px-3 py-2 rounded-[10px] mx-auto"
                                onClick={saveHTML}
                            >
                                save
                            </button>
                        </div>
                    </Popover>
                </div>
            )}
        </>
    );
};

export default UpdateWebsiteApps;
